/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

// Icons
import InputMinusIcon from '../../assets/icons/InputMinusIcon';
import InputPlusIcon from '../../assets/icons/InputPlusIcon';

function FormQuantity({ name, register, control, setValue, value, step, min, max, editable, disabled }) {
    const [quantity, setQuantity] = useState(value || 0);

    if (control) {
        const watchQuantity = useWatch({ control, name });
        useEffect(() => {
            setQuantity(watchQuantity);
        }, [watchQuantity]);
    }

    useEffect(() => {
        setQuantity(value || 0);
    }, [name]);

    const increase = () => {
        setQuantity((prevQuantity) => {
            if (max && prevQuantity + step > max) {
                return max;
            }
            return prevQuantity + (step || 1);
        });
    };

    const decrease = () => {
        setQuantity((prevQuantity) => {
            if (prevQuantity - step < 0) {
                return 0;
            }
            return prevQuantity - (step || 1);
        });
    };

    const handleOnChange = (e) => {
        if (e.target.value > max) {
            return setQuantity(max)
        }
        return setQuantity(parseInt(e.target.value, 10))
    };

    useEffect(() => {
        setValue(name, parseInt(quantity, 10) || 0);
    }, [quantity]);

    return (
        <div className='input-quantity'>
            <button disabled={disabled || quantity <= min} type='button' onClick={decrease}>
                <InputMinusIcon />
            </button>
            <input
                disabled={!editable}
                id={name}
                name={name}
                className={`text-link ${quantity <= 0 ? 'disabled' : undefined}`}
                autoComplete='off'
                type='number'
                defaultValue={value}
                {...register(name)}
                onChange={handleOnChange}
                onFocus={(event) => event.target.select()}
            />
            <button disabled={disabled || quantity >= max} type='button' onClick={increase}>
                <InputPlusIcon />
            </button>
        </div>
    );
}

FormQuantity.propTypes = {
    name: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    value: PropTypes.number,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    editable: PropTypes.bool,
    disabled: PropTypes.bool,
};

FormQuantity.defaultProps = {
    value: 0,
    step: 1,
    min: 0,
    max: undefined,
    editable: false,
    disabled: false,
};

export default FormQuantity;
