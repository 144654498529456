/* eslint-disable react/prop-types */

import React, { createContext, useContext, useState } from 'react';

// create context
const DevisFiltersContext = createContext();

const DevisFiltersContextProvider = ({ children }) => {
    const [devisFilters, setDevisFilters] = useState(null);

    return (
        <DevisFiltersContext.Provider value={{ devisFilters, setDevisFilters }}>
            {children}
        </DevisFiltersContext.Provider>
    );
};

const useDevisFiltersContext = () => {
    const context = useContext(DevisFiltersContext);

    if (context === undefined) {
        throw new Error('useDevisFiltersContext est utilisé en dehors de son provider');
    }

    return context;
};

export { DevisFiltersContextProvider, useDevisFiltersContext };
