/* eslint-disable import/prefer-default-export */

export function filterObjectsByValue(array, string = '', keys) {
    return array?.filter((o) =>
        keys.some((k) => String(o[k]).toLowerCase().includes(string.toLowerCase()))
    );
}

export function removeUndefined(object) {
    const newObject = Object.keys(object).reduce((acc, key) => {
        // eslint-disable-next-line no-underscore-dangle
        const _acc = acc;
        if (object[key] !== undefined && object[key] !== false) _acc[key] = object[key];
        return _acc;
    }, {});
    return newObject;
}

export function convertArraysIntoStrings(object) {
    const newObject = {};
    Object.entries(object).forEach(([key, value]) => {
        if (!value) return;
        if (value && value.constructor === Array) {
            newObject[key] = value.join(',');
        }
    });
    return { ...object, ...newObject };
}
