/* eslint-disable react/prop-types */

import React, { createContext, useContext, useState } from 'react';

// create context
const DevisDetailsFiltersContext = createContext();

const DevisDetailsFiltersContextProvider = ({ children }) => {
    const [devisDetailsFilters, setDevisDetailsFilters] = useState(null);

    return (
        <DevisDetailsFiltersContext.Provider
            value={{ devisDetailsFilters, setDevisDetailsFilters }}
        >
            {children}
        </DevisDetailsFiltersContext.Provider>
    );
};

const useDevisDetailsFiltersContext = () => {
    const context = useContext(DevisDetailsFiltersContext);

    if (context === undefined) {
        throw new Error('useDevisDetailsFiltersContext est utilisé en dehors de son provider');
    }

    return context;
};

export { DevisDetailsFiltersContextProvider, useDevisDetailsFiltersContext };
