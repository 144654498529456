/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';

import { useHistory } from 'react-router-dom';

// Components
import Header from '../../components/Header/Header';
import FormText from '../../components/Form/FormText';
import FormPassword from '../../components/Form/FormPassword';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import AuthService from '../../api/auth/auth';

function Login() {
    const history = useHistory();

    const { isLoading, isError, mutate: login } = useMutation(AuthService.login);

    const {
        register,
        getValues,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const onSubmit = async (formData) => {
        login(formData, {
            onSuccess: (response) => {
                if (response.data) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    history.push('/');
                    window.location.reload();
                }
            },
        });
    };

    return (
        <main className="main">
            <Header>
                <h1 className="text-lg">Connexion</h1>
            </Header>
            <div className="flex-1 flex center">
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <FormText
                        label="Email"
                        placeholder="exemple@email.fr"
                        name="login"
                        register={register}
                        errors={errors}
                    />
                    <FormPassword
                        label="Mot de passe"
                        placeholder="Mot de passe"
                        name="password"
                        register={register}
                        errors={errors}
                        getValues={getValues}
                    />
                    <Button disabled={isLoading}>
                        Connexion
                        {isLoading && <Loader />}
                    </Button>
                    {isError && (
                        <p className="text-error flex center">Email ou mot de passe incorrect</p>
                    )}
                </form>
            </div>
        </main>
    );
}

export default Login;
