import * as React from 'react';

export default function CommandesIcon() {
    return (
        <svg width='1.5em' height='1.5em' viewBox='0 0 29 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M8.182 13.09v1.637a1.637 1.637 0 01-3.273 0v-1.636H1.091C.489 13.09 0 13.58 0 14.18v8.728C0 23.511.489 24 1.09 24H12c.602 0 1.09-.489 1.09-1.09v-8.728c0-.602-.488-1.091-1.09-1.091H8.182zM23.455 13.09v1.637a1.637 1.637 0 01-3.273 0v-1.636h-3.818c-.603 0-1.091.489-1.091 1.09v8.728c0 .602.489 1.091 1.09 1.091h10.91c.602 0 1.09-.489 1.09-1.09v-8.728c0-.602-.488-1.091-1.09-1.091h-3.818zM15.818 0v1.636a1.637 1.637 0 01-3.272 0V0H8.727c-.602 0-1.09.489-1.09 1.09v8.728c0 .602.488 1.091 1.09 1.091h10.91c.602 0 1.09-.489 1.09-1.09V1.09C20.727.489 20.24 0 19.637 0h-3.819z'
                fill='currentColor'
            />
        </svg>
    );
}
