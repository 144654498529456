/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Cell } from 'rsuite-table';
import PropTypes from 'prop-types';

// Icons
import ImageNotFoundIcon from '../../assets/icons/ImageNotFoundIcon';

const ImageCell = ({ rowData, dataKey, openDetails, clickable, ...props }) => (
    <Cell {...props} className="image-cell" onClick={() => clickable && openDetails(rowData.id)}>
        {rowData[dataKey] ? <img src={rowData[dataKey]} alt="" /> : <ImageNotFoundIcon />}
    </Cell>
);

export default ImageCell;

ImageCell.propTypes = {
    clickable: PropTypes.bool,
};

ImageCell.defaultProps = {
    clickable: true,
};
