/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { notify } from '../../utils/utils';
import avoirsActions from '../actions/avoirs.actions';

export function useGetAvoirs() {
    return useQuery('avoirs', avoirsActions.list);
}

export function useGetAvoir(id, options = {}) {
    return useQuery(['avoir', id], () => avoirsActions.item(id), options);
}

export function useCreateAvoir() {
    const queryClient = useQueryClient();
    return useMutation(avoirsActions.create, {
        onSuccess: () => {
            notify("L'avoir a bien été créé", 'success');
        },
        onError: () => {
            notify('Une erreur est survenue', 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('avoirs');
        },
    });
}

export function useAddProductAvoir(avoirId) {
    const queryClient = useQueryClient();
    return useMutation(avoirsActions.addProduct, {
        onSuccess: () => {
            notify("Produit ajouté à l'avoir", 'success');
        },
        onError: () => {
            notify('Une erreur est survenue', 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('avoirs', { exact: true });
            queryClient.invalidateQueries(['avoir', avoirId]);
        },
    });
}

export function useValidateAvoir() {
    const queryClient = useQueryClient();
    return useMutation(avoirsActions.validate, {
        onSuccess: () => {
            notify('Avoir validé', 'success');
        },
        onError: () => {
            notify('Une erreur est survenue', 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries(['avoirs']);
        },
    });
}
