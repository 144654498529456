/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { notify } from '../../utils/utils';
import bonLivraisonsActions from '../actions/bonLivraisons.actions';

export function useGetBonsLivraisons() {
    return useQuery('bons-livraisons', bonLivraisonsActions.list);
}

export function useLivrerBonLivraison() {
    const queryClient = useQueryClient();
    return useMutation((id) => bonLivraisonsActions.livrer(id), {
        onSuccess: () => {
            notify('La commande a été livrée', 'success');
        },
        onError: () => {
            notify('Une erreur est survenue', 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('bons-livraisons');
        },
    });
}

export function useValidateBonLivraison() {
    const queryClient = useQueryClient();
    return useMutation(bonLivraisonsActions.validate, {
        onSuccess: () => {
            notify('Bon de livraison Validé', 'success');
        },
        onError: () => {
            notify('Une erreur est survenue', 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries(['commandes', 'bons-livraisons']);
        },
    });
}
