import { useState, useEffect } from 'react';

// Debounce function
function debounce(func, wait, immediate) {
    let timeout;
    return (...args) => {
        const context = this;
        const later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export default function useScrollPosition() {
    const [scrollPosition, setScrollPosition] = useState(null);

    useEffect(() => {
        // Debounce to avoid the function fire multiple times
        const handleScrollDebounced = debounce(() => {
            setScrollPosition(window.pageYOffset);
        }, 500);

        window.addEventListener('scroll', handleScrollDebounced);
        return () => window.removeEventListener('scroll', handleScrollDebounced);
    }, []);

    return { scrollPosition };
}
