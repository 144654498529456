/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';

// Utils
import { sumValues } from '../../../utils/utils';

// Components
import FormQuantity from '../../Form/FormQuantity';
import Loader from '../../Loader/Loader';

// api
import { useGetLigneDevis, useUpdateDeclinaisonsLigneDevis } from '../../../api/hooks/ligneDevis.hooks';
import LoaderSpinner from '../../Loader/LoaderSpinner';

const QuantityDetails = ({ toggle, ligneId }) => {
    const [quantitySet, setQuantitySet] = useState(0);

    const { data, isLoading: isLoadingLigne } = useGetLigneDevis(parseInt(ligneId, 10));
    const { isLoading, mutate: validate } = useUpdateDeclinaisonsLigneDevis(ligneId);

    const { register, handleSubmit, setValue, getValues, control } = useForm();
    const fields = useWatch({ control });

    useEffect(() => setQuantitySet(sumValues(fields)), [fields]);
    useEffect(() => setQuantitySet(sumValues(getValues())), []);

    const onSubmit = async (formData) => {
        const array = [];
        Object.keys(formData).map((item) =>
            array.push({
                id: item,
                qte: formData[item],
            })
        );

        validate(
            { declinaison: array },
            {
                onSuccess: () => {
                    toggle();
                },
            }
        );
    };

    if (isLoadingLigne) {
        return <LoaderSpinner />;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate className='quantity-details'>
            <div>
                {data?.declinaison.map(({ id, nom, qte }) => (
                    <div className='flex center mb-1' key={id}>
                        <p className='text-lg mr-5 text-right' style={{ minWidth: 50 }}>
                            {nom}
                        </p>
                        <FormQuantity
                            name={id}
                            register={register}
                            value={parseInt(qte, 10)}
                            setValue={setValue}
                            getValues={getValues}
                        />
                    </div>
                ))}
            </div>

            <div
                className={`text-lg flex column center ${
                    quantitySet !== parseInt(data.qte_totale, 10) ? 'text-error' : undefined
                }`}
            >
                <p>Quantité sélectionnée</p>
                {quantitySet} / {data.qte_totale}
            </div>

            <button
                disabled={quantitySet !== parseInt(data.qte_totale, 10) || isLoading}
                type='submit'
                className='button'
                style={{
                    opacity: quantitySet !== parseInt(data.qte_totale, 10) ? 0.5 : 1,
                    color: 'var(--color-light)',
                    height: 41,
                }}
            >
                {isLoading ? <Loader /> : 'Valider les quantités'}
            </button>
        </form>
    );
};

export default QuantityDetails;

QuantityDetails.propTypes = {
    toggle: PropTypes.func.isRequired,
    ligneId: PropTypes.number.isRequired,
};
