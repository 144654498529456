/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

// Components
import Badge from '../Badge/Badge';
import LoaderSpinner from '../Loader/LoaderSpinner';
import Error from '../Error/Error';

// api
import { useGetProduct } from '../../api/hooks/products.hooks';

function ProductDetails({ productId }) {
    const { data: product, isLoading, isError } = useGetProduct(productId);

    if (isLoading)
        return (
            <div className="product-details-loader">
                <LoaderSpinner />
            </div>
        );

    if (isError)
        return (
            <div className="product-details-loader">
                <Error message="Erreur lors de la récupération des détails du produit" />
            </div>
        );

    if (product)
        return (
            <div className="product-details">
                <div className="product-details__header">
                    <p className="text-lg">{product.nom}</p>
                    <p>{product.prix}€ l'unité</p>
                </div>

                {product.photos &&
                    (product.photos.length > 1 ? (
                        <Swiper spaceBetween={16} slidesPerView="auto" centeredSlides loop>
                            {product.photos.map((photo) => (
                                <SwiperSlide key={photo}>
                                    <img src={photo} alt="" />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        <img src={product.photos[0]} alt="" />
                    ))}

                <p>{product.description}</p>

                <div>
                    <p className="text-sm">Stock</p>
                    <p>{product.stock} produits restants</p>
                </div>

                <div>
                    <p className="text-sm">Référence</p>
                    <p>{product.reference}</p>
                </div>

                <div>
                    <p className="text-sm">Categorie</p>
                    <Badge content={product.categorie} />
                </div>
                {/* <div>
                    <p className="text-sm">Categories</p>
                    {product.categories.map((category) => (
                        <Badge key={category} content={category} />
                    ))}
                </div> */}
            </div>
        );

    return null;
}

export default ProductDetails;

ProductDetails.propTypes = {
    productId: PropTypes.number.isRequired,
};
