import apiClient from '../apiClient';

async function update(ligneCommande) {
    const response = await apiClient.post('/gestion_ligne_commande_fournisseur/', ligneCommande);
    return response.data;
}

async function validate(ligneCommande) {
    const response = await apiClient.post('valider_ligne_commande_fournisseur/', ligneCommande);
    return response.data;
}

const ligneCommandesFournisseurActions = { update, validate };

export default ligneCommandesFournisseurActions;
