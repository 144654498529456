import React from "react";

export default function LoaderSpinner() {
    return (
        <div className="loader">
            <div className="spinner">
                <div className="bubble-1" />
                <div className="bubble-2" />
            </div>
        </div>
    );
}
