/* eslint-disable import/prefer-default-export */
import { useMutation, useQueryClient } from 'react-query';
import { notify } from '../../utils/utils';
import ligneCommandesFournisseurActions from '../actions/ligneCommandesFournisseur.actions';

export function useValidateLigneCommandeFournisseur(commandeId) {
    const queryClient = useQueryClient();
    return useMutation(ligneCommandesFournisseurActions.validate, {
        onMutate: async ({ ligne_commande_fournisseur_id }) => {
            await queryClient.cancelQueries(['commande-fournisseur', commandeId]);

            const prevCommande = queryClient.getQueryData(['commande-fournisseur', commandeId]);
            const removeDeleted = prevCommande.lignes.filter(
                (ligne) => ligne.id !== ligne_commande_fournisseur_id
            );

            queryClient.setQueryData(['commande-fournisseur', commandeId], {
                ...prevCommande,
                lignes: removeDeleted,
            });
            return { prevCommande };
        },
        onSuccess: () => {
            notify('Ligne modifiée', 'success');
        },
        onError: () => {
            notify('Une erreur est survenue', 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries(['commande-fournisseur', commandeId]);
            queryClient.invalidateQueries('commandes-fournisseur');
        },
    });
}

export function useUpdateLigneCommandeFournisseur(commandeId) {
    const queryClient = useQueryClient();
    return useMutation(ligneCommandesFournisseurActions.update, {
        onMutate: async ({ qte, ligne_commande_fournisseur_id }) => {
            await queryClient.cancelQueries(['commande-fournisseur', commandeId]);

            const prevCommande = queryClient.getQueryData(['commande-fournisseur', commandeId]);
            const updatedLignes = prevCommande.lignes.map((ligne) =>
                ligne.id === ligne_commande_fournisseur_id ? { ...ligne, qte_livrer: qte } : ligne
            );

            queryClient.setQueryData(['commande-fournisseur', commandeId], {
                ...prevCommande,
                lignes: updatedLignes,
            });
            return { prevCommande };
        },
        onError: (_err, _variables, context) => {
            notify('Une erreur est survenue', 'error');
            queryClient.setQueryData(['commande-fournisseur', commandeId], context.prevCommande);
        },
    });
}
