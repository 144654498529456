/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import productsActions from '../actions/products.actions';

export function useGetProducts(params) {
    return useQuery(['products', params], () => productsActions.list({ ...params }), {
        keepPreviousData: true,
    });
}

export function useGetProduct(id) {
    return useQuery(['product', id], () => productsActions.item(id));
}
