import * as React from 'react';

function OutStockIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 383.22 382" width="1em" height="1em">
            <path
                fill="currentColor"
                d="M0 198.5v-18.71c1-7.63 1.8-15.31 3.16-22.88 18-100.16 113.55-169.5 214.72-155 60.4 8.67 106.83 39.94 138.35 92.09 28.49 47.12 33.68 97.79 19.07 150.61-13.75 49.73-42.47 88.09-87.88 113.12-39.1 21.55-81.08 28.29-125.15 22.07-34.82-4.92-66.27-18-93.58-40.27-37.3-30.43-57.82-70.41-65.63-117.38C1.75 214.31 1 206.38 0 198.5ZM266.39 39.69C216.17 15.79 166 15.56 117.18 40.74c-60.18 31-93.8 80.66-94.47 149.38-.59 60.09 26.07 106.88 74.68 142.29ZM285.5 51.5 117.09 343.21c70.23 35.82 176.2 19.44 223.48-73.08C383 187.11 354 94.25 285.5 51.5Z"
            />
            <path
                fill="currentColor"
                d="M102.72 278.26c-8.82-5.11-17.27-9.87-25.57-14.89-4.16-2.52-5.07-6.76-5.06-11.37q.06-54 0-108.08v-8.32l84.73 48.91ZM83.44 116.19 104 104.28c6.68-3.86 13.34-7.77 20.08-11.52 1-.55 2.74-.9 3.61-.41 20.53 11.71 41 23.56 61.78 35.57l-21.44 37.14ZM148.76 78.45a5595.68 5595.68 0 0 1 37.69-21.68 9.91 9.91 0 0 1 10.05-.15c8.49 4.71 16.86 9.61 25.7 14.68l-21.46 37.16c-17.42-10.06-34.52-19.94-51.98-30.01ZM245.69 173.62c0 3.66-.09 6.65 0 9.63.25 7.25 4.67 11.73 11.38 11.68s11-4.65 11-11.94c.07-6.61-.07-13.22.1-19.83a4.87 4.87 0 0 1 1.69-3.6c13.2-7.79 26.5-15.4 39.78-23.05a6.06 6.06 0 0 1 1.27-.22c.06 1.21.17 2.37.17 3.53v112.25c0 5.44-1.78 9.57-6.7 12.4q-49.8 28.7-99.62 57.53c-.41.24-.86.42-1.67.81-.09-1.16-.23-2.07-.23-3 0-25.82 0-51.64.06-77.45a12 12 0 0 1 1.58-5.57c10.58-18.5 21.38-36.86 31.84-55.43 2.13-3.77 5.19-5.72 9.35-7.74ZM262 137.14 280.53 105l19.35 11.14-37.36 21.59ZM180.26 280.06V323l-19.18-11 18.52-32.09Z"
            />
        </svg>
    );
}

export default OutStockIcon;
