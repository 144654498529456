/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Icons
import EyeCloseIcon from '../../assets/icons/EyeCloseIcon';
import EyeIcon from '../../assets/icons/EyeIcon';

function FormPassword({ label, placeholder, name, register, errors, getValues }) {
    const inputPassword = useRef(null);
    const { ref } = register('password');
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
        const inputPasswordLength = getValues('password').length;
        inputPassword.current.focus();
        setTimeout(() => {
            inputPassword.current.setSelectionRange(inputPasswordLength, inputPasswordLength);
        }, 0);
    };

    return (
        <div className="form__group">
            <label htmlFor="password">{label}</label>
            <div
                className={`input-icon ${errors[name]?.type === 'required' ? 'error' : undefined}`}
            >
                <input
                    id={name}
                    placeholder={placeholder}
                    type={passwordShown ? 'text' : 'password'}
                    {...register('password', { required: true })}
                    ref={(e) => {
                        ref(e);
                        inputPassword.current = e;
                    }}
                    className={`${errors[name]?.type === 'required' ? 'error' : undefined}`}
                />
                <i
                    onClick={togglePasswordVisiblity}
                    className="icon"
                    role="button"
                    onKeyPress={togglePasswordVisiblity}
                    tabIndex="0"
                >
                    {passwordShown ? <EyeIcon /> : <EyeCloseIcon />}
                </i>
            </div>
            {errors.password?.type === 'required' && (
                <p className="text-error">Mot de passe requis</p>
            )}
        </div>
    );
}

FormPassword.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.shape().isRequired,
    getValues: PropTypes.func.isRequired,
};

export default FormPassword;
