import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

// import PropTypes from 'prop-types'

// Icons
import LogOutIcon from '../../assets/icons/LogOutIcon';
import CatalogIcon from '../../assets/icons/CatalogIcon';
import UsersIcon from '../../assets/icons/UsersIcon';
import CommandesIcon from '../../assets/icons/CommandesIcon';
import DevisIcon from '../../assets/icons/DevisIcon';
import TruckIcon from '../../assets/icons/TruckIcon';
import WarehouseIcon from '../../assets/icons/WarehouseIcon';
import AuthService from '../../api/auth/auth';
import AvoirIcon from '../../assets/icons/AvoirIcon';

function Nav() {
    const [isOpen, setIsOpen] = useState(false);

    const logout = () => {
        AuthService.logout();
    };

    return (
        <nav className={`nav ${isOpen ? 'is-open' : ''}`}>
            <button className='nav__button' onClick={() => setIsOpen(!isOpen)} type='button'>
                <div className='nav__toggle'>
                    <span className='nav__bar nav__bar--1' />
                    <span className='nav__bar nav__bar--2' />
                    <span className='nav__bar nav__bar--3' />
                </div>
                Fermer
            </button>
            <div className='nav__inner'>
                <ul>
                    <li className='nav__link'>
                        <NavLink to='/' exact>
                            <CatalogIcon />
                            Catalogue
                        </NavLink>
                    </li>
                    <li className='nav__link'>
                        <NavLink to='/clients'>
                            <UsersIcon />
                            Clients
                        </NavLink>
                    </li>
                    <li className='nav__link'>
                        <NavLink to='/devis'>
                            <DevisIcon />
                            Devis
                        </NavLink>
                    </li>
                    <li className='nav__link'>
                        <NavLink to='/commandes'>
                            <CommandesIcon />
                            Commandes
                        </NavLink>
                    </li>
                    <li className='nav__link'>
                        <NavLink to='/livraisons'>
                            <TruckIcon />
                            Livraisons
                        </NavLink>
                    </li>
                    <li className='nav__link'>
                        <NavLink to='/fournisseurs'>
                            <WarehouseIcon />
                            Fournisseurs
                        </NavLink>
                    </li>
                    <li className='nav__link'>
                        <NavLink to='/avoirs'>
                            <AvoirIcon />
                            Avoirs
                        </NavLink>
                    </li>
                </ul>
            </div>
            <button className='nav__button' onClick={logout} type='button'>
                <LogOutIcon width={24} />
                Déconnexion
            </button>
        </nav>
    );
}

export default Nav;
