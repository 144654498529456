import * as React from 'react';

function CatalogIcon() {
    return (
        <svg width='1.5em' height='1.5em' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M8.556 12.436c-.44-.308-.856-.648-1.312-.912-.976-.564-2.052-.8-3.168-.876-.22-.016-.44-.012-.66-.028-.324-.024-.54-.252-.54-.576-.004-2.86-.004-5.716 0-8.576.004-.316.224-.576.528-.576 1.468.004 2.872.272 4.052 1.216.364.292.656.676.956 1.04.096.116.14.304.144.46.008 1.064-.004 2.132-.004 3.196 0 1.812.008 3.624.012 5.436-.008.072-.008.144-.008.196zM9.444 12.504v-.264l.012-6.176c0-.82-.016-1.64-.004-2.456 0-.156.056-.332.14-.464.752-1.148 1.856-1.772 3.168-2.048.568-.12 1.16-.156 1.744-.2.388-.028.624.224.624.612v8.496c0 .388-.22.612-.624.62-1.028.016-2.028.164-2.992.54-.72.28-1.372.68-1.928 1.22a1.677 1.677 0 00-.14.12z'
                fill='currentColor'
            />
            <path
                d='M10.256 12.968c.42-.392.864-.672 1.344-.884.864-.38 1.776-.544 2.72-.572.192-.004.388-.008.576-.052a1.433 1.433 0 001.112-1.388c.008-.94.004-1.876.004-2.816V3.14v-.252c.204 0 .4-.028.584.008.256.044.408.288.408.588-.004 1.08-.012 2.16-.012 3.236 0 1.452.008 2.904.012 4.356v1.42c-.004.44-.308.684-.74.592-1.388-.3-2.784-.472-4.204-.364-.544.04-1.08.14-1.62.216-.052.008-.096.012-.184.028zM1.976 2.876v2.008c0 .068-.004.132-.004.2V8.8c0 .408.016.812.02 1.22.012.852.616 1.472 1.476 1.488.864.016 1.712.124 2.532.416a5.037 5.037 0 011.576.888c.048.04.096.088.152.14-.452-.068-.884-.144-1.32-.192-1.524-.164-3.032-.008-4.528.3-.152.032-.32.052-.468.024-.252-.048-.412-.268-.412-.528 0-1.96.008-3.916.008-5.876 0-1.032-.008-2.064-.012-3.096 0-.516.192-.708.708-.708h.272z'
                fill='currentColor'
            />
        </svg>
    );
}

export default CatalogIcon;
