import React, { useEffect, useState, useLayoutEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

// Components
import Error from '../../components/Error/Error';
import Filters from '../../components/Filters/Filters';
import FormSearch from '../../components/Form/FormSearch';
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import TableFournisseurs from '../../components/Table/TableFournisseurs';

// api
import { useGetCommandesFournisseur } from '../../api/hooks/commandesFournisseur.hooks';
import { useFournisseursFiltersContext } from '../../contexts/filters/fournisseursFiltersContext';

// hooks
import useDebounce from '../../hooks/useDebounce';
import useDidMountEffect from '../../hooks/useDidMountEffect';

// utils
import { filterObjectsByValue } from '../../utils/object';
import useScrollPosition from '../../hooks/useScrollPosition';

function Fournisseurs() {
    const { fournisseursFilters, setFournisseursFilters } = useFournisseursFiltersContext();
    const { data: commandes, isLoading, isError } = useGetCommandesFournisseur();

    const [filteredData, setFilteredData] = useState([]);

    const methods = useForm({
        defaultValues: fournisseursFilters,
    });

    const searchInput = useWatch({ control: methods.control, name: 'search-input' });
    const debouncedSearchInput = useDebounce(searchInput, 500);
    useDidMountEffect(() => {
        setFournisseursFilters((prevState) => ({
            ...prevState,
            'search-input': debouncedSearchInput,
        }));
    }, [debouncedSearchInput]);

    useEffect(() => {
        if (!fournisseursFilters || fournisseursFilters?.['search-input'] === '') {
            return setFilteredData(commandes);
        }
        const newData = filterObjectsByValue(commandes, debouncedSearchInput, [
            'fournisseur',
            'numero',
        ]);
        return setFilteredData(newData);
    }, [commandes, fournisseursFilters]);

    // Scroll Restoration
    const { scrollPosition } = useScrollPosition();
    useEffect(() => {
        if (fournisseursFilters?.pageYOffset) {
            setTimeout(() => {
                window.scrollTo(0, fournisseursFilters.pageYOffset);
            }, 2);
        }
    }, [window]);
    useLayoutEffect(() => {
        if (scrollPosition !== null) {
            setFournisseursFilters((prev) => ({ ...prev, pageYOffset: scrollPosition }));
        }
    }, [scrollPosition]);

    if (isError)
        return <Error message="Erreur lors de la récupération des commandes fournisseur" />;

    return (
        <>
            <Header>
                <h1 className="text-lg">Commandes fournisseurs</h1>
            </Header>
            <Filters>
                <FormProvider {...methods}>
                    <FormSearch placeholder="Nom, Référence, ..." name="search-input" />
                </FormProvider>
            </Filters>

            <section className="section">
                <Table data={filteredData}>
                    <TableFournisseurs loading={isLoading} />
                </Table>
            </section>
        </>
    );
}

export default Fournisseurs;
