/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useWatch } from 'react-hook-form';

const ReductionCell = ({ disabled = false, defaultValue, name, control, setValue }) => {
    const [reduction, setReduction] = useState(defaultValue);

    if (control) {
        const watchReduction = useWatch({ control, name });
        useEffect(() => {
            setReduction(watchReduction);
        }, [watchReduction]);
    }

    useEffect(() => {
        setReduction(defaultValue || 0);
    }, [name]);

    useEffect(() => {
        setValue(name, parseInt(reduction, 10) || 0);
    }, [reduction]);

    return (
        <p className={`text-link input-reduction ${!reduction ? 'disabled' : undefined}`}>
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                render={({ field: { onChange, value } }) => (
                    <input
                        disabled={disabled}
                        min={0}
                        max={100}
                        name={name}
                        placeholder={0}
                        autoComplete='off'
                        type='number'
                        value={value}
                        onChange={(e) => {
                            setReduction(parseFloat(e.target.value, 10));
                            onChange(parseFloat(e.target.value, 10) || 0);
                        }}
                        onFocus={(event) => event.target.select()}
                    />
                )}
            />
            <span>%</span>
        </p>
    );
};

export default ReductionCell;

ReductionCell.propTypes = {
    disabled: PropTypes.bool,
    defaultValue: PropTypes.number,
    name: PropTypes.string.isRequired,
    control: PropTypes.shape().isRequired,
    setValue: PropTypes.func.isRequired,
};

ReductionCell.defaultProps = {
    disabled: false,
    defaultValue: 0,
};
