/* eslint-disable react/prop-types */

import React, { createContext, useContext, useState } from 'react';

// create context
const CommandesDetailsFiltersContext = createContext();

const CommandesDetailsFiltersContextProvider = ({ children }) => {
    const [commandesDetailsFilters, setCommandesDetailsFilters] = useState(null);

    return (
        <CommandesDetailsFiltersContext.Provider
            value={{ commandesDetailsFilters, setCommandesDetailsFilters }}
        >
            {children}
        </CommandesDetailsFiltersContext.Provider>
    );
};

const useCommandesDetailsFiltersContext = () => {
    const context = useContext(CommandesDetailsFiltersContext);

    if (context === undefined) {
        throw new Error('useCommandesDetailsFiltersContext est utilisé en dehors de son provider');
    }

    return context;
};

export { CommandesDetailsFiltersContextProvider, useCommandesDetailsFiltersContext };
