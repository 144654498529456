import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';

// icons
import SearchIcon from '../../assets/icons/SearchIcon';
import CloseIcon from '../../assets/icons/CloseIcon';

function FormSearch({ placeholder, name }) {
    const { register, setValue, control } = useFormContext();
    const searchInput = useWatch({ control, name });

    return (
        <div className="input-search">
            <SearchIcon />
            <input
                {...register(name)}
                type="text"
                aria-label="Search through site content"
                placeholder={placeholder}
                autoComplete="off"
            />
            {searchInput && (
                <button type="button" onClick={() => setValue(name, '')}>
                    <CloseIcon />
                </button>
            )}
        </div>
    );
}

FormSearch.propTypes = {
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default FormSearch;
