/* eslint-disable react/prop-types */

import React, { createContext, useContext, useState } from 'react';

// create context
const BonsLivraisonFiltersContext = createContext();

const BonsLivraisonFiltersContextProvider = ({ children }) => {
    const [bonsLivraisonFilters, setBonsLivraisonFilters] = useState(null);

    return (
        <BonsLivraisonFiltersContext.Provider
            value={{ bonsLivraisonFilters, setBonsLivraisonFilters }}
        >
            {children}
        </BonsLivraisonFiltersContext.Provider>
    );
};

const useBonsLivraisonFiltersContext = () => {
    const context = useContext(BonsLivraisonFiltersContext);

    if (context === undefined) {
        throw new Error('useBonsLivraisonFiltersContext est utilisé en dehors de son provider');
    }

    return context;
};

export { BonsLivraisonFiltersContextProvider, useBonsLivraisonFiltersContext };
