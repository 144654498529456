/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState } from 'react';

// create context
const ClientsFiltersContext = createContext();

const ClientsFiltersContextProvider = ({ children }) => {
    const [clientsFilters, setClientsFilters] = useState(null);

    return (
        <ClientsFiltersContext.Provider value={{ clientsFilters, setClientsFilters }}>
            {children}
        </ClientsFiltersContext.Provider>
    );
};

const useClientsFiltersContext = () => {
    const context = useContext(ClientsFiltersContext);

    if (context === undefined) {
        throw new Error('useClientsFiltersContext est utilisé en dehors de son provider');
    }

    return context;
};

export { ClientsFiltersContextProvider, useClientsFiltersContext };
