/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';

function Badge({ type, content }) {
    switch (content) {
        case 'à traiter':
            type = 'error';
            break;
        case 'à livrer':
            type = 'warning';
            break;
        case 'livrée partiellement':
            type = 'warning';
            break;
        case 'livrée':
            type = 'success';
            break;

        default:
            break;
    }

    return <span className={`badge ${type} text-nowrap text-capitalize`}>{content}</span>;
}

export default Badge;

Badge.propTypes = {
    type: PropTypes.string,
    content: PropTypes.string,
};

Badge.defaultProps = {
    type: '',
    content: '',
};
