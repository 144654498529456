/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

// Components
import Header from '../../components/Header/Header';
import Filters from '../../components/Filters/Filters';
import FormSearch from '../../components/Form/FormSearch';
import Button from '../../components/Button/Button';
import Error from '../../components/Error/Error';

import Modal from '../../components/Modal/Modal';
import ModalHeader from '../../components/Modal/ModalHeader';
import NewClientForm from './NewClientForm';

// Table
import Table from '../../components/Table/Table';
import TableClients from '../../components/Table/TableClients';

// Icons
import PlusIcon from '../../assets/icons/PlusIcon';

// Hooks
import useModal from '../../hooks/useModal';

// api
import { useGetClients } from '../../api/hooks/clients.hooks';
import { useClientsFiltersContext } from '../../contexts/filters/clientsFiltersContext';
import useDebounce from '../../hooks/useDebounce';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { filterObjectsByValue } from '../../utils/object';
import useScrollPosition from '../../hooks/useScrollPosition';

export default function Clients() {
    const { clientsFilters, setClientsFilters } = useClientsFiltersContext();
    const [filteredData, setFilteredData] = useState([]);

    const { data: clients, isLoading, isError } = useGetClients();
    const [isShowing, toggle] = useModal();

    const methods = useForm({
        defaultValues: clientsFilters,
    });

    const searchInput = useWatch({ control: methods.control, name: 'search-input' });
    const debouncedSearchInput = useDebounce(searchInput, 500);
    useDidMountEffect(() => {
        setClientsFilters((prevState) => ({
            ...prevState,
            'search-input': debouncedSearchInput,
        }));
    }, [debouncedSearchInput]);

    useEffect(() => {
        if (!clientsFilters || clientsFilters?.['search-input'] === '') {
            return setFilteredData(clients);
        }
        const newData = filterObjectsByValue(clients, debouncedSearchInput, [
            'nom',
            'email',
            'tel',
        ]);
        return setFilteredData(newData);
    }, [clients, clientsFilters]);

    // Scroll Restoration
    const { scrollPosition } = useScrollPosition();
    useEffect(() => {
        if (clientsFilters?.pageYOffset) {
            setTimeout(() => {
                window.scrollTo(0, clientsFilters.pageYOffset);
            }, 2);
        }
    }, [window]);
    useLayoutEffect(() => {
        if (scrollPosition !== null) {
            setClientsFilters((prev) => ({ ...prev, pageYOffset: scrollPosition }));
        }
    }, [scrollPosition]);

    if (isError) return <Error message="Erreur lors de la récupération de la liste des clients" />;

    return (
        <>
            <Header>
                <h1 className="text-lg mr-2">Clients</h1>
                <Button icon={<PlusIcon />} onClick={toggle}>
                    Nouveau
                </Button>
            </Header>
            <Filters>
                <div className="flex space">
                    <FormProvider {...methods}>
                        <FormSearch placeholder="Nom, Référence, ..." name="search-input" />
                    </FormProvider>
                </div>
            </Filters>

            <section className="section">
                <Table data={filteredData}>
                    <TableClients loading={isLoading} />
                </Table>
            </section>

            {isShowing && (
                <Modal isShowing={isShowing} hide={toggle} right>
                    <ModalHeader>Nouveau Client</ModalHeader>
                    <NewClientForm />
                </Modal>
            )}
        </>
    );
}
