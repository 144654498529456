import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';

// Icons
import CheckIcon from '../../assets/icons/CheckIcon';
import ChevronIcon from '../../assets/icons/ChevronIcon';
import Button from '../Button/Button';

export default function FormDropdown({ name, label, list }) {
    const node = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [counter, setCounter] = useState(0);
    const { register, control, setValue } = useFormContext();

    const selectedValues = useWatch({ control, name });

    useEffect(() => {
        if (selectedValues) {
            return setCounter(selectedValues.length);
        }
        return setCounter(0);
    }, [selectedValues]);

    // Fermer dropdown quand on clique dehors
    const handleClick = (e) => {
        if (node.current.contains(e.target)) {
            return;
        }
        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen === true) {
            document.addEventListener('mousedown', handleClick);
        }
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [isOpen]);

    return (
        <div className={`input-dropdown ${isOpen ? 'is-open' : undefined}`} ref={node}>
            <button
                className="input-dropdown__button"
                onClick={() => setIsOpen(!isOpen)}
                type="button"
            >
                {counter > 0 && <span className="counter">{counter}</span>}
                <p className="text-nowrap">{label}</p>
                <ChevronIcon />
            </button>
            <div className="dropdown-inner">
                {list.map(({ id, nom }) => (
                    <p key={`${name}-${id}`}>
                        <input
                            {...register(name)}
                            type="checkbox"
                            value={id}
                            id={`${name}-${id}`}
                            checked={selectedValues?.includes(id) || false}
                        />
                        <label htmlFor={`${name}-${id}`}>
                            {nom}
                            <CheckIcon />
                        </label>
                    </p>
                ))}
                <div className="dropdown-inner__footer">
                    <Button onClick={() => setValue(name, [])} border type="reset">
                        Effacer
                    </Button>
                    <Button onClick={() => setIsOpen(false)} type="button">
                        Fermer
                    </Button>
                </div>
            </div>
        </div>
    );
}

FormDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
FormDropdown.defaultProps = {
    // filters: {},
};
