/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import { useHistory } from 'react-router-dom';

// Components
import DateCell from './DateCell';
import LoaderSpinner from '../Loader/LoaderSpinner';

// Icons
import ButtonIcon from '../Button/ButtonIcon';
import PdfIcon from '../../assets/icons/PdfIcon';

// Utils
import { getTableHeight } from '../../utils/utils';
import TruckIcon from '../../assets/icons/TruckIcon';
import { useLivrerBonLivraison } from '../../api/hooks/bonLivraisons.hooks';
import useModal from '../../hooks/useModal';
import Modal from '../Modal/Modal';
import ButtonSecondary from '../Button/ButtonSecondary';
import Loader from '../Loader/Loader';
import Button from '../Button/Button';
import CommandesIcon from '../../assets/icons/CommandesIcon';

function TableLivraisons({ handleSortColumn, sortData, sortColumn, loading }) {
    const history = useHistory();

    return (
        <Table
            autoHeight
            affixHeader={110}
            height={getTableHeight(238)}
            renderLoading={LoaderSpinner}
            className="table"
            data={sortData()}
            rowHeight={72}
            sortColumn={sortColumn}
            onSortColumn={handleSortColumn}
            defaultSortType="asc"
            loading={loading}
            locale={{
                emptyMessage: "Aucun bon de livraison n'a été trouvé",
                loading: 'Chargement',
            }}
        >
            <Column flexGrow={1} minWidth={300} sortable>
                <HeaderCell>Client</HeaderCell>
                <Cell dataKey="client" />
            </Column>

            <Column flexGrow={1} minWidth={120} sortable>
                <HeaderCell>Numéro</HeaderCell>
                <Cell dataKey="numero" />
            </Column>

            <Column flexGrow={1} minWidth={200} sortable>
                <HeaderCell>Commande</HeaderCell>
                <Cell dataKey="commande" />
            </Column>

            <Column flexGrow={1} minWidth={200} sortable>
                <HeaderCell>Date de création</HeaderCell>
                <DateCell dataKey="date" />
            </Column>

            <Column fixed="right" width={176}>
                <HeaderCell />
                <Cell>
                    {({ pdf, id, id_etat, devis_id }) => (
                        <div className="flex right w-100">
                            {parseInt(id_etat, 10) === 1 && (
                                <ButtonIcon
                                    onClick={() => history.push(`/commandes/${devis_id}`)}
                                    icon={<CommandesIcon />}
                                />
                            )}
                            {parseInt(id_etat, 10) === 1 && <ValiderLivraison id={id} />}
                            <ButtonIcon
                                onClick={() => window.open(pdf, '_blank')}
                                icon={<PdfIcon />}
                            />
                        </div>
                    )}
                </Cell>
            </Column>
        </Table>
    );
}

export default TableLivraisons;

const ValiderLivraison = ({ id }) => {
    const [isShowing, toggle] = useModal();

    useEffect(() => {}, []);

    const { isLoading, mutate: livrer } = useLivrerBonLivraison();

    return (
        <>
            <ButtonIcon onClick={toggle} icon={<TruckIcon />} status="success" />
            {isShowing && (
                <Modal isShowing={isShowing} hide={toggle}>
                    <div className="p-5 flex column center">
                        <p className="text-bold text-center mb-3">
                            Confirmer que la commande a été récupérée par le transporteur ?
                        </p>
                        <Button onClick={() => livrer(id)} disabled={isLoading}>
                            Confirmer
                            {isLoading && <Loader />}
                        </Button>
                        <ButtonSecondary onClick={toggle}>
                            <p className="mt-2">Annuler</p>
                        </ButtonSecondary>
                    </div>
                </Modal>
            )}
        </>
    );
};
