import React from 'react';
import PropTypes from 'prop-types';

// Hooks
import useModal from '../../../hooks/useModal';

// Icons
import ButtonIcon from '../../Button/ButtonIcon';
import PenIcon from '../../../assets/icons/PenIcon';

// Components
import Modal from '../../Modal/Modal';
import ModalHeader from '../../Modal/ModalHeader';
import SignatureForm from '../../../pages/Devis/SignatureForm';

const SignDevis = ({ id }) => {
    const [isShowing, toggle] = useModal();

    return (
        <>
            <ButtonIcon onClick={toggle} icon={<PenIcon />} />
            {isShowing && (
                <Modal isShowing={isShowing} hide={toggle} right>
                    <ModalHeader>Signer le devis</ModalHeader>
                    <SignatureForm id={id} />
                </Modal>
            )}
        </>
    );
};

export default SignDevis;

SignDevis.propTypes = {
    id: PropTypes.number.isRequired,
};
