/* eslint-disable no-empty-pattern */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

// Components
import ImageCell from './ImageCell';
import NameCell from './NameCell';
import Modal from '../Modal/Modal';
import ReductionCell from './ReductionCell';
import ProductDetails from '../ProductDetails/ProductDetails';
import LoaderSpinner from '../Loader/LoaderSpinner';
import FormQuantity from '../Form/FormQuantity';

// Hooks
import useModal from '../../hooks/useModal';

// Icons
import ButtonIcon from '../Button/ButtonIcon';
import CheckIcon from '../../assets/icons/CheckIcon';
import DeleteIcon from '../../assets/icons/DeleteIcon';

// Utils
import { getTableHeight, notify } from '../../utils/utils';
import TotalHtCell from './TotalHtCell';
import BoxIcon from '../../assets/icons/BoxIcon';
import QuantityDetails from './modules/QuantityDetails';
import { useDeleteLigneAvoir, useUpdateLigneAvoir } from '../../api/hooks/ligneAvoir.hooks';
import PriceCell from './PriceCell';
import QuantityDetailsAvoir from './modules/QuantityDetailsAvoir';

function TableAvoirDetails({ handleSortColumn, sortData, sortColumn, loading }) {
    const [firstLaunch, setFirstLaunch] = useState(true);
    const tableRef = useRef();
    const { avoirId } = useParams();
    const [productId, setProductId] = useState(null);
    const [isShowing, toggle] = useModal();

    const { isLoading: loadingEditLine, mutate: updateLigneAvoir } = useUpdateLigneAvoir(parseInt(avoirId, 10));
    const { mutate: removeLigneAvoir } = useDeleteLigneAvoir(parseInt(avoirId, 10));

    const { register, setValue, getValues, control } = useForm();

    const openDetails = (id) => {
        setProductId(id);
        toggle();
    };

    const handleEditLine = (ligneId = null, productId, data) => {
        const currentScroll = Math.abs(tableRef.current.scrollY);
        console.log(currentScroll);
        if (!data.qte) {
            return notify('Quantité incorrecte', 'error');
        }
        const ligneAvoir = {
            avoir_id: avoirId,
            ligne_avoir_id: ligneId,
            produit_id: productId,
            ...data,
        };
        updateLigneAvoir(ligneAvoir);

        return setTimeout(() => {
            tableRef.current.scrollTop(currentScroll);
        }, 500);
    };

    const handleDeleteLine = (ligneId) => {
        const currentScroll = Math.abs(tableRef.current.scrollY);
        removeLigneAvoir(ligneId, { onSettled: () => tableRef.current.scrollTop(currentScroll) });
    };

    return (
        <>
            <Table
                ref={tableRef}
                height={getTableHeight(238)}
                renderLoading={LoaderSpinner}
                className='table'
                data={sortData()}
                rowHeight={100}
                sortColumn={sortColumn}
                onSortColumn={handleSortColumn}
                defaultSortType='asc'
                loading={firstLaunch && loading}
                locale={{ emptyMessage: 'Aucun produit', loading: 'Chargement' }}
                onDataUpdated={() => !loading && setFirstLaunch(false)}
            >
                <Column>
                    <HeaderCell />
                    <ImageCell dataKey='vignette' openDetails={openDetails} />
                </Column>

                <Column flexGrow={1} minWidth={300} sortable>
                    <HeaderCell>Produit</HeaderCell>
                    <NameCell dataKey='nom' />
                </Column>

                <Column width={130}>
                    <HeaderCell>Stock virtuel</HeaderCell>
                    <Cell dataKey='stock'>{({ stock }) => <p>{stock}</p>}</Cell>
                </Column>

                <Column width={115}>
                    <HeaderCell>Stock total</HeaderCell>
                    <Cell dataKey='stock'>{({ stock_reel }) => <p>{stock_reel}</p>}</Cell>
                </Column>

                <Column width={150} sortable>
                    <HeaderCell>Zone</HeaderCell>
                    <Cell dataKey='zone_de_stockage' />
                </Column>

                <Column width={90}>
                    <HeaderCell>cond.</HeaderCell>
                    <Cell>
                        {({ conditionnement }) => (
                            <div className='flex center'>
                                <p>{conditionnement}</p>
                            </div>
                        )}
                    </Cell>
                </Column>

                <Column align='right' width={110}>
                    <HeaderCell>Prix U. HT</HeaderCell>
                    <Cell>
                        {({ prix, ligne_id }) => (
                            <PriceCell
                                name={`${ligne_id}.prix_unitaire`}
                                control={control}
                                defaultValue={parseFloat(prix, 10)}
                                setValue={setValue}
                            />
                        )}
                    </Cell>
                </Column>

                <Column align='right' width={166}>
                    <HeaderCell>Quantité</HeaderCell>
                    <Cell>
                        {({ ligne_id, qte, conditionnement }) => (
                            <FormQuantity
                                name={`${ligne_id}.qte`}
                                register={register}
                                setValue={setValue}
                                getValues={getValues}
                                control={control}
                                value={parseInt(qte, 10)}
                                step={parseInt(conditionnement, 10)}
                                editable
                            />
                        )}
                    </Cell>
                </Column>

                <Column align='right' width={91}>
                    <HeaderCell>
                        <p>Reduc.</p>
                    </HeaderCell>
                    <Cell>
                        {({ remise, ligne_id }) => (
                            <ReductionCell
                                name={`${ligne_id}.remise`}
                                control={control}
                                defaultValue={parseInt(remise, 10)}
                                setValue={setValue}
                            />
                        )}
                    </Cell>
                </Column>

                <Column align='right' width={140}>
                    <HeaderCell>
                        <p>Total HT</p>
                    </HeaderCell>
                    <Cell>
                        {({ ligne_id, prix }) => (
                            <TotalHtCell
                                name={ligne_id}
                                control={control}
                                prix={parseFloat(prix)}
                                getValues={getValues}
                                isAvoir
                            />
                        )}
                    </Cell>
                </Column>

                <Column fixed='right' width={176}>
                    <HeaderCell />
                    <Cell>
                        {({ id, ligne_id, declinaison }) => (
                            <div className='flex right w-100'>
                                {parseInt(declinaison, 10) === 1 && <Declinaison id={ligne_id} />}
                                <ButtonIcon
                                    onClick={() => handleEditLine(ligne_id, id, getValues(ligne_id))}
                                    icon={<CheckIcon />}
                                    disabled={loadingEditLine}
                                />
                                <ButtonIcon
                                    onClick={() => handleDeleteLine(ligne_id)}
                                    icon={<DeleteIcon />}
                                    status='error'
                                />
                            </div>
                        )}
                    </Cell>
                </Column>
            </Table>

            {isShowing && productId && (
                <Modal isShowing={isShowing} hide={toggle} right>
                    <ProductDetails productId={productId} />
                </Modal>
            )}
        </>
    );
}

export default TableAvoirDetails;

const Declinaison = ({ id }) => {
    const [isShowing, toggle] = useModal();

    return (
        <>
            <ButtonIcon onClick={toggle} icon={<BoxIcon />} />
            {isShowing && (
                <Modal isShowing={isShowing} hide={toggle}>
                    <QuantityDetailsAvoir toggle={toggle} ligneId={parseInt(id, 10)} />
                </Modal>
            )}
        </>
    );
};
