/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';

// Components
import LoaderSpinner from '../Loader/LoaderSpinner';

function TableDeclinaison({ handleSortColumn, sortData, sortColumn, loading }) {
    return (
        <Table
            style={{ width: 300, marginTop: 40 }}
            height={500}
            renderLoading={LoaderSpinner}
            className="table"
            data={sortData()}
            rowHeight={72}
            sortColumn={sortColumn}
            onSortColumn={handleSortColumn}
            defaultSortType="asc"
            loading={loading}
            locale={{ emptyMessage: 'Aucune déclinaison', loading: 'Chargement' }}
        >
            <Column flexGrow={1} sortable>
                <HeaderCell>Déclinaison</HeaderCell>
                <Cell dataKey="nom" />
            </Column>

            <Column flexGrow={1}>
                <HeaderCell>Quantité</HeaderCell>
                <Cell dataKey="qte" />
            </Column>
        </Table>
    );
}

export default TableDeclinaison;
