import * as React from 'react';

function SearchIcon() {
    return (
        <svg width='1em' height='1em' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M17.7 17l-3.778-3.784L17.7 17zm-1.684-8.842a7.158 7.158 0 11-14.316 0 7.158 7.158 0 0114.316 0v0z'
                stroke='currentColor'
                strokeWidth={2}
                strokeLinecap='round'
            />
        </svg>
    );
}

export default SearchIcon;
