import * as React from "react";

function InputMinusIcon() {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 .5C5.373.5 0 5.873 0 12.5s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zM7.636 11.41a1.09 1.09 0 100 2.18h8.728a1.09 1.09 0 100-2.18H7.636z"
                fill="#2E5599"
            />
        </svg>
    );
}

export default InputMinusIcon;
