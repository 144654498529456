import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { numberToEUR } from '../../utils/utils';

function PriceDetails({ HT, TVA, TTC, remise }) {
    return (
        <div className='price-details'>
            <div>
                {typeof remise === 'number' && <p>Remise générale</p>}
                <p>Total HT</p>
                <p>TVA</p>
                <p>Total TTC</p>
            </div>
            <div className='text-right'>
                {typeof remise === 'number' && <p className='ml-5 text-bold'>{parseInt(remise, 10)} %</p>}
                <p className='ml-5 text-bold'>{numberToEUR(HT)}</p>
                <p className='ml-5 text-bold'>{numberToEUR(TVA)}</p>
                <p className='ml-5 text-bold'>{numberToEUR(TTC)}</p>
            </div>
        </div>
    );
}

export default PriceDetails;

PriceDetails.propTypes = {
    HT: PropTypes.number.isRequired,
    TVA: PropTypes.number.isRequired,
    TTC: PropTypes.number.isRequired,
    remise: PropTypes.number,
};

PriceDetails.defaultProps = {
    remise: null,
};
