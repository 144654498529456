import * as React from "react";

function PenIcon() {
    return (
        <svg width="1em" height="1em" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.176.72c-.336.142-.64.35-.894.611h.001l-9.712 9.724a.564.564 0 00-.135.22L.03 15.503a.564.564 0 00.67.725l4.503-1.127c.1-.025.19-.076.263-.148l7.482-7.494 2.229-2.23A2.758 2.758 0 0013.236.5a2.75 2.75 0 00-1.06.22zM1.427 17.265l-.056-.042 1.518-.38a4.82 4.82 0 002.177.53c.57 0 1.142-.2 1.727-.534.588-.336 1.153-.783 1.718-1.242l.147-.12c.5-.409 1.015-.828 1.505-1.118.531-.313 1.176-.56 1.836-.338.926.307 1.218 1.16 1.488 2.063.062.206.168.41.293.551.125.141.225.174.293.174.211 0 .514-.114.898-.342.243-.143.454-.288.662-.433.127-.089.254-.176.386-.261.358-.232.667-.388.89-.486.114-.05.23-.096.349-.136l.022-.006.009-.002.003-.002.16.541-.159-.54a.562.562 0 01.642.807.564.564 0 01-.324.274l-.008.002-.045.016c-.066.024-.131.05-.196.079a5.227 5.227 0 00-.73.399c-.08.051-.182.121-.297.2-.234.162-.523.361-.792.519-.408.241-.935.498-1.47.498-.495 0-.878-.261-1.135-.552a2.632 2.632 0 01-.53-.977c-.296-.987-.466-1.217-.765-1.319-.183-.06-.454-.026-.908.243-.412.243-.86.609-1.383 1.034l-.131.107c-.561.456-1.191.96-1.87 1.347-.682.388-1.448.681-2.285.681-1.563 0-2.802-.626-3.639-1.235z"
                fill="#fff"
            />
        </svg>
    );
}

export default PenIcon;
