/* eslint-disable import/prefer-default-export */
import { useMutation, useQueryClient } from 'react-query';
import { notify } from '../../utils/utils';
import commentairesActions from '../actions/commentaires.actions';

export function useCreateCommentaire() {
    const queryClient = useQueryClient();
    return useMutation(commentairesActions.create, {
        onSuccess: () => {
            notify('Le commentaire a bien été envoyé', 'success');
        },
        onError: () => {
            notify('Une erreur est survenue', 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('devis');
            queryClient.invalidateQueries('commandes');
        },
    });
}
