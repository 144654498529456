/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

export default function ButtonSecondary({ children, onClick, type }) {
    return (
        <button onClick={onClick} type={type} className="button-secondary">
            {children}
        </button>
    );
}

ButtonSecondary.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    type: PropTypes.string,
};

ButtonSecondary.defaultProps = {
    onClick: undefined,
    type: 'submit',
};
