import * as React from 'react';

function ChevronIcon() {
    return (
        <svg width='0.5em' height='1em' viewBox='0 0 5 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M.194 1.617l1.692 1.692a.65.65 0 00.92 0L4.5 1.617A.655.655 0 004.035.5H.65C.07.5-.218 1.206.194 1.617z' fill='currentColor' />
        </svg>
    );
}

export default ChevronIcon;
