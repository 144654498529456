import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useForm, FormProvider, useWatch } from 'react-hook-form';

// Components
import Header from '../../components/Header/Header';
import Filters from '../../components/Filters/Filters';
import FormSearch from '../../components/Form/FormSearch';
import Table from '../../components/Table/Table';
import TableAvoir from '../../components/Table/TableAvoir';
import Button from '../../components/Button/Button';
import Error from '../../components/Error/Error';

// Modal
import Modal from '../../components/Modal/Modal';
import ModalHeader from '../../components/Modal/ModalHeader';
import NewAvoirForm from './NewAvoirForm';

// Icons
import PlusIcon from '../../assets/icons/PlusIcon';

// Hooks
import useModal from '../../hooks/useModal';
import { useGetAvoirs } from '../../api/hooks/avoirs.hooks';
import useDebounce from '../../hooks/useDebounce';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { useAvoirsFiltersContext } from '../../contexts/filters/avoirsFiltersContext';

// utils
import { filterObjectsByValue } from '../../utils/object';
import useScrollPosition from '../../hooks/useScrollPosition';

export default function Avoirs() {
    const { avoirsFilters, setAvoirsFilters } = useAvoirsFiltersContext();
    const [filteredData, setFilteredData] = useState([]);
    const [modalIsShowing, toggleModal] = useModal();

    const methods = useForm({
        defaultValues: avoirsFilters,
    });

    const { data: avoirs, isLoading, isError } = useGetAvoirs();

    const searchInput = useWatch({ control: methods.control, name: 'search-input' });
    const debouncedSearchInput = useDebounce(searchInput, 500);
    useDidMountEffect(() => {
        setAvoirsFilters((prevState) => ({
            ...prevState,
            'search-input': debouncedSearchInput,
        }));
    }, [debouncedSearchInput]);

    useEffect(() => {
        if (!avoirsFilters || avoirsFilters?.['search-input'] === '') {
            return setFilteredData(avoirs);
        }
        const newData = filterObjectsByValue(avoirs, debouncedSearchInput, ['client', 'numero']);
        return setFilteredData(newData);
    }, [avoirs, avoirsFilters]);

    // Scroll Restoration
    const { scrollPosition } = useScrollPosition();
    useEffect(() => {
        if (avoirsFilters?.pageYOffset) {
            setTimeout(() => {
                window.scrollTo(0, avoirsFilters.pageYOffset);
            }, 2);
        }
    }, [window]);
    useLayoutEffect(() => {
        if (scrollPosition !== null) {
            setAvoirsFilters((prev) => ({ ...prev, pageYOffset: scrollPosition }));
        }
    }, [scrollPosition]);

    if (isError) return <Error message='Erreur lors de la récupération de la liste des avoirs' />;

    return (
        <>
            <Header>
                <h1 className='text-lg mr-2'>Avoirs</h1>
                <Button icon={<PlusIcon />} onClick={toggleModal}>
                    Nouveau
                </Button>
            </Header>
            <Filters>
                <div className='flex space'>
                    <FormProvider {...methods}>
                        <FormSearch placeholder='Client, Numéro, ...' name='search-input' />
                    </FormProvider>
                </div>
            </Filters>

            <section className='section'>
                <Table data={filteredData}>
                    <TableAvoir loading={isLoading} />
                </Table>
            </section>

            {modalIsShowing && (
                <Modal isShowing={modalIsShowing} hide={toggleModal} right>
                    <ModalHeader>Créer un avoir</ModalHeader>
                    <NewAvoirForm />
                </Modal>
            )}
        </>
    );
}
