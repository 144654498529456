import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

// Utils
import { numberToEUR } from '../../utils/utils';

const TotalHtCell = ({ name, control, prix, getValues }) => {
    const [totalHt, setTotalHt] = useState(0);
    const product = useWatch({
        control,
        name,
    });

    const calculTotal = (obj) => {
        if (obj) {
            const quantity = obj.qte;
            const remise = obj.remise || 0;

            let total = parseInt(quantity, 10) * parseFloat(prix);
            total *= 1 - remise / 100;
            return total;
        }
        return 0;
    };

    useEffect(() => {
        if (product && product.qte) {
            const total = calculTotal(product);
            return setTotalHt(total);
        }
        return setTotalHt(0);
    }, [product]);

    useEffect(() => {
        const data = getValues(name);
        setTotalHt(calculTotal(data));
    }, []);

    return <p>{numberToEUR(totalHt)}</p>;
};

export default TotalHtCell;

TotalHtCell.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.shape().isRequired,
    prix: PropTypes.number.isRequired,
    getValues: PropTypes.func.isRequired,
};
