import * as React from 'react';

function LogOutIcon() {
    return (
        <svg width='1.5em' height='1.5em' viewBox='0 0 16 14' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M4.308 7a.615.615 0 01.615-.615h5.539v-4C10.462 1.154 9.162.23 8 .23H2.154A2.156 2.156 0 000 2.385v9.23a2.156 2.156 0 002.154 2.154h6.154a2.157 2.157 0 002.154-2.153v-4H4.923A.615.615 0 014.308 7zM15.82 6.565l-3.077-3.077a.615.615 0 00-.87.87l2.026 2.027h-3.437v1.23h3.437l-2.026 2.027a.615.615 0 10.87.87l3.077-3.077a.616.616 0 000-.87z'
                fill='currentColor'
            />
        </svg>
    );
}

export default LogOutIcon;
