import { useMutation, useQuery, useQueryClient } from 'react-query';
import { notify } from '../../utils/utils';
import ligneAvoirsActions from '../actions/ligneAvoirs.actions';

export function useGetLigneAvoir(id, options) {
    return useQuery(['ligne-avoirs', id], () => ligneAvoirsActions.item(id), { ...options });
}

export function useUpdateLigneAvoir(avoirId) {
    const queryClient = useQueryClient();
    return useMutation((ligneAvoir) => ligneAvoirsActions.update(ligneAvoir), {
        onMutate: async (newLigne) => {
            await queryClient.cancelQueries(['avoir', avoirId]);

            const prevAvoir = queryClient.getQueryData(['avoir', avoirId]);
            const updatedLignes = prevAvoir.ligne_avoir.map((ligne) =>
                ligne.ligne_id === newLigne.ligne_devis_id
                    ? { ...ligne, qte: newLigne.qte, remise: newLigne.remise, prix_unitaire: newLigne.prix_unitaire }
                    : ligne
            );

            queryClient.setQueryData(['avoir', avoirId], {
                ...prevAvoir,
                ligne_avoir: updatedLignes,
            });
            return { prevAvoir };
        },
        onSuccess: () => {
            notify('Quantité modifiée', 'success');
        },
        onError: (_err, _variables, context) => {
            notify('Une erreur est survenue', 'error');
            queryClient.setQueryData(['avoir', avoirId], context.prevDevis);
        },
        onSettled: () => {
            queryClient.invalidateQueries(['avoir', avoirId]);
            queryClient.invalidateQueries('avoirs', { exact: true });
        },
    });
}

export function useDeleteLigneAvoir(avoirId) {
    const queryClient = useQueryClient();
    return useMutation((ligneId) => ligneAvoirsActions.remove(ligneId), {
        onMutate: async (ligneId) => {
            await queryClient.cancelQueries(['avoir', avoirId]);

            const prevAvoir = queryClient.getQueryData(['avoir', avoirId]);
            const removeDeleted = prevAvoir.ligne_avoir.filter((ligne) => ligne.ligne_id !== ligneId);
            queryClient.setQueryData(['avoir', avoirId], {
                ...prevAvoir,
                ligne_avoir: removeDeleted,
            });
            return { prevAvoir };
        },
        onSuccess: () => {
            notify('Ligne supprimée', 'success');
        },
        onError: (_err, _variables, context) => {
            notify('Une erreur est survenue', 'error');
            queryClient.setQueryData(['avoir', avoirId], context.prevDevis);
        },
    });
}

export function useUpdateDeclinaisonsLigneAvoirs(ligneId) {
    const queryClient = useQueryClient();
    return useMutation(ligneAvoirsActions.updateDeclinaisons, {
        onSuccess: () => {
            notify('Déclinaison modifiée', 'success');
        },
        onError: () => {
            notify('Une erreur est survenue', 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries(['ligne-avoirs', ligneId]);
        },
    });
}
