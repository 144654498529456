import { useState, useEffect } from "react";

// Debounce function
function debounce(func, wait, immediate) {
    let timeout;
    return (...args) => {
        const context = this;
        const later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export default function useWindowSize() {
    const [height, setHeight] = useState(window.innerHeight);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Debounce to avoid the function fire multiple times
        const handleResizeDebounced = debounce(() => {
            setHeight(window.innerHeight);
            setWidth(window.innerWidth);
        }, 250);

        window.addEventListener("resize", handleResizeDebounced);
        return () => window.removeEventListener("resize", handleResizeDebounced);
    }, []);

    // Set --vh css variable
    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        if (vh < 6) vh = 6;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, [height]);

    return { height, width };
}
