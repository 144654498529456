import apiClient from '../apiClient';

const list = async (params) => {
    const response = await apiClient.get(`/liste_produit/`, { params });
    return response.data;
};

const item = async (id) => {
    const response = await apiClient.get(`/detail_produit/?produit_id=${id}`);
    return response.data;
};

const productsActions = { list, item };

export default productsActions;
