/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, {
    useRef,
    useState
} from 'react';

import { useForm } from 'react-hook-form';
import {
    Cell,
    Column,
    HeaderCell,
    Table
} from 'rsuite-table';

import {
    useUpdateLigneCommandeFournisseur,
    useValidateLigneCommandeFournisseur
} from '../../api/hooks/ligneCommandesFournisseur.hooks';
import PlusIcon from '../../assets/icons/PlusIcon';
// Hooks
import useModal from '../../hooks/useModal';
// Utils
import { getTableHeight } from '../../utils/utils';
import Button from '../Button/Button';
// Icons
import ButtonIcon from '../Button/ButtonIcon';
import ButtonSecondary from '../Button/ButtonSecondary';
import FormQuantity from '../Form/FormQuantity';
import Loader from '../Loader/Loader';
import LoaderSpinner from '../Loader/LoaderSpinner';
import Modal from '../Modal/Modal';
import ProductDetails from '../ProductDetails/ProductDetails';
// Components
import ImageCell from './ImageCell';
import NameCell from './NameCell';
import QuantityCell from './QuantityCell';

function TableFournisseurDetails({ handleSortColumn, sortData, sortColumn, loading, commandeId }) {
    const [firstLaunch, setFirstLaunch] = useState(true);
    const tableRef = useRef();
    const [productId, setProductId] = useState(null);
    const [isShowing, toggle] = useModal();

    const openDetails = (id) => {
        setProductId(id);
        toggle();
    };
    return (
        <>
            <Table
                ref={tableRef}
                height={getTableHeight(238)}
                renderLoading={LoaderSpinner}
                className='table'
                data={sortData()}
                rowHeight={100}
                sortColumn={sortColumn}
                onSortColumn={handleSortColumn}
                defaultSortType='asc'
                locale={{ emptyMessage: "Aucun produit n'a été trouvé", loading: 'Chargement' }}
                loading={firstLaunch && loading}
                onDataUpdated={() => !loading && setFirstLaunch(false)}
            >
                <Column>
                    <HeaderCell />
                    <ImageCell dataKey='vignette' openDetails={openDetails} clickable={false} />
                </Column>

                <Column flexGrow={1} minWidth={300} sortable>
                    <HeaderCell>Produit</HeaderCell>
                    <NameCell dataKey='nom' />
                </Column>

                <Column width={150} sortable>
                    <HeaderCell>Zone</HeaderCell>
                    <Cell dataKey='zone_de_stockage' />
                </Column>

                <Column width={140}>
                    <HeaderCell>Quantité</HeaderCell>
                    <Cell>
                        {({ qte, qte_livrer }) => (
                            <QuantityCell quantity={parseInt(qte_livrer, 10)} total={parseInt(qte, 10)} />
                        )}
                    </Cell>
                </Column>
                <Column width={90}>
                    <HeaderCell>cond.</HeaderCell>
                    <Cell>
                        {({ conditionnement }) => (
                            <div className='flex center'>
                                <p>{conditionnement}</p>
                            </div>
                        )}
                    </Cell>
                </Column>

                <Column fixed='right' width={78}>
                    <HeaderCell />
                    <Cell>
                        {({ id, qte_livrer, conditionnement, qte }) => (
                            <div className='flex right w-100'>
                                <HandleLine
                                    tableRef={tableRef}
                                    id={id}
                                    conditionnement={conditionnement}
                                    quantity={parseInt(qte_livrer, 10)}
                                    max={parseInt(qte, 10)}
                                    commandeId={commandeId}
                                    loadingData={loading}
                                />
                            </div>
                        )}
                    </Cell>
                </Column>
            </Table>

            {isShowing && productId && (
                <Modal isShowing={isShowing} hide={toggle} right>
                    <ProductDetails productId={productId} />
                </Modal>
            )}
        </>
    );
}

export default TableFournisseurDetails;

const HandleLine = ({ tableRef, id, conditionnement, quantity, max, disabled, commandeId, loadingData }) => {
    const [isShowing, toggle] = useModal();

    const { isLoading, mutate: editLine } = useUpdateLigneCommandeFournisseur(commandeId);
    const { isLoading: loadingValidation, mutate: validateLine } = useValidateLigneCommandeFournisseur(commandeId);

    const { register, handleSubmit, setValue } = useForm();

    async function onSubmit(formData) {
        const currentScroll = Math.abs(tableRef.current.scrollY);
        const data = {
            ligne_commande_fournisseur_id: id,
            ...formData,
        };
        editLine(data, {
            onSuccess: () => {
                validateLine(data, {
                    onSettled: () => {
                        setTimeout(() => {
                            tableRef.current.scrollTop(currentScroll);
                            toggle();
                        }, 200);
                    },
                });
            },
        });
    }

    return (
        <>
            <ButtonIcon onClick={toggle} icon={<PlusIcon />} />
            {isShowing && (
                <Modal isShowing={isShowing} hide={toggle}>
                    <form onSubmit={handleSubmit(onSubmit)} className='form' noValidate>
                        <div className='flex column center'>
                            <p className='label'>Quantité reçue</p>
                            <FormQuantity
                                value={quantity || max}
                                name='qte'
                                register={register}
                                setValue={setValue}
                                step={parseInt(conditionnement || 1, 10)}
                                max={max}
                                disabled={disabled}
                                editable
                            />
                        </div>
                        <div className='flex column center'>
                            <Button disabled={isLoading || loadingValidation || loadingData}>
                                Valider
                                {(isLoading || loadingValidation || loadingData) && <Loader />}
                            </Button>
                            <ButtonSecondary onClick={toggle}>
                                <p className='mt-2'>Annuler</p>
                            </ButtonSecondary>
                        </div>
                    </form>
                </Modal>
            )}
        </>
    );
};
