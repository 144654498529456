/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router';

// Components
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import Modal from '../../components/Modal/Modal';
import ModalHeader from '../../components/Modal/ModalHeader';
import NewClientForm from '../Clients/NewClientForm';
import FormSelect from '../../components/Form/FormSelect';
import LoaderSpinner from '../../components/Loader/LoaderSpinner';
import Error from '../../components/Error/Error';
import FormTextarea from '../../components/Form/FormTextarea';
import FormDate from '../../components/Form/FormDate';
import FormNumber from '../../components/Form/FormNumber';

// Hooks
import useModal from '../../hooks/useModal';

// api
import { useGetClientDetailsReglement, useGetClients } from '../../api/hooks/clients.hooks';
import { useGetAdresses } from '../../api/hooks/adresses.hook';
import { useCreateDevis } from '../../api/hooks/devis.hooks';
import FormSelectSearch from '../../components/Form/FormSelectSearch';

const MODES_REGLEMENT = [
    {
        label: 'Chèque',
        value: 'cheque',
    },
    {
        label: 'Virement',
        value: 'virement',
    },
    {
        label: 'LCR',
        value: 'lcr',
    },
];
const SAISONS = [];
for (let index = new Date().getFullYear(); index < new Date().getFullYear() + 5; index += 1) {
    SAISONS.push({
        label: `${index}`,
        value: `${index}`,
    });
}
export default function NewDevisForm({ openModalDevis }) {
    const history = useHistory();

    const { data: clients, isLoading, isError } = useGetClients();
    const { isLoading: loadingDevis, isError: errorDevis, mutate: createDevis } = useCreateDevis();

    const [modalIsShowing, toggleModal] = useModal();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (clients) {
            const list = [];
            clients.map(({ id, nom }) =>
                list.push({
                    value: id,
                    label: nom,
                })
            );
            setOptions(list);
        }
    }, [clients]);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        control,
    } = useForm();

    const onSubmit = async (formData) => {
        createDevis(formData, {
            onSuccess: (response) => history.push(`/devis/${response.data.id}`),
        });
    };

    const selectedClient = useWatch({ control, name: 'entite_id' });

    if (isLoading) {
        return (
            <div className='product-details-loader'>
                <LoaderSpinner />
            </div>
        );
    }
    if (isError) {
        return (
            <div className='product-details-loader'>
                <Error message='Erreur lors de la récupération des détails du client' />
            </div>
        );
    }

    return (
        <>
            <div className='p-4'>
                <form onSubmit={handleSubmit(onSubmit)} className='form'>
                    <div>
                        <FormSelectSearch
                            control={control}
                            label='Client'
                            placeholder='-- Choisissez un client --'
                            name='entite_id'
                            register={register}
                            errors={errors}
                            options={options}
                        />

                        <div className='flex right'>
                            <button type='button' className='text-link' onClick={openModalDevis}>
                                + Ajouter un nouveau client
                            </button>
                        </div>
                    </div>

                    {selectedClient && (
                        <FormStep2
                            selectedClient={parseInt(selectedClient, 10)}
                            errors={errors}
                            control={control}
                            setValue={setValue}
                            register={register}
                        />
                    )}

                    <div className='flex right'>
                        <Button disabled={loadingDevis}>
                            Créer le devis
                            {loadingDevis && <Loader />}
                        </Button>
                    </div>

                    {errorDevis &&
                        (errorDevis.response.data ? (
                            <p className='text-error'>{errorDevis.response.data.message}</p>
                        ) : (
                            <p className='text-error'>Une erreur est survenue</p>
                        ))}
                </form>
            </div>

            {modalIsShowing && (
                <Modal isShowing={modalIsShowing} hide={toggleModal} right>
                    <ModalHeader>Nouveau Client</ModalHeader>
                    <NewClientForm />
                </Modal>
            )}
        </>
    );
}

NewDevisForm.propTypes = {
    openModalDevis: PropTypes.func.isRequired,
};

const FormStep2 = ({ selectedClient, errors, register, control, setValue }) => {
    const { data: adresses, isLoading, isError } = useGetAdresses({ entite_id: selectedClient });
    const {
        data: detailsReglement,
        isLoadingDetails,
        isErrorDetails,
    } = useGetClientDetailsReglement({ entite_id: selectedClient });

    useEffect(() => {
        if (detailsReglement) {
            setValue('devis_mode_reglement', detailsReglement.mode);
            setValue('devis_delais_reglement', detailsReglement.delais);
        }
    }, [detailsReglement]);

    if (isLoading || isLoadingDetails) return <p>Chargement ...</p>;
    if (isError) return <p className='text-error'>Erreur lors de la récupération des adresses</p>;
    if (isErrorDetails) return <p className='text-error'>Erreur lors de la récupération des details de règlement</p>;

    return (
        <>
            <FormSelect
                label='Adresse de livraison'
                placeholder={
                    adresses.length === 0 ? 'Aucune adresse disponible' : '-- Choisissez une adresse de livraison --'
                }
                name='adresse_id'
                register={register}
                errors={errors}
                options={adresses}
                disabled={adresses.length === 0}
            />
            <FormDate label='Date' name='date_livraison' errors={errors} control={control} setValue={setValue} />
            <FormSelect
                label='Saison'
                placeholder='-- Choisissez une année --'
                name='devis_saison'
                register={register}
                errors={errors}
                options={SAISONS}
            />
            <FormNumber label='Remise par défaut (%)' name='devis_remise' errors={errors} register={register} />
            <FormSelect
                label='Mode de règlement'
                placeholder='-- Choisissez un mode de règlement --'
                name='devis_mode_reglement'
                register={register}
                errors={errors}
                options={MODES_REGLEMENT}
            />
            <FormNumber
                label='Délai de règlement (jours)'
                name='devis_delais_reglement'
                errors={errors}
                register={register}
            />
            <FormTextarea
                label='Commentaire (facultatif)'
                name='devis_detail'
                errors={errors}
                register={register}
                rules={{ required: false }}
            />
        </>
    );
};

FormStep2.propTypes = {
    selectedClient: PropTypes.number.isRequired,
};
