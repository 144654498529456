/* eslint-disable react/prop-types */

import React, { createContext, useContext, useState } from 'react';

// create context
const AvoirsDetailsFiltersContext = createContext();

const AvoirsDetailsFiltersContextProvider = ({ children }) => {
    const [avoirsDetailsFilters, setAvoirsDetailsFilters] = useState(null);

    return (
        <AvoirsDetailsFiltersContext.Provider value={{ avoirsDetailsFilters, setAvoirsDetailsFilters }}>
            {children}
        </AvoirsDetailsFiltersContext.Provider>
    );
};

const useAvoirsDetailsFiltersContext = () => {
    const context = useContext(AvoirsDetailsFiltersContext);

    if (context === undefined) {
        throw new Error('useAvoirsDetailsFiltersContext est utilisé en dehors de son provider');
    }

    return context;
};

export { AvoirsDetailsFiltersContextProvider, useAvoirsDetailsFiltersContext };
