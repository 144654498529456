import React, { useEffect, useLayoutEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

// Components
import Error from '../../components/Error/Error';
import Filters from '../../components/Filters/Filters';
import FormSearch from '../../components/Form/FormSearch';
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import TableLivraisons from '../../components/Table/TableLivraisons';

// hooks
import { useGetBonsLivraisons } from '../../api/hooks/bonLivraisons.hooks';

// contaxts
import { useBonsLivraisonFiltersContext } from '../../contexts/filters/bonsLivraisonFiltersContext';
import useDebounce from '../../hooks/useDebounce';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { filterObjectsByValue } from '../../utils/object';
import useScrollPosition from '../../hooks/useScrollPosition';

function BonsLivraison() {
    const { bonsLivraisonFilters, setBonsLivraisonFilters } = useBonsLivraisonFiltersContext();
    const { data: bons, isLoading, isError } = useGetBonsLivraisons();
    const [filteredData, setFilteredData] = useState([]);

    const methods = useForm({
        defaultValues: bonsLivraisonFilters,
    });

    const searchInput = useWatch({ control: methods.control, name: 'search-input' });
    const debouncedSearchInput = useDebounce(searchInput, 500);
    useDidMountEffect(() => {
        setBonsLivraisonFilters((prevState) => ({
            ...prevState,
            'search-input': debouncedSearchInput,
        }));
    }, [debouncedSearchInput]);

    useEffect(() => {
        if (!bonsLivraisonFilters || bonsLivraisonFilters?.['search-input'] === '') {
            return setFilteredData(bons);
        }
        const newData = filterObjectsByValue(bons, debouncedSearchInput, [
            'client',
            'numero',
            'commande',
        ]);
        return setFilteredData(newData);
    }, [bons, bonsLivraisonFilters]);

    // Scroll Restoration
    const { scrollPosition } = useScrollPosition();
    useEffect(() => {
        if (bonsLivraisonFilters?.pageYOffset) {
            setTimeout(() => {
                window.scrollTo(0, bonsLivraisonFilters.pageYOffset);
            }, 2);
        }
    }, [window]);
    useLayoutEffect(() => {
        if (scrollPosition !== null) {
            setBonsLivraisonFilters((prev) => ({ ...prev, pageYOffset: scrollPosition }));
        }
    }, [scrollPosition]);

    if (isError) return <Error message="Erreur lors de la récupération des bons de livraison" />;

    return (
        <>
            <Header>
                <h1 className="text-lg">Bons de livraison</h1>
            </Header>
            <Filters>
                <FormProvider {...methods}>
                    <FormSearch placeholder="Nom, Référence, ..." name="search-input" />
                </FormProvider>
            </Filters>

            <section className="section">
                <Table data={filteredData}>
                    <TableLivraisons loading={isLoading} />
                </Table>
            </section>
        </>
    );
}

export default BonsLivraison;
