/* eslint-disable react/prop-types */

import React, { createContext, useContext, useState } from 'react';

// create context
const FournisseursFiltersContext = createContext();

const FournisseursFiltersContextProvider = ({ children }) => {
    const [fournisseursFilters, setFournisseursFilters] = useState(null);

    return (
        <FournisseursFiltersContext.Provider
            value={{ fournisseursFilters, setFournisseursFilters }}
        >
            {children}
        </FournisseursFiltersContext.Provider>
    );
};

const useFournisseursFiltersContext = () => {
    const context = useContext(FournisseursFiltersContext);

    if (context === undefined) {
        throw new Error('useFournisseursFiltersContext est utilisé en dehors de son provider');
    }

    return context;
};

export { FournisseursFiltersContextProvider, useFournisseursFiltersContext };
