import * as React from "react";

function AlertIcon() {
    return (
        <svg width="1em" height="1em" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37 0C16.566 0 0 16.566 0 37s16.566 37 37 37 37-16.566 37-37S57.434 0 37 0zm3.364 20.182a3.363 3.363 0 10-6.728 0v20.182a3.364 3.364 0 106.728 0V20.182zm0 31.954a3.363 3.363 0 10-6.728 0v1.682a3.363 3.363 0 106.728 0v-1.682z"
                fill="#D94343"
            />
        </svg>
    );
}

export default AlertIcon;
