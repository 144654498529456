import { useMutation, useQuery, useQueryClient } from 'react-query';
import { notify } from '../../utils/utils';
import devisActions from '../actions/devis.actions';
import ligneDevisActions from '../actions/ligneDevis.actions';

export function useGetLigneDevis(id, options) {
    return useQuery(['ligne-devis', id], () => ligneDevisActions.item(id), { ...options });
}

export function useUpdateLigneDevis(devisId) {
    const queryClient = useQueryClient();
    return useMutation((ligneDevis) => ligneDevisActions.update(ligneDevis), {
        onMutate: async (newLigne) => {
            await queryClient.cancelQueries(['devis', devisId]);

            const prevDevis = queryClient.getQueryData(['devis', devisId]);
            const updatedLignes = prevDevis.ligne_de_commande.map((ligne) =>
                ligne.ligne_id === newLigne.ligne_devis_id ? { ...ligne, qte: newLigne.qte } : ligne
            );

            queryClient.setQueryData(['devis', devisId], {
                ...prevDevis,
                ligne_de_commande: updatedLignes,
            });
            return { prevDevis };
        },
        onSuccess: () => {
            notify('Quantité modifiée', 'success');
        },
        onError: (_err, _variables, context) => {
            notify('Une erreur est survenue', 'error');
            queryClient.setQueryData(['devis', devisId], context.prevDevis);
        },
        onSettled: () => {
            queryClient.invalidateQueries(['devis', devisId], { exact: true });
            queryClient.invalidateQueries('devis', { exact: true });
        },
    });
}

export function useDeleteLigneDevis(devisId) {
    const queryClient = useQueryClient();
    return useMutation((ligneId) => ligneDevisActions.remove(ligneId), {
        onMutate: async (ligneId) => {
            await queryClient.cancelQueries(['devis', devisId]);

            const prevDevis = queryClient.getQueryData(['devis', devisId]);
            const removeDeleted = prevDevis.ligne_de_commande.filter((ligne) => ligne.ligne_id !== ligneId);
            queryClient.setQueryData(['devis', devisId], {
                ...prevDevis,
                ligne_de_commande: removeDeleted,
            });
            return { prevDevis };
        },
        onSuccess: () => {
            notify('Ligne supprimée', 'success');
        },
        onError: (_err, _variables, context) => {
            notify('Une erreur est survenue', 'error');
            queryClient.setQueryData(['devis', devisId], context.prevDevis);
        },
    });
}

export function useUpdateDeclinaisonsLigneDevis(ligneId) {
    const queryClient = useQueryClient();
    return useMutation(ligneDevisActions.updateDeclinaisons, {
        onSuccess: () => {
            notify('Déclinaison modifiée', 'success');
        },
        onError: () => {
            notify('Une erreur est survenue', 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries(['ligne-devis', ligneId]);
        },
    });
}
