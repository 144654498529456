/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import SignaturePad from "react-signature-canvas";

function SignCanvas({ field }) {
    const sigCanvas = useRef({});

    const formatIntoPng = () => {
        if (sigCanvas.current) {
            const dataURL = sigCanvas.current.toDataURL();
            return dataURL;
        }
        return null;
    };

    return <SignaturePad ref={sigCanvas} onEnd={() => field.onChange(formatIntoPng())} canvasProps={{ className: "sign-canvas" }} />;
}

export default SignCanvas;
