/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Cell } from 'rsuite-table';

// Utils
import { dateToString } from '../../utils/utils';

const DateCell = ({ rowData, dataKey, ...props }) => (
    <Cell {...props}>
        <p>{dateToString(rowData[dataKey])}</p>
    </Cell>
);

export default DateCell;
