import * as React from "react";

function BoxIcon() {
    return (
        <svg width="1em" height="1em" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.903 2.235l8.5 3.748L9 7.483.549 3.756c.19-.2.423-.36.688-.465l2.666-1.057v.002zm1.72-.682L7.618.764a3.755 3.755 0 012.768 0l6.38 2.526c.263.105.496.264.686.465l-3.415 1.507-8.412-3.709zm12.37 3.404L9.642 8.64v9.86a3.76 3.76 0 00.742-.21l6.38-2.526c.363-.144.676-.398.896-.729.221-.33.339-.722.339-1.123v-8.77c0-.062-.003-.124-.009-.185h.001zM8.356 18.5V8.64L.01 4.957A2.05 2.05 0 000 5.142v8.77c0 .4.118.793.34 1.124.22.33.533.585.897.728l6.378 2.527c.242.095.49.165.742.209z"
                fill="#fff"
            />
        </svg>
    );
}

export default BoxIcon;
