import * as React from 'react';

function AvoirIcon() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            x={0}
            y={0}
            viewBox='0 0 79.98 79.95'
            xmlSpace='preserve'
            width='1.5em'
            height='1.5em'
        >
            <path
                fill='currentColor'
                d='M62.72 17.9c-3.44.13-6.67.29-6.55 4.23.12 3.89 3.25 3.93 6.22 3.92 15.2-.05 13.6 1.4 13.67-13.59.01-2.76.09-5.62-3.35-6.29-3.4-.66-4.29 2.08-5.09 4.65C47.04-6.5 23.82-.71 11.71 12.05.45 23.93-2.95 38.02 2.65 53.46 8.31 69.07 20.09 77.6 36.48 79.81c1.15.15 2.33.17 3.49.1 2.52-.15 4.1-1.48 4.06-4.06-.03-2.32-1.53-3.56-3.79-3.86-2.63-.35-5.31-.46-7.88-1.05-12.97-3-22.86-14.52-24.12-27.89-1.27-13.45 6.12-26.3 18.4-32 12.29-5.72 26.5-3.15 36.08 6.85z'
            />
            <path
                fill='currentColor'
                d='M75.65 44.08c2.69-.2 4.2-1.44 4.33-3.72.15-2.53-1.36-4.16-3.88-4.22-2.25-.05-3.81 1.35-3.97 3.65-.19 2.61 1.48 3.83 3.52 4.29z'
            />
            <path
                fill='currentColor'
                d='M53.58 69.2c-2.43.59-3.89 1.98-3.59 4.56.28 2.35 1.79 3.44 4.13 3.31 2.41-.14 3.69-1.72 3.66-3.88-.04-2.48-1.79-3.74-4.2-3.99z'
            />
            <path
                fill='currentColor'
                d='M69.25 53.5c.32 2.47 1.48 4.27 4.04 4.26 2.19-.01 3.76-1.29 3.85-3.68.09-2.29-1.02-3.84-3.36-4.12-2.64-.32-3.89 1.3-4.53 3.54z'
            />
            <path
                fill='currentColor'
                d='M65.45 61.62c-2.72.22-3.97 1.79-3.81 4.24.15 2.29 1.7 3.55 3.97 3.45 2.56-.11 3.82-1.67 3.65-4.23-.16-2.39-1.65-3.39-3.81-3.46z'
            />
            <path
                fill='currentColor'
                d='M42.37 57.94c-3.9 0-7.36-1.11-10.4-3.32s-5.18-5.07-6.44-8.57h-4.95c-.56 0-1.03-.19-1.41-.57-.38-.38-.57-.85-.57-1.41s.19-1.03.57-1.41c.38-.38.85-.57 1.41-.57h4.06c-.07-.36-.1-.69-.1-.99v-1.98c0-.3.03-.63.1-.99h-4.06c-.56 0-1.03-.19-1.41-.57-.38-.38-.57-.85-.57-1.41 0-.56.19-1.03.57-1.41.38-.38.85-.57 1.41-.57h4.95c1.25-3.5 3.4-6.35 6.44-8.57s6.5-3.32 10.4-3.32c1.82 0 3.54.25 5.18.74 1.63.5 3.16 1.2 4.58 2.13.59.4.92.96.97 1.68.05.73-.21 1.37-.77 1.93-.4.4-.9.63-1.51.69-.61.07-1.2-.07-1.76-.4-.99-.56-2.05-1.01-3.17-1.34-1.12-.33-2.29-.5-3.52-.5-2.48 0-4.73.64-6.76 1.91-2.03 1.27-3.57 2.95-4.63 5.02h9.41c.56 0 1.03.19 1.41.57.38.38.57.85.57 1.41 0 .56-.19 1.03-.57 1.41-.38.38-.85.57-1.41.57H29.7a9.916 9.916 0 0 0 0 3.96h10.7c.56 0 1.03.19 1.41.57.38.38.57.85.57 1.41s-.19 1.03-.57 1.41c-.38.38-.85.57-1.41.57h-9.41c1.06 2.08 2.6 3.76 4.63 5.03 2.03 1.27 4.28 1.91 6.76 1.91 1.19 0 2.36-.17 3.52-.5 1.16-.33 2.21-.78 3.17-1.34.56-.33 1.14-.47 1.73-.42.59.05 1.11.29 1.53.72.56.56.82 1.2.77 1.93-.05.73-.37 1.29-.97 1.68-1.42.92-2.95 1.63-4.58 2.13-1.63.53-3.36.78-5.18.78z'
            />
        </svg>
    );
}

export default AvoirIcon;
