/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';
import { useHistory } from 'react-router';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';

// Components
import LoaderSpinner from '../Loader/LoaderSpinner';
import Modal from '../Modal/Modal';
import ModalHeader from '../Modal/ModalHeader';
import NewClientForm from '../../pages/Clients/NewClientForm';
import Error from '../Error/Error';

// Icons
import DevisIcon from '../../assets/icons/DevisIcon';
import ButtonIcon from '../Button/ButtonIcon';
import EditIcon from '../../assets/icons/EditIcon';

// Hooks
import useModal from '../../hooks/useModal';
import { useGetClient } from '../../api/hooks/clients.hooks';

function TableClients({ handleSortColumn, sortData, sortColumn, loading }) {
    const history = useHistory();

    return (
        <Table
            autoHeight
            affixHeader={110}
            renderLoading={LoaderSpinner}
            className="table"
            data={sortData()}
            rowHeight={72}
            sortColumn={sortColumn}
            onSortColumn={handleSortColumn}
            defaultSortType="asc"
            loading={loading}
            locale={{ emptyMessage: "Aucun client n'a été trouvé", loading: 'Chargement' }}
        >
            <Column flexGrow={1} minWidth={300} sortable>
                <HeaderCell>Nom</HeaderCell>
                <Cell dataKey="nom" />
            </Column>

            <Column flexGrow={1} minWidth={300}>
                <HeaderCell>Email</HeaderCell>
                <Cell dataKey="email" />
            </Column>

            <Column flexGrow={1} minWidth={175}>
                <HeaderCell>Téléphone</HeaderCell>
                <Cell dataKey="tel" />
            </Column>

            <Column fixed="right" width={136}>
                <HeaderCell />

                <Cell>
                    {({ id }) => (
                        <>
                            <ButtonIcon
                                onClick={() => history.push(`clients/${id}`)}
                                icon={<DevisIcon />}
                            />
                            <EditClient id={id} />
                        </>
                    )}
                </Cell>
            </Column>
        </Table>
    );
}

export default TableClients;

const EditClient = ({ id }) => {
    const [isShowing, toggle] = useModal();

    const { data, isLoading, isError, refetch: getDetails } = useGetClient(id, { enabled: false });

    const handleOnClick = () => {
        getDetails().then(toggle);
    };

    return (
        <>
            <ButtonIcon onClick={handleOnClick} icon={<EditIcon />} />
            {isShowing && (
                <Modal isShowing={isShowing} hide={toggle} right>
                    {isLoading && (
                        <div className="product-details-loader">
                            <LoaderSpinner />
                        </div>
                    )}
                    {isError && (
                        <div className="product-details-loader">
                            <Error message="Erreur lors de la récupération des détails du client" />
                        </div>
                    )}
                    {!isLoading && !isError && (
                        <>
                            <ModalHeader>{data?.nom}</ModalHeader>
                            <NewClientForm data={data} />
                        </>
                    )}
                </Modal>
            )}
        </>
    );
};
