/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';

// Components
import ImageCell from './ImageCell';
import NameCell from './NameCell';
import Modal from '../Modal/Modal';
import ProductDetails from '../ProductDetails/ProductDetails';
import LoaderSpinner from '../Loader/LoaderSpinner';

// Icons
import ButtonIcon from '../Button/ButtonIcon';
import EyeIcon from '../../assets/icons/EyeIcon';

// Hooks
import useModal from '../../hooks/useModal';

// Utils
import { getTableHeight, numberToEUR } from '../../utils/utils';

function TableCatalog({ handleSortColumn, sortData, sortColumn, loading }) {
    const [productId, setProductId] = useState(null);
    const [isShowing, toggle] = useModal();

    const openDetails = (id) => {
        setProductId(id);
        toggle();
    };

    return (
        <>
            <Table
                height={getTableHeight(238)}
                renderLoading={LoaderSpinner}
                className='table'
                data={sortData()}
                rowHeight={100}
                sortColumn={sortColumn}
                onSortColumn={handleSortColumn}
                defaultSortType='asc'
                loading={loading}
                locale={{ emptyMessage: "Aucun produit n'a été trouvé", loading: 'Chargement' }}
            >
                <Column>
                    <HeaderCell />
                    <ImageCell dataKey='vignette' openDetails={openDetails} />
                </Column>

                <Column flexGrow={1} minWidth={300} sortable>
                    <HeaderCell>Produit</HeaderCell>
                    <NameCell dataKey='nom' />
                </Column>

                <Column width={160}>
                    <HeaderCell>Conditionnement</HeaderCell>
                    <Cell>{({ conditionnement }) => <p>{conditionnement}</p>}</Cell>
                </Column>

                <Column width={110}>
                    <HeaderCell>Prix U. HT</HeaderCell>
                    <Cell dataKey='prix'>{({ prix }) => <p>{numberToEUR(prix)}</p>}</Cell>
                </Column>

                <Column align='right'>
                    <HeaderCell>
                        <p>Stock</p>
                    </HeaderCell>
                    <Cell>{({ stock }) => <p>{stock}</p>}</Cell>
                </Column>

                {/* <Column sortable align="right" width={120}>
                    <HeaderCell>
                        <p>Prix HT</p>
                    </HeaderCell>
                    <Cell dataKey="prix">{({ prix }) => <p>{numberToEUR(prix)}</p>}</Cell>
                </Column> */}

                <Column fixed='right' width={88}>
                    <HeaderCell />
                    <Cell>
                        {({ id }) => <ButtonIcon onClick={() => openDetails(parseInt(id, 10))} icon={<EyeIcon />} />}
                    </Cell>
                </Column>
            </Table>

            {isShowing && productId && (
                <Modal isShowing={isShowing} hide={toggle} right>
                    <ProductDetails productId={productId} />
                </Modal>
            )}
        </>
    );
}

export default TableCatalog;
