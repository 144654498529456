/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import './main.scss';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

// Notifications
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
import Nav from './components/Nav/Nav';

// Pages
import Login from './pages/Login/Login';
import Catalog from './pages/Catalog/Catalog';
import Clients from './pages/Clients/Clients';
import ClientDetails from './pages/Clients/ClientDetails';
import Devis from './pages/Devis/Devis';
import DevisDetails from './pages/Devis/DevisDetails';
import DevisAddProduct from './pages/Devis/DevisAddProduct';
import Commandes from './pages/Commandes/Commandes';
import CommandeDetails from './pages/Commandes/CommandeDetails';
import BonsLivraison from './pages/BonsLivraison/BonsLivraison';
import Fournisseurs from './pages/Fournisseurs/Fournisseurs';
import Avoirs from './pages/Avoirs/Avoirs';
import AvoirDetails from './pages/Avoirs/AvoirDetails';
import AvoirAddProduct from './pages/Avoirs/AvoirAddProduct';

// Hooks
import useWindowSize from './hooks/useWindowSize';
import FournisseurDetails from './pages/Fournisseurs/FournisseurDetails';
import FiltersContexts from './contexts/filters/filtersContexts';

// auth
import AuthService from './api/auth/auth';
import AuthVerify from './api/auth/AuthVerify';

function App() {
    useWindowSize();

    // Authentification
    const [currentUser, setCurrentUser] = useState(null);
    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
        }
    }, []);
    const logout = () => {
        AuthService.logout();
    };

    return (
        <FiltersContexts>
            <Router>
                {!currentUser ? (
                    <Login />
                ) : (
                    <div className='flex'>
                        <Nav />
                        <main className='main'>
                            <Switch>
                                <Route path='/clients' exact component={Clients} />
                                <Route path='/clients/:id' exact component={ClientDetails} />
                                <Route path='/devis' exact component={Devis} />
                                <Route path='/devis/:devisId' exact component={DevisDetails} />
                                <Route path='/devis/:devisId/add' exact component={DevisAddProduct} />
                                <Route path='/commandes' exact component={Commandes} />
                                <Route path='/commandes/:id' exact component={CommandeDetails} />
                                <Route path='/livraisons' exact component={BonsLivraison} />
                                <Route path='/fournisseurs' exact component={Fournisseurs} />
                                <Route path='/fournisseurs/:id' exact component={FournisseurDetails} />
                                <Route path='/avoirs' exact component={Avoirs} />
                                <Route path='/avoirs/:avoirId' exact component={AvoirDetails} />
                                <Route path='/avoirs/:avoirId/add' exact component={AvoirAddProduct} />
                                <Route index component={Catalog} />
                            </Switch>
                        </main>
                        <AuthVerify logout={logout} />
                        <ToastContainer transition={Slide} />
                    </div>
                )}
            </Router>
        </FiltersContexts>
    );
}

export default App;
