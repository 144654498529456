import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

// Hooks
import useModal from '../../../hooks/useModal';

// Icons
import ButtonIcon from '../../Button/ButtonIcon';
import CommentIcon from '../../../assets/icons/CommentIcon';

// Components
import Modal from '../../Modal/Modal';
import ModalHeader from '../../Modal/ModalHeader';
import ModalFooter from '../../Modal/ModalFooter';
import FormTextarea from '../../Form/FormTextarea';
import Button from '../../Button/Button';
import Loader from '../../Loader/Loader';
import { useCreateCommentaire } from '../../../api/hooks/commentaires.hooks.js';

const Comments = ({ comments, id }) => {
    const [isShowing, toggle] = useModal();

    return (
        <>
            <ButtonIcon onClick={toggle} icon={<CommentIcon />} status={comments.length > 0 ? 'error' : ''} />
            {isShowing && (
                <Modal isShowing={isShowing} hide={toggle} right>
                    <ModalHeader>Commentaires</ModalHeader>
                    <div className='p-5 flex-1'>
                        {comments.length === 0 && <p>Aucun commentaire</p>}

                        {comments.map(({ date, utilisateur, commentaire }, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div className='pb-5' key={index}>
                                <div className='flex center left'>
                                    <p className='text-bold'>{utilisateur}</p>
                                    <span className='mx-2'>-</span>
                                    <p> {date}</p>
                                </div>
                                {/* eslint-disable-next-line react/no-danger */}
                                <div dangerouslySetInnerHTML={{ __html: commentaire }} />
                            </div>
                        ))}
                    </div>
                    <ModalFooter>
                        <NewCommentForm id={id} />
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default Comments;

Comments.propTypes = {
    comments: PropTypes.arrayOf(
        PropTypes.shape({
            date: PropTypes.string,
            utilisateur: PropTypes.string,
            commentaire: PropTypes.string,
        })
    ),
    id: PropTypes.number.isRequired,
};

Comments.defaultProps = {
    comments: [],
};

const NewCommentForm = ({ id }) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm();

    const { isLoading: loadingComment, mutate: createComment } = useCreateCommentaire();

    const onSubmit = async (formData) => {
        const data = { ...formData, devis_id: id };
        createComment(data, {
            onSuccess: () => reset(),
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='form'>
            <FormTextarea
                label='Commentaire (facultatif)'
                name='commentaire'
                errors={errors}
                register={register}
                rules={{ required: true }}
                rows={2}
            />
            <div className='flex right'>
                <Button disabled={loadingComment}>
                    Envoyer
                    {loadingComment && <Loader />}
                </Button>
            </div>
        </form>
    );
};

NewCommentForm.propTypes = {
    id: PropTypes.number.isRequired,
};
