import apiClient from '../apiClient';

async function update(ligneBonLivraison) {
    const response = await apiClient.post('/gestion_ligne_bon_livraison', ligneBonLivraison);
    return response.data;
}

async function rupture(ligneBonLivraison) {
    const response = await apiClient.post('/rupture', ligneBonLivraison);
    return response.data;
}

const ligneBonLivraisonsActions = { update, rupture };

export default ligneBonLivraisonsActions;
