import * as React from "react";

function CloseIcon() {
    return (
        <svg width="0.75em" height="0.75em" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.94 4l2.863-2.858a.669.669 0 00-.946-.946L4 3.06 1.143.196a.669.669 0 10-.946.946l2.864 2.857L.197 6.857A.666.666 0 00.414 7.95a.666.666 0 00.729-.146L4 4.939l2.857 2.864a.666.666 0 001.092-.217.666.666 0 00-.146-.729L4.939 4z"
                fill="#2E5599"
            />
        </svg>
    );
}

export default CloseIcon;
