import React from 'react';
import PropTypes from 'prop-types';

// Hooks
import useModal from '../../../hooks/useModal';

// Icons
import ButtonIcon from '../../Button/ButtonIcon';
import BoxIcon from '../../../assets/icons/BoxIcon';

// Components
import Modal from '../../Modal/Modal';
import TableDeclinaison from '../TableDeclinaison';
import Table from '../Table';
import { useGetLigneDevis } from '../../../api/hooks/ligneDevis.hooks';

const Declinaison = ({ id }) => {
    const [isShowing, toggle] = useModal();

    const {
        data: declinaisons,
        refetch: getDeclinaisons,
        isLoading,
    } = useGetLigneDevis(parseInt(id, 10), {
        enabled: false,
    });

    const openDeclinaisons = () => {
        getDeclinaisons().then(toggle);
    };

    return (
        <>
            <ButtonIcon onClick={() => openDeclinaisons()} icon={<BoxIcon />} />
            {isShowing && (
                <Modal isShowing={isShowing} hide={toggle}>
                    <section className="section">
                        <Table data={declinaisons}>
                            <TableDeclinaison loading={isLoading} />
                        </Table>
                    </section>
                </Modal>
            )}
        </>
    );
};

export default Declinaison;

Declinaison.propTypes = {
    id: PropTypes.number.isRequired,
};
