/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

function FormSelectSearch({ label, placeholder, name, errors, control, options, noOptionsMessage }) {
    return (
        <div className={`form__group ${errors[name] ? 'error' : ''}`}>
            <label htmlFor={name}>{label}</label>
            <Controller
                control={control}
                name={name}
                rules={{ required: true }}
                defaultValue={new Date()}
                render={({ field: { onChange } }) => (
                    <Select
                        styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                border: '1px solid var(--color-primary)',
                                borderRadius: 'var(--border-radius)',
                            }),
                        }}
                        placeholder={placeholder}
                        options={options}
                        onChange={(client) => onChange(client.value)}
                        noOptionsMessage={() => noOptionsMessage}
                    />
                )}
            />
            {errors[name]?.type === 'required' && <p className='text-error'>{label} requis</p>}
        </div>
    );
}

FormSelectSearch.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    errors: PropTypes.shape().isRequired,
    control: PropTypes.shape().isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })
    ).isRequired,
    noOptionsMessage: PropTypes.string,
};

FormSelectSearch.defaultProps = {
    noOptionsMessage: 'Aucun résultat',
};

export default FormSelectSearch;
