/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

function FormEmail({ label, placeholder, name, register, errors }) {
    return (
        <div className="form__group">
            <label htmlFor={name}>{label}</label>
            <input
                id={name}
                placeholder={placeholder}
                type="email"
                {...register(name, {
                    required: true,
                    pattern: /^[a-z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,8}$/,
                })}
                className={errors[name] ? 'error' : undefined}
            />
            {errors[name]?.type === 'pattern' && (
                <p className="text-error">Adresse email non valide</p>
            )}
            {errors[name]?.type === 'required' && <p className="text-error">{label} requis</p>}
        </div>
    );
}

FormEmail.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.shape().isRequired,
};

export default FormEmail;
