import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

// Components
import Button from '../../components/Button/Button';
import FormEmail from '../../components/Form/FormEmail';
import FormSelect from '../../components/Form/FormSelect';
import FormText from '../../components/Form/FormText';
import Loader from '../../components/Loader/Loader';

// api
import { useCreateClient } from '../../api/hooks/clients.hooks';
import FormNumber from '../../components/Form/FormNumber';

const MODES_REGLEMENT = [
    {
        label: 'Chèque',
        value: 'chèque',
    },
    {
        label: 'Virement',
        value: 'virement',
    },
    {
        label: 'LCR',
        value: 'lcr',
    },
];

export default function NewClientForm({ data }) {
    const history = useHistory();

    const { isLoading, mutate: submitClient } = useCreateClient(data?.id);

    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
    } = useForm({
        defaultValues: {
            entite_id: data?.id,
            entite_societe: data?.nom,
            entite_categorie: data?.categorie,
            entite_mail: data?.email,
            entite_tel1: data?.tel,
            entite_adresse: data?.adresse,
            entite_cp: data?.cp,
            entite_ville: data?.ville,
            entite_perso1: data?.entite_perso1,
            entite_perso2: data?.entite_perso2,
        },
    });

    async function onSubmit(formData) {
        submitClient(formData, {
            onSuccess: (response) => history.push(`/clients/${response.data.client.id}`),
        });
    }

    return (
        <div className="p-4">
            <form onSubmit={handleSubmit(onSubmit)} className="form" noValidate>
                <FormText
                    label="Société"
                    placeholder="Nom de la société, du client, ..."
                    name="entite_societe"
                    register={register}
                    errors={errors}
                />
                <FormSelect
                    label="Catégorie"
                    placeholder="-- Choisissez une catégorie --"
                    name="entite_categorie"
                    register={register}
                    errors={errors}
                    options={[
                        {
                            value: 'souvenir',
                            label: 'Souvenir',
                        },
                        {
                            value: 'gms',
                            label: 'Gms',
                        },
                    ]}
                    getValues={getValues}
                />
                <FormEmail
                    label="Email"
                    placeholder="exemple@email.fr"
                    name="entite_mail"
                    register={register}
                    errors={errors}
                />
                <FormText
                    label="Téléphone"
                    placeholder="06 00 00 00 00"
                    name="entite_tel1"
                    register={register}
                    errors={errors}
                    rules={{
                        required: true,
                        pattern:
                            /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/,
                    }}
                />
                <FormText
                    label="Adresse"
                    placeholder="rue"
                    name="entite_adresse"
                    register={register}
                    errors={errors}
                />
                <FormText
                    label="Code postal"
                    placeholder="20200"
                    name="entite_cp"
                    register={register}
                    errors={errors}
                    rules={{
                        required: true,
                        pattern: /^[0-9]{5}$/,
                    }}
                />
                <FormText
                    label="Ville"
                    placeholder="Bastia"
                    name="entite_ville"
                    register={register}
                    errors={errors}
                />
                <FormSelect
                    label="Mode de règlement par défaut"
                    placeholder="-- Choisissez un mode de règlement --"
                    name="entite_perso1"
                    register={register}
                    errors={errors}
                    options={MODES_REGLEMENT}
                />
                <FormNumber
                    label="Délai de règlement par défaut (jours)"
                    name="entite_perso2"
                    errors={errors}
                    register={register}
                />

                <div className="flex right">
                    <Button disabled={isLoading}>
                        Ajouter le client
                        {isLoading && <Loader />}
                    </Button>
                </div>
            </form>
        </div>
    );
}

NewClientForm.propTypes = {
    data: PropTypes.shape(),
};

NewClientForm.defaultProps = {
    data: {},
};
