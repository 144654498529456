import apiClient from '../apiClient';

const list = async (params) => {
    const response = await apiClient.get(`adresse_livraison/`, { params });
    return response.data;
};

const adressesActions = { list };

export default adressesActions;
