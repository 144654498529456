/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import AuthService from './auth/auth';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        'Accept-Language': 'fr',
        ...AuthService.authHeader(),
    },
});

export default apiClient;
