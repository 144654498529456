/* eslint-disable no-empty-pattern */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

// Components
import ImageCell from './ImageCell';
import NameCell from './NameCell';
import Modal from '../Modal/Modal';
import ReductionCell from './ReductionCell';
import ProductDetails from '../ProductDetails/ProductDetails';
import LoaderSpinner from '../Loader/LoaderSpinner';
import FormQuantity from '../Form/FormQuantity';
import TotalHtCell from './TotalHtCell';
import QuantityDetails from './modules/QuantityDetails';

// Hooks
import useModal from '../../hooks/useModal';

// Icons
import ButtonIcon from '../Button/ButtonIcon';
import CheckIcon from '../../assets/icons/CheckIcon';

// Utils
import { getTableHeight, notify, numberToEUR } from '../../utils/utils';

// api
import { useAddProductDevis, useCheckOrderProduct, useGetDevisDetails } from '../../api/hooks/devis.hooks';
import CheckOrderProduct from './modules/CheckOrderProduct';

function TableAddProducts({ handleSortColumn, sortData, sortColumn, loading }) {
    const { devisId } = useParams();
    const [isShowing, toggle] = useModal();
    const [isShowingDeclinaisons, toggleDeclinaisons] = useModal();
    const [productId, setProductId] = useState(null);
    const [declinaisons, setDeclinaisons] = useState(null);

    const [isShowinCheckModal, toggleCheckModal] = useModal();
    const [checkModalDetails, setCheckModalDetails] = useState()

    const { data: devis, isLoading: devisLoading } = useGetDevisDetails(parseInt(devisId, 10));

    const { isLoading: loadingAddProduct, mutate: addProduct } = useAddProductDevis(parseInt(devisId, 10));

    const checkOrderMutation = useCheckOrderProduct();

    const { register, setValue, getValues, control } = useForm();

    const openDetails = (id) => {
        setProductId(id);
        toggle();
    };

    const closeDeclinaisons = () => {
        setDeclinaisons(null);
    };
    const openDeclinaisons = (ligneId) => {
        setDeclinaisons(<QuantityDetails toggle={closeDeclinaisons} ligneId={ligneId} />);
        toggleDeclinaisons();
    };

    const handleCheckResponse = (response) => {
        if (!response || !response.length) return;
        setCheckModalDetails(response);
        toggleCheckModal();
    }

    const addNewProduct = (productId, data) => {
        if (!parseInt(data.qte, 10)) {
            return notify('Quantité incorrecte', 'error');
        }
        checkOrderMutation.mutateAsync({ produit_id: productId, devis_id: devisId }).then(response => handleCheckResponse(response))
        return addProduct(
            {
                devis_id: devisId,
                produit_id: productId,
                ...data,
            },
            {
                onSuccess: ({ data }) => {
                    if (data.qte_incorrecte) openDeclinaisons(parseInt(data.id, 10));
                    setValue(productId, { qte: 0, remise: parseInt(devis?.remise, 10) });
                },
            }
        );
    };

    return (
        <>
            <Table
                height={getTableHeight(238)}
                renderLoading={LoaderSpinner}
                className='table'
                data={sortData()}
                rowHeight={100}
                sortColumn={sortColumn}
                onSortColumn={handleSortColumn}
                defaultSortType='asc'
                loading={loading || devisLoading}
                locale={{ emptyMessage: 'Aucun produit', loading: 'Chargement' }}
            >
                <Column>
                    <HeaderCell />
                    <ImageCell dataKey='vignette' openDetails={openDetails} />
                </Column>

                <Column flexGrow={1} minWidth={300} sortable>
                    <HeaderCell>Produit</HeaderCell>
                    <NameCell dataKey='nom' />
                </Column>

                <Column width={130}>
                    <HeaderCell>Stock virtuel</HeaderCell>
                    <Cell dataKey='stock'>{({ stock }) => <p>{stock}</p>}</Cell>
                </Column>

                <Column width={115}>
                    <HeaderCell>Stock total</HeaderCell>
                    <Cell dataKey='stock_reel'>{({ stock_reel }) => <p>{stock_reel}</p>}</Cell>
                </Column>

                <Column width={110}>
                    <HeaderCell>Prix U. HT</HeaderCell>
                    <Cell dataKey='prix'>{({ prix }) => <p>{numberToEUR(prix)}</p>}</Cell>
                </Column>

                <Column width={166}>
                    <HeaderCell>Quantité</HeaderCell>
                    <Cell>
                        {({ id, conditionnement, stock, produit_desactiver }) => (
                            <FormQuantity
                                name={`${id}.qte`}
                                register={register}
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                                step={parseInt(conditionnement || 1, 10)}
                                max={produit_desactiver ? stock : undefined}
                                editable
                            />
                        )}
                    </Cell>
                </Column>

                {devis && (
                    <Column align='right' width={91}>
                        <HeaderCell>
                            <p>Reduc.</p>
                        </HeaderCell>
                        <Cell>
                            {({ id, produit_sans_remise }) => (
                                <ReductionCell
                                    disabled={parseInt(produit_sans_remise, 10) === 1}
                                    name={`${id}.remise`}
                                    control={control}
                                    defaultValue={parseInt(produit_sans_remise, 10) ? 0 : parseFloat(devis.remise, 10)}
                                    setValue={setValue}
                                />
                            )}
                        </Cell>
                    </Column>
                )}

                <Column align='right' width={140}>
                    <HeaderCell>
                        <p>Total HT</p>
                    </HeaderCell>
                    <Cell>
                        {({ id, prix }) => (
                            <TotalHtCell name={id} control={control} prix={parseFloat(prix)} getValues={getValues} />
                        )}
                    </Cell>
                </Column>

                <Column fixed='right' width={88}>
                    <HeaderCell />
                    <Cell>
                        {({ id }) => (
                            <div className='flex right w-100 mr-1'>
                                <ButtonIcon
                                    onClick={() => addNewProduct(id, getValues(id))}
                                    icon={<CheckIcon />}
                                    disabled={loadingAddProduct}
                                />
                            </div>
                        )}
                    </Cell>
                </Column>
            </Table>

            {isShowing && productId && (
                <Modal isShowing={isShowing} hide={toggle} right>
                    <ProductDetails productId={productId} />
                </Modal>
            )}

            {declinaisons && (
                <Modal isShowing={!!declinaisons} hide={toggleDeclinaisons} isClosable={false}>
                    {declinaisons}
                </Modal>
            )}

            {isShowinCheckModal && checkModalDetails && (
                <Modal isShowing={isShowinCheckModal} hide={toggleCheckModal}>
                    <CheckOrderProduct data={checkModalDetails} />
                </Modal>
            )}
        </>
    );
}

export default TableAddProducts;
