/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import 'rsuite-table/dist/css/rsuite-table.css';

function Table({ data, children }) {
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();

    function dynamicSort(key) {
        let sortOrder = 1;

        if (key[0] === '-') {
            sortOrder = -1;
            key = key.substr(1);
        }

        return (a, b) => {
            if (sortOrder === -1) {
                return b[key].localeCompare(a[key]);
            }
            return a[key].localeCompare(b[key]);
        };
    }

    const sortData = () => {
        if (sortColumn && sortType) {
            if (sortType === 'asc') {
                return data.sort(dynamicSort(sortColumn));
            }
            return data.sort(dynamicSort(`-${sortColumn}`));
        }
        return data;
    };

    const handleSortColumn = (column, type) => {
        setSortColumn(column);
        setSortType(type);
    };

    return React.cloneElement(children, { handleSortColumn, sortData, sortColumn });
}

export default Table;
