import * as React from 'react';

function UsersIcon() {
    return (
        <svg width='1.5em' height='1.5em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M4.453 6.56H3.94A5.12 5.12 0 00.142 8.048L0 8.214v4.89h2.41v-2.776l.325-.366.147-.171A6.497 6.497 0 015.664 8.1a3.892 3.892 0 01-1.21-1.541zM15.876 8.03a5.121 5.121 0 00-3.798-1.488c-.215 0-.43.013-.643.036a3.893 3.893 0 01-1.182 1.447 6.444 6.444 0 012.953 1.772l.148.165.319.366v2.782H16V8.196l-.124-.165zM3.922 5.408h.183a3.81 3.81 0 011.837-3.715 2.416 2.416 0 10-2.02 3.739v-.024zM11.795 4.965c.007.136.007.272 0 .408.113.018.228.028.342.03h.112a2.415 2.415 0 10-2.203-3.65 3.857 3.857 0 011.749 3.212zM7.92 7.611a2.64 2.64 0 100-5.28 2.64 2.64 0 000 5.28z'
                fill='currentColor'
            />
            <path
                d='M8.062 9.017a5.723 5.723 0 00-4.2 1.595l-.147.165v3.739a.928.928 0 00.945.91h6.786a.927.927 0 00.945-.91v-3.727l-.142-.177a5.658 5.658 0 00-4.187-1.595z'
                fill='currentColor'
            />
        </svg>
    );
}

export default UsersIcon;
