import React from "react";
import PropTypes from "prop-types";
import Sticky from "react-sticky-el";

function Filters({ children }) {
    return (
        <div className="filters">
            <Sticky stickyStyle={{ zIndex: 10000 }} style={{ zIndex: 10000 }}>
                <div className="filters-outer">
                    <div className="filters-inner">{children}</div>
                </div>
            </Sticky>
        </div>
    );
}

Filters.propTypes = {
    children: PropTypes.element.isRequired,
};

export default Filters;
