import * as React from 'react';

export default function EyeIcon() {
    return (
        <svg width='1em' height='1em' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M7.34 7.436c0 .677.27 1.327.752 1.806a2.576 2.576 0 003.633 0 2.548 2.548 0 000-3.613 2.576 2.576 0 00-3.633 0 2.547 2.547 0 00-.753 1.807zm12.527-.589C17.694 2.292 14.407 0 10 0 5.591 0 2.307 2.292.132 6.85a1.37 1.37 0 000 1.174C2.307 12.58 5.594 14.872 10 14.872c4.409 0 7.693-2.293 9.867-6.85.177-.37.177-.798 0-1.175zM9.909 11.45c-2.23 0-4.037-1.797-4.037-4.014 0-2.217 1.808-4.015 4.037-4.015 2.23 0 4.037 1.798 4.037 4.015 0 2.217-1.807 4.014-4.037 4.014z'
                fill='currentColor'
            />
        </svg>
    );
}
