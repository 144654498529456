/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import { useHistory } from 'react-router';

// Components
import Badge from '../Badge/Badge';
import DateCell from './DateCell';
import LoaderSpinner from '../Loader/LoaderSpinner';
import QuantityCell from './QuantityCell';
import Comments from './modules/Comments';

// Icons
import ButtonIcon from '../Button/ButtonIcon';
import EyeIcon from '../../assets/icons/EyeIcon';

function TableCommandes({ handleSortColumn, sortData, sortColumn, loading }) {
    const history = useHistory();

    return (
        <Table
            autoHeight
            affixHeader={110}
            renderLoading={LoaderSpinner}
            className='table'
            data={sortData()}
            rowHeight={72}
            sortColumn={sortColumn}
            onSortColumn={handleSortColumn}
            defaultSortType='asc'
            loading={loading}
            locale={{ emptyMessage: "Aucun client n'a été trouvé", loading: 'Chargement' }}
        >
            <Column flexGrow={1} minWidth={200}>
                <HeaderCell>Statut</HeaderCell>
                <Cell>{({ statut }) => <Badge content={statut} />}</Cell>
            </Column>

            <Column flexGrow={1} minWidth={200} sortable>
                <HeaderCell>Date de livraison</HeaderCell>
                <DateCell dataKey='date_livraison' />
            </Column>

            <Column flexGrow={1} minWidth={190} sortable>
                <HeaderCell>Numéro</HeaderCell>
                <Cell dataKey='numero' />
            </Column>

            <Column flexGrow={1} minWidth={300} sortable>
                <HeaderCell>Client</HeaderCell>
                <Cell dataKey='client' />
            </Column>

            <Column flexGrow={1} minWidth={160}>
                <HeaderCell>Nb d’articles</HeaderCell>
                <Cell>
                    {({ nb_article, nb_article_livre }) => (
                        <QuantityCell quantity={parseInt(nb_article_livre, 10)} total={parseInt(nb_article, 10)} />
                    )}
                </Cell>
            </Column>

            <Column fixed='right' width={120}>
                <HeaderCell />
                <Cell>
                    {({ id, commentaire }) => (
                        <div className='flex right w-100'>
                            {commentaire && <Comments comments={commentaire} id={parseInt(id, 10)} />}
                            <ButtonIcon onClick={() => history.push(`commandes/${id}`)} icon={<EyeIcon />} />
                        </div>
                    )}
                </Cell>
            </Column>
        </Table>
    );
}

export default TableCommandes;
