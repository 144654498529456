/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import { useForm } from 'react-hook-form';

// Components
import ImageCell from './ImageCell';
import NameCell from './NameCell';
import Modal from '../Modal/Modal';
import ProductDetails from '../ProductDetails/ProductDetails';
import LoaderSpinner from '../Loader/LoaderSpinner';
import QuantityCell from './QuantityCell';
import FormQuantity from '../Form/FormQuantity';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import ButtonSecondary from '../Button/ButtonSecondary';
import Declinaison from './modules/Declinaison';

// Icons
import ButtonIcon from '../Button/ButtonIcon';
import PlusIcon from '../../assets/icons/PlusIcon';

// Hooks
import useModal from '../../hooks/useModal';

// Utils
import { getTableHeight } from '../../utils/utils';
import { useRuptureLigneBonLivraison, useUpdateLigneBonLivraison } from '../../api/hooks/ligneBonLivraisons.hooks';
import OutStockIcon from '../../assets/icons/OutStockIcon';
import Badge from '../Badge/Badge';

function TableCommandeDetails({ handleSortColumn, sortData, sortColumn, loading, commandeId }) {
    const [firstLaunch, setFirstLaunch] = useState(true);
    const tableRef = useRef();
    const [productId, setProductId] = useState(null);
    const [isShowing, toggle] = useModal();

    const openDetails = (id) => {
        setProductId(id);
        toggle();
    };

    return (
        <>
            <Table
                ref={tableRef}
                height={getTableHeight(238)}
                renderLoading={LoaderSpinner}
                className='table'
                data={sortData()}
                rowHeight={100}
                sortColumn={sortColumn}
                onSortColumn={handleSortColumn}
                defaultSortType='asc'
                loading={firstLaunch && loading}
                onDataUpdated={() => !loading && setFirstLaunch(false)}
                locale={{ emptyMessage: "Aucun produit n'a été trouvé", loading: 'Chargement' }}
                rowClassName={(rowData) => (parseInt(rowData?.rupture, 10) ? 'row-error' : '')}
            >
                <Column>
                    <HeaderCell />
                    <ImageCell dataKey='vignette' openDetails={openDetails} clickable={false} />
                </Column>

                <Column flexGrow={1} minWidth={300} sortable>
                    <HeaderCell>Produit</HeaderCell>
                    <NameCell dataKey='nom' />
                </Column>

                <Column width={130}>
                    <HeaderCell>Stock virtuel</HeaderCell>
                    <Cell dataKey='stock'>{({ stock }) => <p>{stock}</p>}</Cell>
                </Column>

                <Column width={150} sortable>
                    <HeaderCell>Zone</HeaderCell>
                    <Cell dataKey='zone_de_stockage' />
                </Column>

                <Column width={140}>
                    <HeaderCell>Quantité</HeaderCell>
                    <Cell>
                        {({ qte_selectionnee, qte_total }) => (
                            <QuantityCell quantity={parseInt(qte_selectionnee, 10)} total={parseInt(qte_total, 10)} />
                        )}
                    </Cell>
                </Column>
                <Column width={90}>
                    <HeaderCell>cond.</HeaderCell>
                    <Cell>
                        {({ conditionnement }) => (
                            <div className='flex center'>
                                <p>{conditionnement}</p>
                            </div>
                        )}
                    </Cell>
                </Column>

                <Column fixed='right' width={176}>
                    <HeaderCell />
                    <Cell>
                        {(rowData) => (
                            <div className='flex right w-100'>
                                {(() => {
                                    if (parseInt(rowData.rupture, 10)) {
                                        return <Badge type='error' content='Stock épuisé' />;
                                    }
                                    return (
                                        <>
                                            {rowData.declinaison !== '0' && (
                                                <Declinaison id={parseInt(rowData.id, 10)} />
                                            )}
                                            <HandleRupture
                                                tableRef={tableRef}
                                                commandeId={commandeId}
                                                id={rowData.id}
                                            />
                                            <HandleLine
                                                tableRef={tableRef}
                                                commandeId={commandeId}
                                                id={rowData.id}
                                                conditionnement={rowData.conditionnement}
                                                quantity={parseInt(rowData.qte_selectionnee, 10)}
                                                max={parseInt(rowData.qte_total, 10)}
                                                loadingData={loading}
                                                editable={!parseInt(rowData.declinaison, 10)}
                                                disabled={!rowData.modifiable}
                                            />
                                        </>
                                    );
                                })()}
                            </div>
                        )}
                    </Cell>
                </Column>
            </Table>

            {isShowing && productId && (
                <Modal isShowing={isShowing} hide={toggle} right>
                    <ProductDetails productId={productId} />
                </Modal>
            )}
        </>
    );
}

export default TableCommandeDetails;

const HandleLine = ({ tableRef, commandeId, id, conditionnement, quantity, max, disabled, editable, loadingData }) => {
    const [isShowing, toggle] = useModal();

    const { isLoading, mutate: editLine } = useUpdateLigneBonLivraison(commandeId);

    const { register, handleSubmit, setValue } = useForm();

    async function onSubmit(formData) {
        const currentScroll = Math.abs(tableRef.current.scrollY);
        editLine(
            { ligne_bon_livraison_id: id, ...formData },
            {
                onSettled: () => {
                    tableRef.current.scrollTop(currentScroll);
                    setTimeout(toggle, 200);
                },
            }
        );
    }

    return (
        <>
            <ButtonIcon onClick={toggle} icon={<PlusIcon />} />
            {isShowing && (
                <Modal isShowing={isShowing} hide={toggle}>
                    <form onSubmit={handleSubmit(onSubmit)} className='form' noValidate>
                        <div className='flex column center'>
                            <p className='label'>Quantité selectionnée</p>
                            <FormQuantity
                                value={quantity || max}
                                name='qte'
                                register={register}
                                setValue={setValue}
                                step={parseInt(conditionnement || 1, 10)}
                                max={max}
                                editable={editable}
                                disabled={disabled}
                            />
                        </div>
                        <div className='flex column center'>
                            <Button disabled={isLoading || loadingData}>
                                Valider
                                {(isLoading || loadingData) && <Loader />}
                            </Button>
                            <ButtonSecondary onClick={toggle}>
                                <p className='mt-2'>Annuler</p>
                            </ButtonSecondary>
                        </div>
                    </form>
                </Modal>
            )}
        </>
    );
};

const HandleRupture = ({ tableRef, commandeId, id }) => {
    const [isShowing, toggle] = useModal();

    const { loading, mutate: ruptureLigneDevis } = useRuptureLigneBonLivraison(commandeId);

    const handleRuptureLine = () => {
        const currentScroll = Math.abs(tableRef.current.scrollY);
        ruptureLigneDevis(
            { ligne_bon_livraison_id: id },
            {
                onSettled: () => {
                    tableRef.current.scrollTop(currentScroll);
                    setTimeout(toggle, 2000);
                },
            }
        );
    };

    return (
        <>
            <ButtonIcon onClick={toggle} icon={<OutStockIcon />} status='error' />
            {isShowing && (
                <Modal isShowing={isShowing} hide={toggle}>
                    <form className='form p-5 flex column center'>
                        <p className='text-bold text-center'>Ce produit est-il en rupture de stock ?</p>
                        <Button onClick={handleRuptureLine} disabled={loading} type='button'>
                            Oui
                            {loading && <Loader />}
                        </Button>
                        <ButtonSecondary onClick={toggle}>Non</ButtonSecondary>
                    </form>
                </Modal>
            )}
        </>
    );
};
