import React from "react";
import PropTypes from "prop-types";

function Footer({ children }) {
    return <footer className="footer">{children}</footer>;
}

Footer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default Footer;
