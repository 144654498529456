/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

function FormText({ label, placeholder, name, register, errors, rules }) {
    return (
        <div className="form__group">
            <label htmlFor={name}>{label}</label>
            <input
                id={name}
                placeholder={placeholder}
                type="text"
                {...register(name, rules)}
                className={errors[name] ? 'error' : undefined}
            />
            {errors[name]?.type === 'pattern' && <p className="text-error">{label} non valide</p>}
            {errors[name]?.type === 'required' && <p className="text-error">{label} requis</p>}
        </div>
    );
}

FormText.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.shape().isRequired,
    rules: PropTypes.shape(),
};

FormText.defaultProps = {
    rules: { required: true },
};

export default FormText;
