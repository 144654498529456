import React from 'react';
import PropTypes from 'prop-types';

function Header({ children }) {
    return <header className='header'>{children}</header>;
}

Header.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default Header;
