/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

function FormSelect({ label, placeholder, name, register, errors, options, disabled }) {
    return (
        <div className='form__group'>
            <label htmlFor={name}>{label}</label>
            <select
                className={`input-select ${errors[name]?.type === 'required' ? 'error' : ''}`}
                {...register(name, { required: true })}
                name={name}
                id={name}
                disabled={disabled}
            >
                <option value=''>{placeholder}</option>
                {options.map((option) => (
                    <option value={option.value} key={option.value}>
                        {`${option.label}`}
                    </option>
                ))}
            </select>
            {errors[name]?.type === 'required' && <p className='text-error'>{label} requis</p>}
        </div>
    );
}

FormSelect.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.shape().isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })
    ).isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

FormSelect.defaultProps = {
    onChange: undefined,
    disabled: false,
};

export default FormSelect;
