import React from "react";
import PropTypes from "prop-types";

// Icons
import AlertIcon from "../../assets/icons/AlertIcon";

function Error({ message }) {
    return (
        <div className="error-page">
            <AlertIcon />
            <p>{message}</p>
        </div>
    );
}

Error.propTypes = {
    message: PropTypes.string.isRequired,
};

export default Error;
