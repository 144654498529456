import { useMutation, useQueryClient } from 'react-query';
import { notify } from '../../utils/utils';
import ligneBonLivraisonsActions from '../actions/ligneBonLivraisons.actions';
import ligneDevisActions from '../actions/ligneDevis.actions';

export function useUpdateLigneBonLivraison(commandeId) {
    const queryClient = useQueryClient();
    return useMutation((ligneBonLivraison) => ligneBonLivraisonsActions.update(ligneBonLivraison), {
        onMutate: async ({ qte, ligne_bon_livraison_id }) => {
            await queryClient.cancelQueries(['commande', commandeId]);

            const prevCommande = queryClient.getQueryData(['commande', commandeId]);
            const updatedLignes = prevCommande.lignes.map((ligne) =>
                ligne.id === ligne_bon_livraison_id ? { ...ligne, qte_selectionnee: qte } : ligne
            );

            queryClient.setQueryData(['commande', commandeId], {
                ...prevCommande,
                lignes: updatedLignes,
            });
            return { prevCommande };
        },
        onSuccess: () => {
            notify('Quantité modifiée', 'success');
        },
        onError: (_err, _variables, context) => {
            notify('Une erreur est survenue', 'error');
            queryClient.setQueryData(['commande', commandeId], context.prevCommande);
        },
    });
}

export function useRuptureLigneBonLivraison(commandeId) {
    const queryClient = useQueryClient();
    return useMutation(
        (ligneBonLivraison) => ligneBonLivraisonsActions.rupture(ligneBonLivraison),
        {
            onMutate: async ({ ligne_bon_livraison_id }) => {
                await queryClient.cancelQueries(['commande', commandeId]);
                const prevCommande = queryClient.getQueryData(['commande', commandeId]);
                const updatedLignes = prevCommande.lignes.map((ligne) =>
                    ligne.id === ligne_bon_livraison_id ? { ...ligne, rupture: 1 } : ligne
                );
                queryClient.setQueryData(['commande', commandeId], {
                    ...prevCommande,
                    lignes: updatedLignes,
                });
                return { prevCommande };
            },
            onSuccess: () => {
                notify('Rupture de stock enregistrée', 'success');
            },
            onError: (_err, _variables, context) => {
                notify('Une erreur est survenue', 'error');
                queryClient.setQueryData(['commande', commandeId], context.prevCommande);
            },
        }
    );
}

export function useDeleteLigneDevis(devisId) {
    const queryClient = useQueryClient();
    return useMutation((ligneId) => ligneDevisActions.remove(ligneId), {
        onMutate: async (ligneId) => {
            await queryClient.cancelQueries(['devis', devisId]);

            const prevDevis = queryClient.getQueryData(['devis', devisId]);
            const removeDeleted = prevDevis.ligne_de_commande.filter(
                (ligne) => ligne.ligne_id !== ligneId
            );
            queryClient.setQueryData(['devis', devisId], {
                ...prevDevis,
                ligne_de_commande: removeDeleted,
            });
            return { prevDevis };
        },
        onSuccess: () => {
            notify('Ligne supprimée', 'success');
        },
        onError: (_err, _variables, context) => {
            notify('Une erreur est survenue', 'error');
            queryClient.setQueryData(['devis', devisId], context.prevDevis);
        },
    });
}
