/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { notify } from '../../utils/utils';
import devisActions from '../actions/devis.actions';

export function useGetDevis() {
    return useQuery('devis', devisActions.list);
}
export function useGetDevisDetails(id) {
    return useQuery(['devis', id], () => devisActions.item(id));
}

export function useCreateDevis() {
    const queryClient = useQueryClient();
    return useMutation(devisActions.create, {
        onSuccess: () => {
            notify('Le devis a bien été créé', 'success');
        },
        onError: () => {
            notify('Une erreur est survenue', 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('devis');
        },
    });
}

export function useDuplicateDevis() {
    const queryClient = useQueryClient();
    return useMutation(devisActions.duplicate, {
        onSuccess: () => {
            notify('Le devis a bien été dupliqué', 'success');
        },
        onError: () => {
            notify('Une erreur est survenue', 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('devis');
        },
    });
}

export function useValidateDevis() {
    const queryClient = useQueryClient();
    return useMutation(devisActions.validate, {
        onSuccess: () => {
            notify('Devis signé', 'success');
        },
        onError: () => {
            notify('Une erreur est survenue', 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('devis');
        },
    });
}

export function useAddProductDevis(devisId) {
    const queryClient = useQueryClient();
    return useMutation(devisActions.addProduct, {
        onSuccess: () => {
            notify('Produit ajouté au devis', 'success');
        },
        onError: () => {
            notify('Une erreur est survenue', 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('devis', { exact: true });
            queryClient.invalidateQueries(['devis', devisId]);
        },
    });
}

export function useCheckOrderProduct() {
    return useMutation(devisActions.checkOrderProduct);
}
