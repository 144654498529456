/* eslint-disable react/prop-types */
import { withRouter } from 'react-router-dom';

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

const AuthVerify = ({ history, logout }) => {
    history.listen(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const decodedJwt = parseJwt(user);
            if (decodedJwt.exp * 1000 < Date.now()) {
                logout();
            }
        }
    });
    return null;
};

export default withRouter(AuthVerify);
