import * as React from 'react';

function DevisIcon() {
    return (
        <svg viewBox='0 0 14 17' xmlSpace='preserve' width='1.5em' height='1.5em'>
            <path
                fill='currentColor'
                d='M8.8 4.8c-.3-.3-.4-.7-.4-1V0H2.3c-.5 0-1 .2-1.3.6-.4.3-.6.8-.6 1.3v13.2c0 .5.2 1 .6 1.3.3.4.8.6 1.3.6h9.4c.5 0 1-.2 1.3-.6.4-.4.6-.8.6-1.3V5.3H9.8c-.4 0-.7-.2-1-.5zM4.2 3.2h.9c.3 0 .5.2.5.5s-.2.6-.5.6h-.9c-.3 0-.5-.2-.5-.5s.2-.6.5-.6zm5.6 10.6H7.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1.9c.3 0 .5.2.5.5s-.2.5-.5.5zm.6-4.4c0 .3-.2.5-.5.5H4.2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5.7c.2 0 .5.3.5.5z'
            />
            <path fill='currentColor' d='M9.5 4.1c.1.1.2.1.3.1H13L9.4.5v3.2c0 .2.1.3.1.4z' />
        </svg>
    );
}

export default DevisIcon;
