import apiClient from '../apiClient';

const list = async () => {
    const response = await apiClient.get('/liste_avoir');
    return response.data;
};

const item = async (id) => {
    const response = await apiClient.get(`/detail_avoir/?avoir_id=${id}`);
    return response.data;
};

async function create(avoir) {
    return apiClient.post('gestion_avoir', avoir);
}

async function validate(data) {
    return apiClient.post('/valider_avoir', data);
}

async function addProduct(data) {
    return apiClient.post(`/gestion_ligne_avoir`, data);
}

const clientsActions = { list, item, create, validate, addProduct };

export default clientsActions;
