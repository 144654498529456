/* eslint-disable no-unused-vars */
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

// Components
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import SignCanvas from '../../components/SignCanvas/SignCanvas';

// Utils
import { useValidateDevis } from '../../api/hooks/devis.hooks';

export default function SignatureForm({ id }) {
    const history = useHistory();

    const { isLoading, mutate: signDevis } = useValidateDevis();

    const { handleSubmit, control } = useForm();

    const onSubmit = async (formData) => {
        signDevis(
            {
                devis_id: id,
                ...formData,
            },
            {
                onSuccess: ({ data }) => history.push(`/clients/${data.client_id}`),
            }
        );
    };

    return (
        <div className="p-4">
            <form onSubmit={handleSubmit(onSubmit)} className="form">
                <Controller
                    name="imageUrl"
                    control={control}
                    render={({ field }) => <SignCanvas field={field} />}
                />
                <div className="flex right">
                    <Button disabled={isLoading}>
                        Signer le devis
                        {isLoading && <Loader />}
                    </Button>
                </div>
            </form>
        </div>
    );
}

SignatureForm.propTypes = {
    id: PropTypes.number.isRequired,
};
