import * as React from 'react';

function ArrowLeftIcon() {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.875 1.125L1 9l7.875 7.875M1 9h18"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default ArrowLeftIcon;
