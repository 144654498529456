import React from 'react';
import PropTypes from 'prop-types';

export default function ModalHeader({ children }) {
    return (
        <div className="modal__header">
            <p className="text-lg">{children}</p>
        </div>
    );
}

ModalHeader.propTypes = {
    children: PropTypes.string,
};

ModalHeader.defaultProps = {
    children: '',
};
