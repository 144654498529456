import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

// Hooks
import useModal from '../../hooks/useModal';

// Components
import Filters from '../../components/Filters/Filters';
import FormSearch from '../../components/Form/FormSearch';
import Header from '../../components/Header/Header';
import Error from '../../components/Error/Error';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import PriceDetails from './PriceDetails';
import SignDevis from '../../components/Table/modules/SignDevis';

// Table
import Table from '../../components/Table/Table';
import TableDevisDetails from '../../components/Table/TableDevisDetails';

// Icons
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon';
import ButtonIcon from '../../components/Button/ButtonIcon';

// Utils
import { numberToEUR } from '../../utils/utils';

// icons
import PlusIcon from '../../assets/icons/PlusIcon';

// api
import { useGetDevisDetails } from '../../api/hooks/devis.hooks';
import useDebounce from '../../hooks/useDebounce';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { useDevisDetailsFiltersContext } from '../../contexts/filters/devisDetailsFiltersContext';
import { filterObjectsByValue } from '../../utils/object';

export default function DevisDetails() {
    const history = useHistory();
    const { devisId } = useParams();
    const { devisDetailsFilters, setDevisDetailsFilters } = useDevisDetailsFiltersContext();
    const [filteredData, setFilteredData] = useState([]);
    const [isShowing, toggle] = useModal();

    const { data: devis, isLoading, isError } = useGetDevisDetails(parseInt(devisId, 10));

    const methods = useForm({ defaultValues: devisDetailsFilters });

    const searchInput = useWatch({ control: methods.control, name: 'search-input' });
    const debouncedSearchInput = useDebounce(searchInput, 500);
    useDidMountEffect(() => {
        setDevisDetailsFilters((prevState) => ({
            ...prevState,
            'search-input': debouncedSearchInput,
        }));
    }, [debouncedSearchInput]);

    useEffect(() => {
        if (!devisDetailsFilters || devisDetailsFilters?.['search-input'] === '') {
            return setFilteredData(devis?.ligne_de_commande);
        }
        const newData = filterObjectsByValue(devis?.ligne_de_commande, debouncedSearchInput, [
            'nom',
            'reference',
        ]);
        return setFilteredData(newData);
    }, [devis, devisDetailsFilters]);

    if (isError) return <Error message="Erreur lors de la récupération du détail du devis" />;

    return (
        <>
            <Header>
                <div className="flex space">
                    <div className="flex center">
                        <ButtonIcon
                            icon={<ArrowLeftIcon />}
                            onClick={() => history.push('/devis')}
                        />
                        <h1 className="text-lg ml-1">Devis {devis?.numero || ''}</h1>
                    </div>
                    <div className="flex center">
                        <p className="text-lg mr-2">
                            Total HT : {numberToEUR(devis?.montant_ht || 0)}
                        </p>
                        <div className="mr-2">
                            <Button iconRight=" €" onClick={toggle}>
                                Détails
                            </Button>
                        </div>
                        <SignDevis id={parseInt(devisId, 10)} />
                    </div>
                </div>
            </Header>
            <Filters>
                <div className="flex space">
                    <FormProvider {...methods}>
                        <FormSearch placeholder="Nom, Référence, ..." name="search-input" />
                    </FormProvider>
                    <Button
                        icon={<PlusIcon />}
                        onClick={() => history.push(`/devis/${devisId}/add`)}
                    >
                        Ajouter un produit
                    </Button>
                </div>
            </Filters>
            <section className="section">
                <Table data={filteredData}>
                    <TableDevisDetails loading={isLoading} />
                </Table>
            </section>

            {isShowing && (
                <Modal isShowing={isShowing} hide={toggle}>
                    <PriceDetails
                        HT={parseInt(devis?.montant_ht || 0, 10)}
                        TVA={parseInt(devis?.tva || 0, 10)}
                        TTC={parseInt(devis?.montant_ttc || 0, 10)}
                        remise={parseInt(devis?.remise || 0, 10)}
                    />
                </Modal>
            )}
        </>
    );
}
