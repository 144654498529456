import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

function FormCheckbox({ children, name, checked }) {
    const { register } = useFormContext();

    return (
        <div>
            <div className='input-checkbox'>
                <input
                    className='input-checkbox__input'
                    type='checkbox'
                    id={name}
                    defaultChecked={checked}
                    {...register(name)}
                />
                <label className='input-checkbox__label' htmlFor={name}>
                    {children}
                </label>
            </div>
        </div>
    );
}

FormCheckbox.propTypes = {
    children: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
};

FormCheckbox.defaultProps = {
    checked: false,
};

export default FormCheckbox;
