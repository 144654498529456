import React from 'react';
import PropTypes from 'prop-types';
import AlertIcon from '../../../assets/icons/AlertIcon';

const CheckOrderProduct = ({ data }) => (
    <section className="section">
        <div className='quantity-details'>
            <div className='flex' style={{ maxWidth: 300 }} >
                <div className='mr-2'>
                    <AlertIcon />
                </div>
                <p>Attention : Ce produit est déjà en attente de livraison dans une ou plusieurs commandes partiellement livrées.</p>
            </div>
            <div className="flex">
                <div className='mb-2'>

                    <p className='text-lg mb-2' style={{ minWidth: 50 }}>
                        N° de commande
                    </p>
                    {data.map(array => <p>{array[0]}</p>)}
                </div>
                <div className='ml-5'>
                    <p className='text-lg mb-2'>Qté</p>
                    {data.map(array => <p className='text-right'>{array[1]}</p>)}
                </div>
            </div>
        </div>
    </section>
);

export default CheckOrderProduct;

CheckOrderProduct.propTypes = {
    data: PropTypes.shape.isRequired,
};