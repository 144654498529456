/* eslint-disable react/prop-types */

import React, { createContext, useContext, useState } from 'react';

// create context
const CommandesFiltersContext = createContext();

const CommandesFiltersContextProvider = ({ children }) => {
    const [commandesFilters, setCommandesFilters] = useState(null);

    return (
        <CommandesFiltersContext.Provider value={{ commandesFilters, setCommandesFilters }}>
            {children}
        </CommandesFiltersContext.Provider>
    );
};

const useCommandesFiltersContext = () => {
    const context = useContext(CommandesFiltersContext);

    if (context === undefined) {
        throw new Error('useCommandesFiltersContext est utilisé en dehors de son provider');
    }

    return context;
};

export { CommandesFiltersContextProvider, useCommandesFiltersContext };
