import * as React from 'react';

export default function EyeCloseIcon() {
    return (
        <svg width='1em' height='1em' viewBox='0 0 34 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M16.844 19.631a4.367 4.367 0 004.35-4.747l-4.73 4.73c.125.012.252.017.38.017zM31.299 1.756L29.634.09a.312.312 0 00-.441 0L24.93 4.355C22.578 3.153 19.935 2.553 17 2.553c-7.494 0-13.086 3.903-16.775 11.71a2.352 2.352 0 000 2.007c1.474 3.105 3.249 5.593 5.325 7.465L1.425 27.86a.312.312 0 000 .441l1.664 1.665a.312.312 0 00.441 0L31.3 2.197a.312.312 0 000-.441zM9.981 15.264A6.862 6.862 0 0120.076 9.21l-1.896 1.896a4.37 4.37 0 00-5.495 5.495l-1.896 1.896a6.826 6.826 0 01-.808-3.232z'
                fill='currentColor'
            />
            <path
                d='M33.775 14.258c-1.373-2.89-3.006-5.248-4.901-7.071l-5.62 5.62a6.866 6.866 0 01-8.867 8.867L9.62 26.44c2.212 1.023 4.672 1.535 7.38 1.535 7.494 0 13.086-3.903 16.775-11.71a2.351 2.351 0 000-2.008z'
                fill='currentColor'
            />
        </svg>
    );
}
