import React from 'react';
import PropTypes from 'prop-types';

export default function ModalFooter({ children }) {
    return <div className='modal__footer'>{children}</div>;
}

ModalFooter.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
};

ModalFooter.defaultProps = {
    children: '',
};
