import apiClient from '../apiClient';

const list = async () => {
    const response = await apiClient.get(`/liste_commande`);
    return response.data;
};

const item = async (id) => {
    const response = await apiClient.get(`/gestion_bon_livraison/?commande_id=${id}`);
    return response.data;
};

const commandesActions = { list, item };

export default commandesActions;
