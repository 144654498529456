/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

// Icons
import ButtonIcon from '../Button/ButtonIcon';
import PrevIcon from '../../assets/icons/PrevIcon';
import NextIcon from '../../assets/icons/NextIcon';

function Pagination({ setPage, page, totalPages }) {
    return (
        <div className="pagination">
            <ButtonIcon
                icon={<PrevIcon />}
                onClick={() => setPage((prev) => prev - 1)}
                disabled={page <= 1}
                type="button"
            />
            <ButtonIcon
                icon={<NextIcon />}
                onClick={() => setPage((prev) => prev + 1)}
                disabled={page >= totalPages}
                type="button"
            />
        </div>
    );
}

export default Pagination;

Pagination.propTypes = {
    setPage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    totalPages: PropTypes.number,
};

Pagination.defaultProps = {
    totalPages: 1,
};
