import React from 'react';
import PropTypes from 'prop-types';

export default function ButtonIcon({ icon, onClick, disabled, status }) {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            type="submit"
            className={`button-icon ${status}`}
        >
            {icon}
        </button>
    );
}

ButtonIcon.propTypes = {
    icon: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    status: PropTypes.string,
};

ButtonIcon.defaultProps = {
    onClick: undefined,
    disabled: false,
    status: '',
};
