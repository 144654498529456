/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import { useHistory } from 'react-router';

// Components
import DateCell from './DateCell';
import LoaderSpinner from '../Loader/LoaderSpinner';

// Icons
import ButtonIcon from '../Button/ButtonIcon';
import EyeIcon from '../../assets/icons/EyeIcon';

// Utils
import { numberToEUR } from '../../utils/utils';
import QuantityCell from './QuantityCell';

function TableFournisseurs({ handleSortColumn, sortData, sortColumn, loading }) {
    const history = useHistory();

    return (
        <Table
            autoHeight
            affixHeader={110}
            renderLoading={LoaderSpinner}
            className="table"
            data={sortData()}
            rowHeight={72}
            sortColumn={sortColumn}
            onSortColumn={handleSortColumn}
            defaultSortType="asc"
            loading={loading}
            locale={{ emptyMessage: "Aucun client n'a été trouvé", loading: 'Chargement' }}
        >
            <Column flexGrow={1} minWidth={250} sortable>
                <HeaderCell>Fournisseur</HeaderCell>
                <Cell dataKey="fournisseur" />
            </Column>

            <Column flexGrow={1} minWidth={190} sortable>
                <HeaderCell>Numéro</HeaderCell>
                <Cell dataKey="numero" />
            </Column>

            <Column flexGrow={1} minWidth={160}>
                <HeaderCell>Nb d’articles</HeaderCell>
                <Cell>
                    {({ nb_article, nb_article_livre }) => (
                        <QuantityCell
                            quantity={parseInt(nb_article_livre, 10)}
                            total={parseInt(nb_article, 10)}
                        />
                    )}
                </Cell>
            </Column>

            <Column flexGrow={1} minWidth={200} sortable>
                <HeaderCell>Date de création</HeaderCell>
                <DateCell dataKey="date" />
            </Column>

            <Column flexGrow={1} minWidth={150}>
                <HeaderCell>Montant</HeaderCell>
                <Cell dataKey="montant">{({ montant }) => <p>{numberToEUR(montant)}</p>}</Cell>
            </Column>

            <Column fixed="right" width={88}>
                <HeaderCell />
                <Cell>
                    {({ id }) => (
                        <ButtonIcon
                            onClick={() => history.push(`fournisseurs/${id}`)}
                            icon={<EyeIcon />}
                        />
                    )}
                </Cell>
            </Column>
        </Table>
    );
}

export default TableFournisseurs;
