import React from 'react';
import PropTypes from 'prop-types';

// contexts
import { ProductsFiltersContextProvider } from './productsFiltersContext';
import { ClientsFiltersContextProvider } from './clientsFiltersContext';
import { DevisFiltersContextProvider } from './devisFiltersContext';
import { DevisDetailsFiltersContextProvider } from './devisDetailsFiltersContext';
import { CommandesFiltersContextProvider } from './commandesFiltersContext';
import { CommandesDetailsFiltersContextProvider } from './commandesDetailsFiltersContext';
import { BonsLivraisonFiltersContextProvider } from './bonsLivraisonFiltersContext';
import { FournisseursFiltersContextProvider } from './fournisseursFiltersContext';
import { FournisseursDetailsFiltersContextProvider } from './fournisseursDetailsFiltersContext';
import { AvoirsFiltersContextProvider } from './avoirsFiltersContext';
import { AvoirsDetailsFiltersContextProvider } from './avoirsDetailsFiltersContext';

function FiltersContexts({ children }) {
    return (
        <ProductsFiltersContextProvider>
            <ClientsFiltersContextProvider>
                <DevisFiltersContextProvider>
                    <DevisDetailsFiltersContextProvider>
                        <CommandesFiltersContextProvider>
                            <CommandesDetailsFiltersContextProvider>
                                <BonsLivraisonFiltersContextProvider>
                                    <FournisseursFiltersContextProvider>
                                        <FournisseursDetailsFiltersContextProvider>
                                            <AvoirsFiltersContextProvider>
                                                <AvoirsDetailsFiltersContextProvider>
                                                    {children}
                                                </AvoirsDetailsFiltersContextProvider>
                                            </AvoirsFiltersContextProvider>
                                        </FournisseursDetailsFiltersContextProvider>
                                    </FournisseursFiltersContextProvider>
                                </BonsLivraisonFiltersContextProvider>
                            </CommandesDetailsFiltersContextProvider>
                        </CommandesFiltersContextProvider>
                    </DevisDetailsFiltersContextProvider>
                </DevisFiltersContextProvider>
            </ClientsFiltersContextProvider>
        </ProductsFiltersContextProvider>
    );
}

export default FiltersContexts;

FiltersContexts.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};
