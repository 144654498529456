/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

export default function Button({ children, onClick, disabled, icon, iconRight, border, type }) {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            type={type}
            className={`button ${border ? 'button-outline' : undefined}`}
        >
            {icon}
            {children}
            {iconRight}
        </button>
    );
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    icon: PropTypes.element,
    iconRight: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    border: PropTypes.bool,
    type: PropTypes.string,
};

Button.defaultProps = {
    onClick: undefined,
    disabled: false,
    icon: undefined,
    iconRight: undefined,
    border: false,
    type: 'submit',
};
