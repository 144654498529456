/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

// Datepicker
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';

// Icons
import CalendarIcon from '../../assets/icons/CalendarIcon';

registerLocale('fr', fr); // register it with the name you want

function FormDate({ label, name, errors, control, defaultDate, setValue }) {
    useEffect(() => {
        if (defaultDate) {
            setValue(name, new Date(defaultDate));
        }
    }, [defaultDate]);

    return (
        <div className={`form__group ${errors[name] ? 'error' : undefined}`}>
            <div className="input-date">
                <Controller
                    control={control}
                    name={name}
                    rules={{ required: true }}
                    defaultValue={new Date()}
                    render={({ field: { onChange, value } }) => (
                        <DatePicker
                            locale="fr"
                            dateFormat="EEEE d MMM"
                            placeholderText="Choisissez une date"
                            onChange={(date) => onChange(date)}
                            selected={value}
                            customInput={<Input label={label} />}
                        />
                    )}
                />
                {errors[name]?.type === 'required' && (
                    <p className="form-error">Veuillez choisir une date</p>
                )}
            </div>
        </div>
    );
}

FormDate.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    errors: PropTypes.shape().isRequired,
    control: PropTypes.shape().isRequired,
    defaultDate: PropTypes.string,
    setValue: PropTypes.func.isRequired,
};

FormDate.defaultProps = {
    defaultDate: '',
};

export default FormDate;

const Input = React.forwardRef(({ value, onClick, label }, ref) => (
    <div>
        <p className="label input-date-label" onClick={onClick} aria-hidden>
            {label}
        </p>
        <button className="input-date-input" onClick={onClick} ref={ref} type="button">
            {value}
            <CalendarIcon />
        </button>
    </div>
));

Input.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

Input.defaultProps = {
    value: 'Choisissez une date',
    onClick: undefined,
};
