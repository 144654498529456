import apiClient from '../apiClient';

const list = async () => {
    const response = await apiClient.get(`/liste_commande_fournisseur`);
    return response.data;
};

const item = async (id) => {
    const response = await apiClient.get(
        `/detail_commande_fournisseur/?commande_fournisseur_id=${id}`
    );
    return response.data;
};

const commandesFournisseurActions = { list, item };

export default commandesFournisseurActions;
