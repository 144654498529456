import * as React from 'react';

function PlusIcon() {
    return (
        <svg width='1em' height='1em' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M7 6H1m6 6V6v6zm0-6V0v6zm0 0h6-6z' stroke='currentColor' strokeWidth={2} strokeLinecap='round' />
        </svg>
    );
}

export default PlusIcon;
