import React from 'react';
import { useHistory, useParams } from 'react-router';

// Components
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import TableClientDetails from '../../components/Table/TableClientDetails';
import Footer from '../../components/Footer/Footer';
import Error from '../../components/Error/Error';

// Icons
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon';
import ButtonIcon from '../../components/Button/ButtonIcon';

// Utils
import { numberToEUR } from '../../utils/utils';
import Button from '../../components/Button/Button';
import PlusIcon from '../../assets/icons/PlusIcon';

// Hooks
import useModal from '../../hooks/useModal';
import Modal from '../../components/Modal/Modal';
import ModalHeader from '../../components/Modal/ModalHeader';
import NewDevisForm from '../Devis/NewDevisForm';
import NewClientForm from './NewClientForm';
import { useGetClient } from '../../api/hooks/clients.hooks';

function ClientDetails() {
    const history = useHistory();
    const { id } = useParams();
    const [modalDevisIsShowing, toggleModalDevis] = useModal();
    const [modalClientIsShowing, toggleModalClient] = useModal();

    const { data: client, isLoading, isError } = useGetClient(id);

    const openModalDevis = () => {
        toggleModalDevis();
        toggleModalClient();
    };

    if (isError) return <Error message="Erreur lors de la récupération des détails du client" />;

    return (
        <>
            <Header>
                <div className="flex space">
                    <div className="flex">
                        <ButtonIcon
                            icon={<ArrowLeftIcon />}
                            onClick={() => history.push('/clients')}
                        />
                        <h1 className="text-lg ml-1">{client?.nom}</h1>
                    </div>
                    <Button icon={<PlusIcon />} onClick={toggleModalDevis}>
                        Créer devis
                    </Button>
                </div>
            </Header>
            <div className="mb-4" />
            <section className="section">
                <Table data={client?.commandes}>
                    <TableClientDetails loading={isLoading} />
                </Table>
            </section>
            <Footer>
                <div className="flex">
                    <div className="flex column">
                        <p className="text-bold text-error">Restant dû total</p>
                    </div>
                    <div className="flex column">
                        <p className="ml-5 text-bold text-error">
                            {numberToEUR(client?.total_restant_du)}
                        </p>
                    </div>
                </div>
            </Footer>

            {modalDevisIsShowing && (
                <Modal isShowing={modalDevisIsShowing} hide={toggleModalDevis} right>
                    <ModalHeader>Nouveau devis</ModalHeader>
                    <NewDevisForm openModalDevis={openModalDevis} clientId={parseInt(id, 10)} />
                </Modal>
            )}

            {modalClientIsShowing && (
                <Modal isShowing={modalClientIsShowing} hide={toggleModalClient} right>
                    <ModalHeader>Nouveau client</ModalHeader>
                    <NewClientForm />
                </Modal>
            )}
        </>
    );
}

export default ClientDetails;
