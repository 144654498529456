/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

function FormTextarea({ label, placeholder, name, register, errors, rules, rows }) {
    return (
        <div className='form__group'>
            {label && <label htmlFor={name}>{label}</label>}
            <textarea
                id={name}
                placeholder={placeholder}
                {...register(name, rules)}
                className={errors[name] ? 'error' : undefined}
                rows={rows}
            />
            {errors[name]?.type === 'pattern' && <p className='text-error'>{label} non valide</p>}
            {errors[name]?.type === 'required' && <p className='text-error'>{label} requis</p>}
        </div>
    );
}

export default FormTextarea;

FormTextarea.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.shape().isRequired,
    rules: PropTypes.shape(),
    rows: PropTypes.number,
};

FormTextarea.defaultProps = {
    label: undefined,
    rules: { required: true },
    placeholder: '',
    rows: 5,
};
