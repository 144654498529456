import axios from 'axios';

const login = async (credentials) => {
    const response = await axios.post(
        `${process.env.REACT_APP_API_URL}|jwt-authentication`,
        credentials
    );
    return response;
};

const logout = () => {
    localStorage.removeItem('user');
    window.location.reload();
};

const authHeader = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        return { Authorization: `${user}` };
    }
    return {};
};

const getCurrentUser = () => JSON.parse(localStorage.getItem('user'));

const AuthService = {
    login,
    logout,
    authHeader,
    getCurrentUser,
};
export default AuthService;
