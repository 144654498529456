/* eslint-disable react/prop-types */

import React, { createContext, useContext, useState } from 'react';

// create context
const ProductsFiltersContext = createContext();

const ProductsFiltersContextProvider = ({ children }) => {
    const [productsFilters, setProductsFilters] = useState(null);

    return (
        <ProductsFiltersContext.Provider value={{ productsFilters, setProductsFilters }}>
            {children}
        </ProductsFiltersContext.Provider>
    );
};

const useProductsFiltersContext = () => {
    const context = useContext(ProductsFiltersContext);

    if (context === undefined) {
        throw new Error('useProductsFiltersContext est utilisé en dehors de son provider');
    }

    return context;
};

export { ProductsFiltersContextProvider, useProductsFiltersContext };
