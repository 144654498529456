import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

// Components
import Filters from '../../components/Filters/Filters';
import FormSearch from '../../components/Form/FormSearch';
import Header from '../../components/Header/Header';
import Error from '../../components/Error/Error';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import Loader from '../../components/Loader/Loader';
import ButtonSecondary from '../../components/Button/ButtonSecondary';

// Table
import Table from '../../components/Table/Table';
import TableAvoirDetails from '../../components/Table/TableAvoirDetails';

// Icons
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon';
import ButtonIcon from '../../components/Button/ButtonIcon';

// Utils
import { filterObjectsByValue } from '../../utils/object';

// icons
import PlusIcon from '../../assets/icons/PlusIcon';

// hooks
import useModal from '../../hooks/useModal';
import useDebounce from '../../hooks/useDebounce';
import useDidMountEffect from '../../hooks/useDidMountEffect';

// api
import { useGetAvoir, useValidateAvoir } from '../../api/hooks/avoirs.hooks';

// context
import { useAvoirsDetailsFiltersContext } from '../../contexts/filters/avoirsDetailsFiltersContext';
import { numberToEUR } from '../../utils/utils';
import PriceDetails from '../Devis/PriceDetails';

export default function AvoirDetails() {
    const history = useHistory();
    const { avoirId } = useParams();
    const { avoirsDetailsFilters, setAvoirsDetailsFilters } = useAvoirsDetailsFiltersContext();
    const [filteredData, setFilteredData] = useState([]);
    const [isShowing, toggle] = useModal();
    const [isShowingDetails, toggleDetails] = useModal();

    const { data: avoir, isLoading, isError } = useGetAvoir(parseInt(avoirId, 10));
    const { isLoading: loadingValidate, mutate: validate } = useValidateAvoir();

    const methods = useForm({ defaultValues: avoirsDetailsFilters });

    const handleValidation = () => {
        validate(
            { avoir_id: avoirId },
            {
                onSuccess: () => {
                    history.push('/avoirs');
                },
            }
        );
    };

    const searchInput = useWatch({ control: methods.control, name: 'search-input' });
    const debouncedSearchInput = useDebounce(searchInput, 500);
    useDidMountEffect(() => {
        setAvoirsDetailsFilters((prevState) => ({
            ...prevState,
            'search-input': debouncedSearchInput,
        }));
    }, [debouncedSearchInput]);

    useEffect(() => {
        if (!avoirsDetailsFilters || avoirsDetailsFilters?.['search-input'] === '') {
            return setFilteredData(avoir?.ligne_avoir);
        }
        const newData = filterObjectsByValue(avoir?.ligne_avoir, debouncedSearchInput, ['nom', 'reference']);
        return setFilteredData(newData);
    }, [avoir, avoirsDetailsFilters]);

    if (isError) return <Error message="Erreur lors de la récupération du détail de l'avoir" />;

    return (
        <>
            <Header>
                <div className='flex space'>
                    <div className='flex center'>
                        <ButtonIcon icon={<ArrowLeftIcon />} onClick={() => history.push('/avoirs')} />
                        <h1 className='text-lg ml-1'>Avoir {avoir?.numero || ''}</h1>
                    </div>
                    <div className='flex center'>
                        <p className='text-lg mr-2'>Total HT : {numberToEUR(avoir?.montant_ht || 0)}</p>
                        <div className='mr-2'>
                            <Button iconRight=' €' onClick={toggleDetails}>
                                Détails
                            </Button>
                        </div>
                        <Button onClick={toggle}>Valider l'avoir</Button>
                    </div>
                </div>
            </Header>
            <Filters>
                <div className='flex space'>
                    <FormProvider {...methods}>
                        <FormSearch placeholder='Nom, Référence, ...' name='search-input' />
                    </FormProvider>
                    <Button icon={<PlusIcon />} onClick={() => history.push(`/avoirs/${avoirId}/add`)}>
                        Ajouter un produit
                    </Button>
                </div>
            </Filters>
            <section className='section'>
                <Table data={filteredData}>
                    <TableAvoirDetails loading={isLoading} />
                </Table>
            </section>

            {isShowing && (
                <Modal isShowing={isShowing} hide={toggle}>
                    <form className='form p-5 flex column center'>
                        <p className='text-bold text-center'>
                            Etes-vous certain de vouloir valider la création de cet avoir et de restocker les quantités
                            des produits selectionnés ?
                        </p>
                        <Button onClick={handleValidation} disabled={loadingValidate} type='button'>
                            Valider l'avoir
                            {loadingValidate && <Loader />}
                        </Button>
                        <ButtonSecondary onClick={toggle}>Annuler</ButtonSecondary>
                    </form>
                </Modal>
            )}

            {isShowingDetails && (
                <Modal isShowing={isShowingDetails} hide={toggleDetails}>
                    <PriceDetails
                        HT={parseInt(avoir?.montant_ht || 0, 10)}
                        TVA={parseInt(avoir?.tva || 0, 10)}
                        TTC={parseInt(avoir?.montant_ttc || 0, 10)}
                    />
                </Modal>
            )}
        </>
    );
}
