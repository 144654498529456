/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

const PriceCell = ({ defaultValue, name, control, setValue }) => {
    useEffect(() => {
        setValue(name, parseFloat(defaultValue, 10) || 0);
    }, [name]);

    return (
        <p className='text-link input-price'>
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                render={({ field: { onChange, value } }) => (
                    <input
                        min={0}
                        name={name}
                        placeholder={0}
                        autoComplete='off'
                        type='number'
                        value={value}
                        onChange={(e) => {
                            onChange(parseFloat(e.target.value, 10) || 0);
                        }}
                        onFocus={(event) => event.target.select()}
                    />
                )}
            />
            <span>€</span>
        </p>
    );
};

export default PriceCell;

PriceCell.propTypes = {
    defaultValue: PropTypes.number,
    name: PropTypes.string.isRequired,
    control: PropTypes.shape().isRequired,
    setValue: PropTypes.func.isRequired,
};

PriceCell.defaultProps = {
    defaultValue: 0,
};
