/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { FormProvider, useForm, useWatch } from "react-hook-form";

// Hooks
import useDebounce from "../../hooks/useDebounce";

// Components
import Filters from "../../components/Filters/Filters";
import FormDropdown from "../../components/Form/FormDropdown";
import Header from "../../components/Header/Header";
import Error from "../../components/Error/Error";

// Table
import Table from "../../components/Table/Table";
import TableAddProducts from "../../components/Table/TableAddProducts";
import Pagination from "../../components/Pagination/Pagination";

// Icons
import ButtonIcon from "../../components/Button/ButtonIcon";
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon";
import { useGetProducts } from "../../api/hooks/products.hooks";
import { useGetFiltres } from "../../api/hooks/filtres.hooks";
import { useProductsFiltersContext } from "../../contexts/filters/productsFiltersContext";
import { convertArraysIntoStrings, removeUndefined } from "../../utils/object";
import FormSearch from "../../components/Form/FormSearch";
import FormCheckbox from "../../components/Form/FormCheckbox";

export default function DevisAddProduct() {
    const history = useHistory();
    const { devisId } = useParams();
    const { productsFilters, setProductsFilters } = useProductsFiltersContext();
    const [page, setPage] = useState(1);
    const [formattedFilters, setFormatedFilters] = useState({});

    const {
        data: filters,
        isLoading: loadingFilters,
        isError: errorFilters,
    } = useGetFiltres();

    const {
        data: products,
        isLoading: loadingProducts,
        isError: errorProducts,
    } = useGetProducts({
        numero_page: page,
        ...formattedFilters,
        devis_id: devisId,
    });

    const methods = useForm({
        defaultValues: {
            type_affaire_id: [],
            categorie_id: [],
            ...productsFilters,
        },
    });

    const selectedFilters = useWatch({ control: methods.control });
    const debouncedSelectedFilters = useDebounce(selectedFilters, 700);
    useEffect(() => {
        setProductsFilters(debouncedSelectedFilters);
        setPage(1);
        const newFilters = convertArraysIntoStrings(selectedFilters);
        return setFormatedFilters(removeUndefined(newFilters));
    }, [debouncedSelectedFilters]);

    if (errorProducts)
        return <Error message="Erreur lors de la récupération des produits" />;
    if (errorFilters)
        return <Error message="Erreur lors de la récupération des filtres" />;

    return (
        <>
            <Header>
                <div className="flex center">
                    <ButtonIcon
                        icon={<ArrowLeftIcon />}
                        onClick={() => history.push(`/devis/${devisId}`)}
                    />
                    <h1 className="text-lg">Ajouter un produit</h1>
                </div>
            </Header>
            <Filters>
                <div className="flex space">
                    <FormProvider {...methods}>
                        <FormSearch
                            placeholder="Nom, Référence, ..."
                            name="recherche"
                        />
                        {loadingFilters ? (
                            <p>Chargement</p>
                        ) : (
                            <div className="flex">
                                <form className="flex sl-2 mr-2">
                                    <FormCheckbox name="commande">
                                        Produits commandés
                                    </FormCheckbox>
                                    <FormCheckbox name="produit_casino">
                                        Casino
                                    </FormCheckbox>
                                    {filters.type_affaire && (
                                        <FormDropdown
                                            name="type_affaire_id"
                                            label="Famille"
                                            list={
                                                filters.type_affaire
                                                    ? filters.type_affaire
                                                    : []
                                            }
                                        />
                                    )}
                                    {filters.categorie && (
                                        <FormDropdown
                                            name="categorie_id"
                                            label="Catégories"
                                            list={
                                                filters.categorie
                                                    ? filters.categorie
                                                    : []
                                            }
                                        />
                                    )}
                                </form>
                                <Pagination
                                    setPage={setPage}
                                    page={page}
                                    totalPages={products?.nb_page}
                                />
                            </div>
                        )}
                    </FormProvider>
                </div>
            </Filters>
            <section className="section">
                <Table data={products?.produits}>
                    <TableAddProducts loading={loadingProducts} />
                </Table>
            </section>
        </>
    );
}
