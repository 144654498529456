/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

// Components
import Filters from '../../components/Filters/Filters';
import FormSearch from '../../components/Form/FormSearch';
import Header from '../../components/Header/Header';
import Error from '../../components/Error/Error';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import ButtonSecondary from '../../components/Button/ButtonSecondary';
import Loader from '../../components/Loader/Loader';

// Table
import Table from '../../components/Table/Table';
import TableCommandeDetails from '../../components/Table/TableCommandeDetails';

// Icons
import ButtonIcon from '../../components/Button/ButtonIcon';
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon';

// Hooks
import useModal from '../../hooks/useModal';
import useDebounce from '../../hooks/useDebounce';
import useDidMountEffect from '../../hooks/useDidMountEffect';

// api
import { useGetCommande } from '../../api/hooks/commandes.hooks';
import { useValidateBonLivraison } from '../../api/hooks/bonLivraisons.hooks';
import { useDevisDetailsFiltersContext } from '../../contexts/filters/devisDetailsFiltersContext';

// utils
import { filterObjectsByValue } from '../../utils/object';
import FormTextarea from '../../components/Form/FormTextarea';

export default function CommandeDetails() {
    const history = useHistory();
    const { id } = useParams();
    const { devisDetailsFilters, setDevisDetailsFilters } = useDevisDetailsFiltersContext();
    const [filteredData, setFilteredData] = useState([]);
    const [isShowing, toggle] = useModal();

    const { data: bonLivraison, isLoading, isError } = useGetCommande(id);
    const { isLoading: loadingValidate, mutate: validate } = useValidateBonLivraison();

    const methods = useForm({ defaultValues: devisDetailsFilters });
    const {
        register,
        formState: { errors },
        getValues,
    } = useForm();

    const handleValidation = () => {
        const data = {
            bon_livraison_id: bonLivraison.id,
            bon_livraison_detail: getValues('bon_livraison_detail'),
        };

        validate(data, {
            onSuccess: ({ pdf }) => {
                if (pdf) {
                    window.open(pdf, '_blank');
                }
                history.push('/commandes');
            },
        });
    };

    const searchInput = useWatch({ control: methods.control, name: 'search-input' });
    const debouncedSearchInput = useDebounce(searchInput, 500);
    useDidMountEffect(() => {
        setDevisDetailsFilters((prevState) => ({
            ...prevState,
            'search-input': debouncedSearchInput,
        }));
    }, [debouncedSearchInput]);

    useEffect(() => {
        if (!devisDetailsFilters || devisDetailsFilters?.['search-input'] === '') {
            return setFilteredData(bonLivraison?.lignes);
        }
        const newData = filterObjectsByValue(bonLivraison?.lignes, debouncedSearchInput, [
            'nom',
            'reference',
        ]);
        return setFilteredData(newData);
    }, [bonLivraison, devisDetailsFilters]);

    if (isError) return <Error message="Erreur lors de la récupération du bon de livraison" />;

    return (
        <>
            <Header>
                <div className="flex space">
                    <div className="flex center">
                        <ButtonIcon
                            icon={<ArrowLeftIcon />}
                            onClick={() => history.push('/commandes')}
                        />
                        <h1 className="text-lg ml-1">
                            {bonLivraison?.commande} | {bonLivraison?.client}
                        </h1>
                    </div>
                    <div className="flex center">
                        <Button onClick={toggle}>Valider bon de livraison</Button>
                    </div>
                </div>
            </Header>
            <Filters>
                <div className="flex space">
                    <FormProvider {...methods}>
                        <FormSearch placeholder="Nom, Référence, ..." name="search-input" />
                    </FormProvider>
                </div>
            </Filters>
            <section className="section">
                <Table data={filteredData}>
                    <TableCommandeDetails loading={isLoading} commandeId={id} />
                </Table>
            </section>

            {isShowing && (
                <Modal isShowing={isShowing} hide={toggle}>
                    <form className="form p-5 flex column center">
                        <p className="text-bold text-center">
                            Souhaitez-vous valider le bon de livraison ?
                        </p>
                        <FormTextarea
                            name="bon_livraison_detail"
                            placeholder="Commentaire, remarques, ..."
                            register={register}
                            errors={errors}
                        />
                        <Button onClick={handleValidation} disabled={loadingValidate} type="button">
                            Valider bon de livraison
                            {loadingValidate && <Loader />}
                        </Button>
                        <ButtonSecondary onClick={toggle}>Annuler</ButtonSecondary>
                    </form>
                </Modal>
            )}
        </>
    );
}
