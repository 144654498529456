/* eslint-disable no-empty-pattern */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

// Components
import ImageCell from './ImageCell';
import NameCell from './NameCell';
import Modal from '../Modal/Modal';
import ReductionCell from './ReductionCell';
import ProductDetails from '../ProductDetails/ProductDetails';
import LoaderSpinner from '../Loader/LoaderSpinner';
import FormQuantity from '../Form/FormQuantity';
import TotalHtCell from './TotalHtCell';
import QuantityDetails from './modules/QuantityDetails';

// Hooks
import useModal from '../../hooks/useModal';

// Icons
import ButtonIcon from '../Button/ButtonIcon';
import CheckIcon from '../../assets/icons/CheckIcon';

// Utils
import { getTableHeight, notify } from '../../utils/utils';

// api
import { useAddProductAvoir, useGetAvoir } from '../../api/hooks/avoirs.hooks';
import PriceCell from './PriceCell';
import QuantityDetailsAvoir from './modules/QuantityDetailsAvoir';

function TableAvoirAddProducts({ handleSortColumn, sortData, sortColumn, loading }) {
    const { avoirId } = useParams();
    const [isShowing, toggle] = useModal();
    const [isShowingDeclinaisons, toggleDeclinaisons] = useModal();
    const [productId, setProductId] = useState(null);
    const [declinaisons, setDeclinaisons] = useState(null);

    const { data: avoir, isLoading: avoirLoading } = useGetAvoir(parseInt(avoirId, 10));
    const { isLoading: loadingAddProduct, mutate: addProduct } = useAddProductAvoir(parseInt(avoirId, 10));

    const { register, setValue, getValues, control } = useForm();

    const openDetails = (id) => {
        setProductId(id);
        toggle();
    };

    const closeDeclinaisons = () => {
        setDeclinaisons(null);
    };
    const openDeclinaisons = (ligneId) => {
        setDeclinaisons(<QuantityDetailsAvoir toggle={closeDeclinaisons} ligneId={ligneId} />);
        toggleDeclinaisons();
    };

    const addNewProduct = (productId, data) => {
        if (!data.qte) {
            return notify('Quantité incorrecte', 'error');
        }
        return addProduct(
            {
                avoir_id: avoirId,
                produit_id: productId,
                ...data,
            },
            {
                onSuccess: ({ data }) => {
                    if (data.qte_incorrecte) openDeclinaisons(parseInt(data.id, 10));
                    setValue(productId, { qte: 0, prix_unitaire: parseFloat(data.prix, 10) });
                },
            }
        );
    };

    return (
        <>
            <Table
                height={getTableHeight(238)}
                renderLoading={LoaderSpinner}
                className='table'
                data={sortData()}
                rowHeight={100}
                sortColumn={sortColumn}
                onSortColumn={handleSortColumn}
                defaultSortType='asc'
                loading={loading}
                locale={{ emptyMessage: 'Aucun produit', loading: 'Chargement' }}
            >
                <Column>
                    <HeaderCell />
                    <ImageCell dataKey='vignette' openDetails={openDetails} />
                </Column>

                <Column flexGrow={1} minWidth={300} sortable>
                    <HeaderCell>Produit</HeaderCell>
                    <NameCell dataKey='nom' />
                </Column>




                <Column width={130}>
                    <HeaderCell>Stock virtuel</HeaderCell>
                    <Cell dataKey='stock'>{({ stock }) => <p>{stock}</p>}</Cell>
                </Column>

                <Column width={115}>
                    <HeaderCell>Stock total</HeaderCell>
                    <Cell dataKey='stock_reel'>{({ stock_reel }) => <p>{stock_reel}</p>}</Cell>
                </Column>

                <Column width={150} sortable>
                    <HeaderCell>Zone</HeaderCell>
                    <Cell dataKey='zone_de_stockage' />
                </Column>

                <Column width={90}>
                    <HeaderCell>cond.</HeaderCell>
                    <Cell>
                        {({ conditionnement }) => (
                            <div className='flex center'>
                                <p>{conditionnement}</p>
                            </div>
                        )}
                    </Cell>
                </Column>

                <Column align='right' width={110}>
                    <HeaderCell>Prix U. HT</HeaderCell>
                    <Cell>
                        {({ prix, id }) => (
                            <PriceCell
                                name={`${id}.prix_unitaire`}
                                control={control}
                                defaultValue={parseFloat(prix, 10)}
                                setValue={setValue}
                            />
                        )}
                    </Cell>
                </Column>

                <Column width={166}>
                    <HeaderCell>Quantité</HeaderCell>
                    <Cell>
                        {({ id, conditionnement }) => (
                            <FormQuantity
                                name={`${id}.qte`}
                                register={register}
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                                step={parseInt(conditionnement || 1, 10)}
                                editable
                            />
                        )}
                    </Cell>
                </Column>

                {avoir && (
                    <Column align='right' width={91}>
                        <HeaderCell>
                            <p>Reduc.</p>
                        </HeaderCell>
                        <Cell>
                            {({ id, produit_sans_remise }) => (
                                <ReductionCell
                                    name={`${id}.remise`}
                                    control={control}
                                    defaultValue={parseInt(produit_sans_remise, 10) ? 0 : parseFloat(avoir.remise, 10)}
                                    setValue={setValue}
                                />
                            )}
                        </Cell>
                    </Column>
                )}

                <Column align='right' width={140}>
                    <HeaderCell>
                        <p>Total HT</p>
                    </HeaderCell>
                    <Cell>
                        {({ id, prix }) => (
                            <TotalHtCell
                                name={id}
                                control={control}
                                prix={parseFloat(prix)}
                                getValues={getValues}
                                isAvoir
                            />
                        )}
                    </Cell>
                </Column>

                <Column fixed='right' width={88}>
                    <HeaderCell />
                    <Cell>
                        {({ id }) => (
                            <div className='flex right w-100 mr-1'>
                                <ButtonIcon
                                    onClick={() => addNewProduct(id, getValues(id))}
                                    icon={<CheckIcon />}
                                    disabled={loadingAddProduct}
                                />
                            </div>
                        )}
                    </Cell>
                </Column>
            </Table>

            {isShowing && productId && (
                <Modal isShowing={isShowing} hide={toggle} right>
                    <ProductDetails productId={productId} />
                </Modal>
            )}

            {declinaisons && (
                <Modal isShowing={declinaisons} hide={toggleDeclinaisons} isClosable={false}>
                    {declinaisons}
                </Modal>
            )}
        </>
    );
}

export default TableAvoirAddProducts;
