import React, { useState, useEffect, useLayoutEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

// Components
import Header from '../../components/Header/Header';
import Filters from '../../components/Filters/Filters';
import FormSearch from '../../components/Form/FormSearch';

import Table from '../../components/Table/Table';
import TableCommandes from '../../components/Table/TableCommandes';
import Error from '../../components/Error/Error';

// hooks
import { useGetCommandes } from '../../api/hooks/commandes.hooks';
import { useCommandesFiltersContext } from '../../contexts/filters/commandesFiltersContext';
import useDebounce from '../../hooks/useDebounce';
import useDidMountEffect from '../../hooks/useDidMountEffect';

// utils
import { filterObjectsByValue } from '../../utils/object';
import useScrollPosition from '../../hooks/useScrollPosition';

export default function Commandes() {
    const { commandesFilters, setCommandesFilters } = useCommandesFiltersContext();

    const { data: commandes, isLoading, isError } = useGetCommandes();
    const [filteredData, setFilteredData] = useState([]);

    const methods = useForm({
        defaultValues: commandesFilters,
    });

    const searchInput = useWatch({ control: methods.control, name: 'search-input' });
    const debouncedSearchInput = useDebounce(searchInput, 500);
    useDidMountEffect(() => {
        setCommandesFilters((prevState) => ({
            ...prevState,
            'search-input': debouncedSearchInput,
        }));
    }, [debouncedSearchInput]);

    useEffect(() => {
        if (!commandesFilters || commandesFilters?.['search-input'] === '') {
            return setFilteredData(commandes);
        }
        const newData = filterObjectsByValue(commandes, debouncedSearchInput, ['client', 'numero']);
        return setFilteredData(newData);
    }, [commandes, commandesFilters]);

    // Scroll Restoration
    const { scrollPosition } = useScrollPosition();
    useEffect(() => {
        if (commandesFilters?.pageYOffset) {
            setTimeout(() => {
                window.scrollTo(0, commandesFilters.pageYOffset);
            }, 2);
        }
    }, [window]);
    useLayoutEffect(() => {
        if (scrollPosition !== null) {
            setCommandesFilters((prev) => ({ ...prev, pageYOffset: scrollPosition }));
        }
    }, [scrollPosition]);

    if (isError) return <Error message="Erreur lors de la récupération des commandes" />;

    return (
        <>
            <Header>
                <h1 className="text-lg">Préparation des commandes</h1>
            </Header>
            <Filters>
                <FormProvider {...methods}>
                    <FormSearch placeholder="Nom, Référence, ..." name="search-input" />
                </FormProvider>
            </Filters>

            <section className="section">
                <Table data={filteredData}>
                    <TableCommandes loading={isLoading} />
                </Table>
            </section>
        </>
    );
}
