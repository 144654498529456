import apiClient from '../apiClient';

const list = async () => {
    const response = await apiClient.get('/liste_client');
    return response.data;
};

const item = async (id) => {
    const response = await apiClient.get(`/detail_client/?entite_id=${id}`);
    return response.data;
};

async function create(client) {
    return apiClient.post('gestion_client', client);
}

const detailReglement = async (params) => {
    const response = await apiClient.get('detail_reglement_client/', { params });
    return response.data;
};

const clientsActions = { list, item, create, detailReglement };

export default clientsActions;
