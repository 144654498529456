/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

// Components
import Filters from '../../components/Filters/Filters';
import FormSearch from '../../components/Form/FormSearch';
import Header from '../../components/Header/Header';
import Error from '../../components/Error/Error';

// Table
import Table from '../../components/Table/Table';
import TableFournisseurDetails from '../../components/Table/TableFournisseurDetails';

// Icons
import ButtonIcon from '../../components/Button/ButtonIcon';
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon';
import { notify } from '../../utils/utils';

// api
import { useGetCommandeFournisseur } from '../../api/hooks/commandesFournisseur.hooks';

// hooks
import useDebounce from '../../hooks/useDebounce';
import useDidMountEffect from '../../hooks/useDidMountEffect';

// utils
import { filterObjectsByValue } from '../../utils/object';

// contexts
import { useFournisseursDetailsFiltersContext } from '../../contexts/filters/fournisseursDetailsFiltersContext';

export default function FournisseurDetails() {
    const history = useHistory();
    const { id } = useParams();
    const { fournisseursDetailsFilters, setFournisseursDetailsFilters } =
        useFournisseursDetailsFiltersContext();
    const [filteredData, setFilteredData] = useState([]);

    const { data: commande, isLoading, isError } = useGetCommandeFournisseur(id);

    useEffect(() => {
        if (commande && commande.length === 0) {
            history.push('/fournisseurs');
            notify('Commande totalement livrée', 'success');
        }
    }, [commande]);

    const methods = useForm({ defaultValues: fournisseursDetailsFilters });

    const searchInput = useWatch({ control: methods.control, name: 'search-input' });
    const debouncedSearchInput = useDebounce(searchInput, 500);
    useDidMountEffect(() => {
        setFournisseursDetailsFilters((prevState) => ({
            ...prevState,
            'search-input': debouncedSearchInput,
        }));
    }, [debouncedSearchInput]);

    useEffect(() => {
        if (!fournisseursDetailsFilters || fournisseursDetailsFilters?.['search-input'] === '') {
            return setFilteredData(commande?.lignes);
        }
        const newData = filterObjectsByValue(commande?.lignes, debouncedSearchInput, [
            'nom',
            'reference',
        ]);
        return setFilteredData(newData);
    }, [commande, fournisseursDetailsFilters]);

    if (isError) return <Error message="Erreur lors de la récupération du bon de livraison" />;

    return (
        <>
            <Header>
                <div className="flex center">
                    <ButtonIcon
                        icon={<ArrowLeftIcon />}
                        onClick={() => history.push('/fournisseurs')}
                    />
                    <h1 className="text-lg ml-1">
                        Commande {commande?.numero} | {commande?.fournisseur}{' '}
                    </h1>
                </div>
            </Header>
            <Filters>
                <div className="flex space">
                    <FormProvider {...methods}>
                        <FormSearch placeholder="Nom, Référence, ..." name="search-input" />
                    </FormProvider>
                </div>
            </Filters>
            <section className="section">
                <Table data={filteredData}>
                    <TableFournisseurDetails loading={isLoading} commandeId={id} />
                </Table>
            </section>
        </>
    );
}
