/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

function FormNumber({ label, placeholder, name, register, errors, rules, value, min, max }) {
    return (
        <div className="form__group">
            <label htmlFor={name}>{label}</label>
            <input
                defaultValue={value}
                id={name}
                placeholder={placeholder}
                type="number"
                {...register(name, { ...rules, min: 0, max: 100 })}
                className={errors[name] ? 'error' : undefined}
            />
            {errors[name]?.type === 'pattern' && <p className="text-error">{label} non valide</p>}
            {errors[name]?.type === 'required' && <p className="text-error">{label} requis</p>}
            {(errors[name]?.type === 'max' || errors[name]?.type === 'min') && (
                <p className="text-error">
                    La valeur doit être comprise entre {min} et {max}
                </p>
            )}
        </div>
    );
}

FormNumber.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.number,
    name: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.shape().isRequired,
    rules: PropTypes.shape(),
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
};

FormNumber.defaultProps = {
    placeholder: 0,
    rules: { required: true },
    value: 0,
    min: 0,
    max: 100,
};

export default FormNumber;
