/* eslint-disable react/prop-types */

import React, { createContext, useContext, useState } from 'react';

// create context
const AvoirsFiltersContext = createContext();

const AvoirsFiltersContextProvider = ({ children }) => {
    const [avoirsFilters, setAvoirsFilters] = useState(null);

    return (
        <AvoirsFiltersContext.Provider value={{ avoirsFilters, setAvoirsFilters }}>
            {children}
        </AvoirsFiltersContext.Provider>
    );
};

const useAvoirsFiltersContext = () => {
    const context = useContext(AvoirsFiltersContext);

    if (context === undefined) {
        throw new Error('useAvoirsFiltersContext est utilisé en dehors de son provider');
    }

    return context;
};

export { AvoirsFiltersContextProvider, useAvoirsFiltersContext };
