import apiClient from '../apiClient';

async function list() {
    const response = await apiClient.get('/liste_bon_livraison');
    return response.data;
}

async function validate(bonLivraison) {
    const response = await apiClient.post('valider_bon_livraison/', bonLivraison);
    return response.data;
}

async function livrer(id) {
    const response = await apiClient.post(`/livrer_bon_livraison/?bon_livraison_id=${id}`);
    return response.data;
}

const bonLivraisonsActions = { list, validate, livrer };

export default bonLivraisonsActions;
