/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { notify } from '../../utils/utils';
import clientsActions from '../actions/clients.actions';

export function useGetClients() {
    return useQuery('clients', clientsActions.list);
}

export function useGetClient(id, options = {}) {
    return useQuery(['client', id], () => clientsActions.item(id), options);
}

export function useGetClientDetailsReglement(params) {
    return useQuery(['client-reglement', { client: params.entite_id }], () =>
        clientsActions.detailReglement(params)
    );
}

export function useCreateClient(clientId = null) {
    const queryClient = useQueryClient();
    return useMutation(clientsActions.create, {
        onSuccess: () => {
            notify('Le client a bien été créé/modifié', 'success');
        },
        onError: () => {
            notify('Une erreur est survenue', 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries(['client', clientId]);
            queryClient.invalidateQueries('clients');
        },
    });
}
