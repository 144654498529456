/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import { useHistory } from 'react-router';

// Components
import Badge from '../Badge/Badge';
import DateCell from './DateCell';
import LoaderSpinner from '../Loader/LoaderSpinner';
import Modal from '../Modal/Modal';

// Icons
import ButtonIcon from '../Button/ButtonIcon';
import CopyIcon from '../../assets/icons/CopyIcon';

// Utils
import { getTableHeight, numberToEUR } from '../../utils/utils';

// Hooks
import useModal from '../../hooks/useModal';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import ButtonSecondary from '../Button/ButtonSecondary';
import EyeIcon from '../../assets/icons/EyeIcon';
import { useDuplicateDevis } from '../../api/hooks/devis.hooks';

function TableClientDetails({ handleSortColumn, sortData, sortColumn, loading }) {
    const history = useHistory();

    return (
        <>
            <Table
                height={getTableHeight(245)}
                renderLoading={LoaderSpinner}
                className="table"
                data={sortData()}
                rowHeight={72}
                sortColumn={sortColumn}
                onSortColumn={handleSortColumn}
                defaultSortType="asc"
                loading={loading}
                locale={{ emptyMessage: 'Aucune commande', loading: 'Chargement' }}
            >
                <Column flexGrow={1} minWidth={230}>
                    <HeaderCell>Statut</HeaderCell>
                    <Cell>{({ statut }) => <Badge type={statut.type} content={statut} />}</Cell>
                </Column>

                <Column flexGrow={1} minWidth={190} sortable>
                    <HeaderCell>Date de création</HeaderCell>
                    <DateCell dataKey="date_creation" />
                </Column>

                <Column flexGrow={1} minWidth={190} sortable>
                    <HeaderCell>Numéro</HeaderCell>
                    <Cell dataKey="numero" />
                </Column>

                <Column flexGrow={1} minWidth={150}>
                    <HeaderCell>Montant</HeaderCell>
                    <Cell dataKey="montant">
                        {({ montant }) => <p className="text-nowrap">{numberToEUR(montant)}</p>}
                    </Cell>
                </Column>

                <Column flexGrow={1} minWidth={150}>
                    <HeaderCell>Restant dû</HeaderCell>
                    <Cell dataKey="restant_du">
                        {({ restant_du }) => (
                            <p className={restant_du > 0 ? 'text-error' : undefined}>
                                {numberToEUR(restant_du)}
                            </p>
                        )}
                    </Cell>
                </Column>

                <Column fixed="right" width={136}>
                    <HeaderCell />
                    <Cell>
                        {({ id }) => (
                            <>
                                <DuplicateDevis id={id} />
                                <ButtonIcon
                                    onClick={() => history.push(`/devis/${id}`)}
                                    icon={<EyeIcon />}
                                />
                            </>
                        )}
                    </Cell>
                </Column>
            </Table>
        </>
    );
}

export default TableClientDetails;

const DuplicateDevis = ({ id }) => {
    const history = useHistory();
    const [isShowing, toggle] = useModal();

    const { mutate: duplicateDevis, isLoading } = useDuplicateDevis();

    const handleOnClick = () => {
        duplicateDevis(id, {
            onSuccess: ({ data }) => history.push(`/devis/${data.id}`),
        });
    };

    return (
        <>
            <ButtonIcon onClick={toggle} icon={<CopyIcon />} />
            {isShowing && (
                <Modal isShowing={isShowing} hide={toggle}>
                    <div className="p-5 flex column center">
                        <p className="text-bold mb-2">
                            Souhaitez-vous vraiment dupliquer ce devis ?
                        </p>
                        <Button disabled={isLoading} onClick={handleOnClick}>
                            Dupliquer
                            {isLoading && <Loader />}
                        </Button>
                        <ButtonSecondary onClick={toggle}>
                            <p className="mt-2">Annuler</p>
                        </ButtonSecondary>
                    </div>
                </Modal>
            )}
        </>
    );
};
