/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const QuantityCell = ({ total, quantity }) => {
    let state = '';

    if (quantity === 0) {
        state = 'text-error';
    } else if (quantity === total) {
        state = 'text-success';
    } else {
        state = 'text-warning';
    }

    return (
        <p className={state}>
            {quantity} / {total}
        </p>
    );
};

export default QuantityCell;

QuantityCell.propTypes = {
    quantity: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};
