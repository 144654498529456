import apiClient from '../apiClient';

const list = async () => {
    const response = await apiClient.get('/liste_filtre');
    return response.data;
};

const filtresActions = { list };

export default filtresActions;
