import apiClient from '../apiClient';

const item = async (id) => {
    const response = await apiClient.get(`/detail_ligne_avoir/?ligne_avoir_id=${id}`);
    return response.data;
};

async function update(ligneAvoir) {
    const response = await apiClient.post('gestion_ligne_avoir', ligneAvoir);
    return response.data;
}

async function remove(ligneId) {
    return apiClient.post(`/supprimer_ligne_avoir/?ligne_avoir_id=${ligneId}`);
}

async function updateDeclinaisons(declinaisons) {
    const response = await apiClient.post('gestion_detail_ligne_avoir', declinaisons);
    return response.data;
}

const ligneAvoirsActions = { item, update, remove, updateDeclinaisons };

export default ligneAvoirsActions;
