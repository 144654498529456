import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useForm, FormProvider, useWatch } from 'react-hook-form';

// Components
import Header from '../../components/Header/Header';
import Filters from '../../components/Filters/Filters';
import FormSearch from '../../components/Form/FormSearch';
import Table from '../../components/Table/Table';
import TableDevis from '../../components/Table/TableDevis';
import Button from '../../components/Button/Button';
import Error from '../../components/Error/Error';

// Modal
import Modal from '../../components/Modal/Modal';
import ModalHeader from '../../components/Modal/ModalHeader';
import NewDevisForm from './NewDevisForm';
import NewClientForm from '../Clients/NewClientForm';

// Icons
import PlusIcon from '../../assets/icons/PlusIcon';

// Hooks
import useModal from '../../hooks/useModal';
import { useGetDevis } from '../../api/hooks/devis.hooks';
import useDebounce from '../../hooks/useDebounce';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { useDevisFiltersContext } from '../../contexts/filters/devisFiltersContext';

// utils
import { filterObjectsByValue } from '../../utils/object';
import useScrollPosition from '../../hooks/useScrollPosition';

export default function Devis() {
    const { devisFilters, setDevisFilters } = useDevisFiltersContext();
    const [filteredData, setFilteredData] = useState([]);
    const [modalDevisIsShowing, toggleModalDevis] = useModal();
    const [modalClientIsShowing, toggleModalClient] = useModal();

    const methods = useForm({
        defaultValues: devisFilters,
    });

    const { data: devis, isLoading, isError } = useGetDevis();

    const openModalDevis = () => {
        toggleModalDevis();
        toggleModalClient();
    };

    const searchInput = useWatch({ control: methods.control, name: 'search-input' });
    const debouncedSearchInput = useDebounce(searchInput, 500);
    useDidMountEffect(() => {
        setDevisFilters((prevState) => ({
            ...prevState,
            'search-input': debouncedSearchInput,
        }));
    }, [debouncedSearchInput]);

    useEffect(() => {
        if (!devisFilters || devisFilters?.['search-input'] === '') {
            return setFilteredData(devis);
        }
        const newData = filterObjectsByValue(devis, debouncedSearchInput, ['client', 'numero']);
        return setFilteredData(newData);
    }, [devis, devisFilters]);

    // Scroll Restoration
    const { scrollPosition } = useScrollPosition();
    useEffect(() => {
        if (devisFilters?.pageYOffset) {
            setTimeout(() => {
                window.scrollTo(0, devisFilters.pageYOffset);
            }, 2);
        }
    }, [window]);
    useLayoutEffect(() => {
        if (scrollPosition !== null) {
            setDevisFilters((prev) => ({ ...prev, pageYOffset: scrollPosition }));
        }
    }, [scrollPosition]);

    if (isError) return <Error message="Erreur lors de la récupération de la liste des devis" />;

    return (
        <>
            <Header>
                <h1 className="text-lg mr-2">Devis</h1>
                <Button icon={<PlusIcon />} onClick={toggleModalDevis}>
                    Nouveau
                </Button>
            </Header>
            <Filters>
                <div className="flex space">
                    <FormProvider {...methods}>
                        <FormSearch placeholder="Client, Numéro, ..." name="search-input" />
                    </FormProvider>
                </div>
            </Filters>

            <section className="section">
                <Table data={filteredData}>
                    <TableDevis loading={isLoading} />
                </Table>
            </section>

            {modalDevisIsShowing && (
                <Modal isShowing={modalDevisIsShowing} hide={toggleModalDevis} right>
                    <ModalHeader>Nouveau devis</ModalHeader>
                    <NewDevisForm openModalDevis={openModalDevis} />
                </Modal>
            )}

            {modalClientIsShowing && (
                <Modal isShowing={modalClientIsShowing} hide={toggleModalClient} right>
                    <ModalHeader>Nouveau client</ModalHeader>
                    <NewClientForm />
                </Modal>
            )}
        </>
    );
}
