/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import { useHistory } from 'react-router';

// Components
import DateCell from './DateCell';
import LoaderSpinner from '../Loader/LoaderSpinner';

// Icons
import ButtonIcon from '../Button/ButtonIcon';
import EyeIcon from '../../assets/icons/EyeIcon';

// Utils
import { numberToEUR } from '../../utils/utils';

function TableAvoir({ handleSortColumn, sortData, sortColumn, loading }) {
    const history = useHistory();

    return (
        <Table
            autoHeight
            affixHeader={110}
            renderLoading={LoaderSpinner}
            className='table'
            data={sortData()}
            rowHeight={72}
            sortColumn={sortColumn}
            onSortColumn={handleSortColumn}
            defaultSortType='asc'
            loading={loading}
            locale={{ emptyMessage: "Aucun avoir n'a été trouvé", loading: 'Chargement' }}
        >
            <Column flexGrow={1} minWidth={190} sortable>
                <HeaderCell>Numéro</HeaderCell>
                <Cell dataKey='numero' />
            </Column>

            <Column flexGrow={1} minWidth={200} sortable>
                <HeaderCell>Date de création</HeaderCell>
                <DateCell dataKey='date' />
            </Column>

            <Column flexGrow={1} minWidth={300} sortable>
                <HeaderCell>Client</HeaderCell>
                <Cell dataKey='client' />
            </Column>

            <Column flexGrow={1} minWidth={150}>
                <HeaderCell>Montant</HeaderCell>
                <Cell dataKey='montant'>{({ montant }) => <p>{numberToEUR(montant)}</p>}</Cell>
            </Column>

            <Column flexGrow={1} minWidth={300} sortable>
                <HeaderCell>Désignation</HeaderCell>
                <Cell dataKey='designation' />
            </Column>

            <Column fixed='right' width={88}>
                <HeaderCell />
                <Cell>
                    {({ id }) => <ButtonIcon onClick={() => history.push(`avoirs/${id}`)} icon={<EyeIcon />} />}
                </Cell>
            </Column>
        </Table>
    );
}

export default TableAvoir;
