/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import { toast } from 'react-toastify';
import useWindowSize from '../hooks/useWindowSize';

// Convertit un objet Date en chaine de caractères (ex: Lundi 19 juin 2021)
export function dateToString(date) {
    if (Number.isNaN(Date.parse(date))) {
        return null;
    }

    const newDate = new Date(date);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return newDate.toLocaleDateString('fr-FR', options);
}

export function timestampToDate(timestamp) {
    const date = new Date();
    date.setTime(timestamp);
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return date.toLocaleDateString('fr-FR', options);
}

export function numberToEUR(number) {
    const eur = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(
        number
    );
    return eur;
}

export function getTableHeight(headerHeight) {
    const { height } = useWindowSize();
    const tableHeight = height - headerHeight;
    if (tableHeight < 350) return 350;
    return tableHeight;
}

export function notify(message, type) {
    return toast[type](message, {
        position: 'bottom-right',
        autoClose: 2500,
        theme: 'colored',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
    });
}

export function sumValues(obj) {
    let sum = 0;
    for (const el in obj) {
        if (obj.hasOwnProperty(el)) {
            sum += parseFloat(obj[el]);
        }
    }
    return sum;
}
