import apiClient from '../apiClient';

const list = async () => {
    const response = await apiClient.get('/liste_devis');
    return response.data;
};

const item = async (id) => {
    const response = await apiClient.get(`/detail_devis/?devis_id=${id}`);
    return response.data;
};

async function create(devis) {
    return apiClient.post('gestion_devis', devis);
}

async function duplicate(devisId) {
    return apiClient.post(`/dupliquer_devis/?devis_id=${devisId}`);
}

async function validate(data) {
    return apiClient.post('/valider_devis', data);
}

async function addProduct(data) {
    return apiClient.post(`/gestion_ligne_devis`, data);
}

async function checkOrderProduct(params) {
    const response = await apiClient.get(`/check_commande_produit/`, { params });
    return response.data;
}

const devisActions = { list, item, create, duplicate, validate, addProduct, checkOrderProduct };

export default devisActions;
