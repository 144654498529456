import * as React from "react";

function InputPlusIcon() {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 .5C5.373.5 0 5.873 0 12.5s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.09 16.364a1.09 1.09 0 11-2.18 0V13.59H7.635a1.09 1.09 0 110-2.182h3.273V8.136a1.09 1.09 0 112.182 0v3.273h3.273a1.09 1.09 0 110 2.182H13.09v3.273z"
                fill="#2E5599"
            />
        </svg>
    );
}

export default InputPlusIcon;
