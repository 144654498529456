/* eslint-disable react/prop-types */

import React, { createContext, useContext, useState } from 'react';

// create context
const FournisseursDetailsFiltersContext = createContext();

const FournisseursDetailsFiltersContextProvider = ({ children }) => {
    const [fournisseursDetailsFilters, setFournisseursDetailsFilters] = useState(null);

    return (
        <FournisseursDetailsFiltersContext.Provider
            value={{ fournisseursDetailsFilters, setFournisseursDetailsFilters }}
        >
            {children}
        </FournisseursDetailsFiltersContext.Provider>
    );
};

const useFournisseursDetailsFiltersContext = () => {
    const context = useContext(FournisseursDetailsFiltersContext);

    if (context === undefined) {
        throw new Error(
            'useFournisseursDetailsFiltersContext est utilisé en dehors de son provider'
        );
    }

    return context;
};

export { FournisseursDetailsFiltersContextProvider, useFournisseursDetailsFiltersContext };
