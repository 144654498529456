/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import commandesActions from '../actions/commandes.actions';

export function useGetCommandes() {
    return useQuery('commandes', () => commandesActions.list());
}

export function useGetCommande(id) {
    return useQuery(['commande', id], () => commandesActions.item(id));
}
