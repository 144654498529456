/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import commandesFournisseurActions from '../actions/commandesFournisseur.actions';

export function useGetCommandesFournisseur() {
    return useQuery('commandes-fournisseur', () => commandesFournisseurActions.list());
}

export function useGetCommandeFournisseur(id) {
    return useQuery(['commande-fournisseur', id], () => commandesFournisseurActions.item(id));
}
