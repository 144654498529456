import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Modal = ({ isShowing, hide, children, right, isClosable }) => {
    const modal = useRef(null);

    const closeModal = () => {
        modal.current.classList.add('hide');
        setTimeout(() => {
            hide();
        }, 500);
    };

    return isShowing
        ? ReactDOM.createPortal(
              <>
                  <div ref={modal} className={`modal-wrapper ${right ? 'right' : ''}`}>
                      <div className='modal'>{children}</div>
                      <div className='modal__overlay' onClick={() => isClosable && closeModal()} aria-hidden='true' />
                  </div>
              </>,
              document.body
          )
        : null;
};

export default Modal;

Modal.propTypes = {
    isShowing: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    right: PropTypes.bool,
    isClosable: PropTypes.bool,
};

Modal.defaultProps = {
    isClosable: true,
    right: false,
};
